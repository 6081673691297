import { useState } from 'react';
import { FullscreenLayout } from '../../../components/layout/FullscreenLayout';
import { SignUp, SignUpData } from '../../../components/theme/signUp/SignUp';
import { UserValidator } from 'nocode-api-builder-model';
import { usePublicAuthApiClient } from '../../../api/PublicAuthApiClient';
import { HttpClient } from '../../../api/HttpClient';
import { ConfirmSignUp } from '../../../components/theme/signUp/ConfirmSignUp';
import { FinishSignUp } from '../../../components/theme/signUp/FinishSignUp';

type SignUpStage = 'signUp' | 'confirm' | 'finish';

export function SignUpRoute() {
	const apiClient = usePublicAuthApiClient();
	const [stage, setStage] = useState<SignUpStage>('signUp');
	const [signUpData, setSignUpData] = useState<SignUpData>(() => ({
		email: '',
		invitationCode: '',
		password1: '',
		password2: ''
	}));
	const [confirmationCode, setConfirmationCode] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);

	async function onSubmit(handler: () => Promise<void>) {
		if (isLoading) {
			return;
		}
		setIsLoading(true);
		if (error) {
			setError(null);
		}

		try {
			await handler();
		} catch (e) {
			setError(HttpClient.readError(e));
		} finally {
			setIsLoading(false);
		}
	}

	async function onSignUpSubmit() {
		onSubmit(async () => {
			validPasswords(signUpData.password1, signUpData.password2);
			UserValidator.validPassword(signUpData.password1);
			UserValidator.validEmail(signUpData.email);

			await apiClient.signUp({
				email: signUpData.email,
				invitationCode: signUpData.invitationCode
			});

			setStage('confirm');
		});
	}

	async function onConfirmSubmit() {
		onSubmit(async () => {
			await apiClient.confirmSignUp({
				email: signUpData.email,
				invitationCode: signUpData.invitationCode,
				password: signUpData.password1,
				confirmationCode
			});

			setStage('finish');
		});
	}

	return (
		<FullscreenLayout>
			{stage === 'signUp' && (
				<SignUp isLoading={isLoading} data={signUpData} error={error} onDataChanged={setSignUpData} onSubmit={onSignUpSubmit} />
			)}
			{stage === 'confirm' && (
				<ConfirmSignUp
					isLoading={isLoading}
					confirmationCode={confirmationCode}
					onConfirmationCodeChanged={setConfirmationCode}
					error={error}
					onSubmit={onConfirmSubmit}
				/>
			)}
			{stage === 'finish' && <FinishSignUp singInHref="/signin" />}
		</FullscreenLayout>
	);
}

function validPasswords(p1: string, p2: string) {
	if (p1 !== p2) {
		throw new Error('Passwords do not match');
	}
}
