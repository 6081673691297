export interface LogItemBoxProps {
	children: React.ReactNode;
	theme: LogItemBoxTheme;
}

export enum LogItemBoxTheme {
	default = 1,
	user = 2,
	error = 3
}

export function LogItemBox(props: LogItemBoxProps) {
	let cls = 'block border px-2 py-1.5 text-xs rounded-md mb-1';
	switch (props.theme) {
		case LogItemBoxTheme.error:
			cls += ' border-red-300 bg-red-100 text-red-600';
			break;
		case LogItemBoxTheme.user:
			cls += ' border-blue-300 bg-blue-100';
			break;
		default:
			cls += ' border-gray-200';
			break;
	}

	return <div className={cls}>{props.children}</div>;
}
