"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.trimTextModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const StepNameFormatter_1 = require("../../utilities/formatters/StepNameFormatter");
exports.trimTextModel = (0, sequential_workflow_editor_model_1.createStepModel)('trimText', 'task', step => {
    step.label('TrimText');
    step.category('Primitives');
    step.description('Trim text');
    step.name()
        .dependentProperty('input')
        .dependentProperty('result')
        .value((0, sequential_workflow_editor_model_1.createGeneratedStringValueModel)({
        generator(context) {
            const input = context.formatPropertyValue('input', StepNameFormatter_1.StepNameFormatter.formatDynamic);
            const result = context.formatPropertyValue('result', StepNameFormatter_1.StepNameFormatter.formatVariable);
            return `${result} = TrimText(${input})`;
        }
    }));
    step.property('input').value((0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
                isRequired: true,
                valueType: 'text'
            })
        ]
    }));
    step.property('result').value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        valueType: 'text',
        isRequired: true
    }));
    step.property('maxEmptyLines')
        .value((0, sequential_workflow_editor_model_1.createChoiceValueModel)({
        choices: ['-', '0', '1']
    }))
        .label('Max empty lines');
});
