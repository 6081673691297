import { FlowTabHostMode } from '../theme/flow/FlowTabHost';

export type FlowMode = 'edit' | 'test';

export function createFlowModes(current: FlowMode, isNewMode: boolean): FlowTabHostMode[] {
	return [
		{
			id: 'edit',
			label: isNewMode ? 'Create' : 'Edit',
			isSelected: current === 'edit'
		},
		{
			id: 'test',
			label: 'Test',
			isSelected: current === 'test'
		},
		{
			id: 'traces',
			label: 'Traces',
			isSelected: false
		}
	];
}
