"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cropImageStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const StepNameFormatter_1 = require("../../utilities/formatters/StepNameFormatter");
exports.cropImageStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('cropImage', 'task', step => {
    step.label('CropImage');
    step.category('Primitives');
    step.name()
        .dependentProperty('input')
        .dependentProperty('output')
        .value((0, sequential_workflow_editor_model_1.createGeneratedStringValueModel)({
        generator(context) {
            const input = context.formatPropertyValue('input', StepNameFormatter_1.StepNameFormatter.formatVariable);
            const output = context.formatPropertyValue('output', StepNameFormatter_1.StepNameFormatter.formatVariable);
            return `${output} = CropImage(${input})`;
        }
    }));
    step.property('input')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        isRequired: true,
        valueType: 'image'
    }))
        .hint('Expected type: image');
    step.property('output')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        isRequired: true,
        valueType: 'image'
    }))
        .hint('Expected type: image');
    function num(defaultValue) {
        return (0, sequential_workflow_editor_model_1.createDynamicValueModel)({
            models: [
                (0, sequential_workflow_editor_model_1.createNumberValueModel)({
                    min: 1,
                    defaultValue
                }),
                (0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
                    isRequired: true,
                    valueType: 'number'
                })
            ]
        });
    }
    step.property('width').value(num(200));
    step.property('height').value(num(200));
});
