import { GetTracesResponse } from 'nocode-api-builder-model';
import { TraceList } from '../../components/theme/traceList/TraceList';
import { useNavigate } from 'react-router';
import { useScrollTop } from '../../components/theme/layout/Main';
import { useEffect, useMemo } from 'react';
import { useTraceApiClient } from '../../api/TraceApiClient';

export interface TraceListPageProps {
	page: number;
	processId: string | undefined;
	triggerId: string | undefined;
	response: GetTracesResponse;
}

export function TraceListPage(props: TraceListPageProps) {
	const tracesApi = useTraceApiClient();
	const navigate = useNavigate();
	const scrollTop = useScrollTop();
	const filter = useMemo(() => {
		const parts: string[] = [];
		if (props.processId) {
			parts.push(`processId=${props.processId}`);
		}
		if (props.triggerId) {
			parts.push(`triggerId=${props.triggerId}`);
		}
		return parts.length > 0 ? parts.join(', ') : null;
	}, [props.processId, props.triggerId]);

	useEffect(scrollTop, [scrollTop, props.page]);

	async function traceLogsProvider(traceId: string) {
		const response = await tracesApi.getTraceLogs({ minIndex: 0, traceId });
		return response.traceLogs;
	}

	function onPrevPageClicked() {
		const prevPage = props.page - 1;
		const url = buildNextUrl(prevPage, props.processId, props.triggerId);
		navigate(url);
	}

	function onNextPageClicked() {
		const url = buildNextUrl(props.response.nextPage as number, props.processId, props.triggerId);
		navigate(url);
	}

	return (
		<TraceList
			page={props.page}
			traces={props.response.traces}
			editProcessUrlPattern="/flows/:processId/edit#design"
			filter={filter}
			hasPrevPage={props.page > 0}
			hasNextPage={props.response.nextPage !== null}
			onPrevPageClicked={onPrevPageClicked}
			onNextPageClicked={onNextPageClicked}
			traceLogsProvider={traceLogsProvider}
		/>
	);
}

function buildNextUrl(page: number, processId: string | undefined, triggerId: string | undefined) {
	const params = new URLSearchParams();
	params.set('page', String(page));
	if (processId) {
		params.set('processId', processId);
	}
	if (triggerId) {
		params.set('triggerId', triggerId);
	}
	return '/traces?' + params.toString();
}
