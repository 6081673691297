"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadPdfStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const StepNameFormatter_1 = require("../../utilities/formatters/StepNameFormatter");
exports.loadPdfStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('loadPdf', 'task', step => {
    step.label('LoadPdf');
    step.category('Transformers');
    step.name()
        .dependentProperty('file')
        .dependentProperty('output')
        .value((0, sequential_workflow_editor_model_1.createGeneratedStringValueModel)({
        generator(context) {
            const output = context.formatPropertyValue('output', StepNameFormatter_1.StepNameFormatter.formatVariable);
            const file = context.formatPropertyValue('file', StepNameFormatter_1.StepNameFormatter.formatVariable);
            return `${output} = LoadPdf(${file})`;
        }
    }));
    step.property('file')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        valueType: 'file',
        isRequired: true
    }))
        .hint('Expected type: file');
    step.property('output')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        valueType: 'text',
        isRequired: true
    }))
        .hint('Expected type: text');
});
