import { FlowOutputField } from './FlowOutputState';
import { NumberOutputField } from './types/NumberOutputField';
import { JsonOutputField } from './types/JsonOutputField';
import { TextOutputField } from './types/TextOutputField';
import { ImageOutputField } from './types/ImageOutputField';
import { HtmlOutputField } from './types/HtmlOutputField';

export interface OutputFieldProps {
	field: FlowOutputField;
}

export function OutputField({ field }: OutputFieldProps) {
	if (['text', 'markdown'].includes(field.type)) {
		const value = field.value as string;
		if (value.startsWith('<!DOCTYPE html>')) {
			return <HtmlOutputField field={field} />;
		}
		return <TextOutputField field={field} />;
	}
	if (['json', 'textCollection'].includes(field.type)) {
		return <JsonOutputField field={field} />;
	}
	if (field.type === 'number') {
		return <NumberOutputField field={field} />;
	}
	if (field.type === 'image') {
		return <ImageOutputField field={field} />;
	}

	throw new Error(`Unknown output field type: ${field.type}`);
}
