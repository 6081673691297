import { Input } from '../../primitives/Input';
import { InputFieldProps, InputFieldSpec } from '../InputField';

export function createNumberInputFieldDefaultRawValue(): string {
	return '';
}

export function parseNumberInputFieldRawValue(rawValue: string, spec: InputFieldSpec): number {
	if (spec.isRequired && !rawValue) {
		throw new Error('Value is required');
	}
	const value = Number(rawValue);
	if (isNaN(value)) {
		throw new Error('Invalid number');
	}
	return value;
}

export function NumberInputField(props: InputFieldProps<string>) {
	return <Input value={props.rawValue} isInvalid={props.hasError} onChanged={props.onChange} />;
}
