import { useMemo, useRef, useState } from 'react';
import { FlowTab, FlowTabHost } from '../../theme/flow/FlowTabHost';
import { OverviewTab } from './OverviewTab';
import { DesignTabState, createDesignTabState } from './DesignTabState';
import { StartDefinitionActivator } from '../StartDefinitionActivator';
import { OverviewTabState, createOverviewTabState } from './OverviewTabState';
import { createFlowModes } from '../FlowModes';
import { FlowJSON, TriggerJSON, TriggerType, UpdateFlowJSON, UpdateTriggerJSON } from 'nocode-api-builder-model';
import { DesignTab } from './DesignTab';
import { TriggersTabState, createTriggersTabState, updateTriggersTabState } from './TriggersTabState';
import { TriggersTab } from './TriggersTab';
import { useLocation, useNavigate } from 'react-router';
import { readFlowInputFormStateValues } from '../../theme/flowInputForm/FlowInputFormState';
import { HttpClient } from '../../../api/HttpClient';
import { DevTab } from './DevTab';

export interface FlowEditorProps {
	userId: string;
	flow?: FlowJSON;
	triggers?: TriggerJSON[];
	onSave: (data: FlowEditorData) => Promise<boolean>;
	onModeChanged: (modeId: string, isDirty: boolean) => void;
}

export interface FlowEditorData {
	flow: UpdateFlowJSON;
	triggers: UpdateTriggerJSON[];
}

interface FlowEditorState {
	overview: OverviewTabState;
	triggers: TriggersTabState;
	design: DesignTabState;
}

export function FlowEditor(props: FlowEditorProps) {
	const { hash } = useLocation();
	const navigate = useNavigate();

	const isSaving = useRef(false);
	const [state, setState] = useState<FlowEditorState>(() => {
		const definition = props.flow?.definition ? JSON.parse(props.flow.definition) : StartDefinitionActivator.createDefault();
		return {
			overview: createOverviewTabState(props.flow),
			triggers: createTriggersTabState(definition, props.triggers),
			design: createDesignTabState(definition)
		};
	});

	const isNewMode = !props.flow;
	const currentTabId = hash ? hash.substring(1) : isNewMode ? 'overview' : 'design';
	const isDirty = state.design.isDirty || state.triggers.isDirty || state.overview.isDirty;
	const isValid = (state.design.definition.isValid ?? true) && state.triggers.isValid && !state.overview.errors;
	const canSave = isDirty && isValid;

	const modes = useMemo(() => createFlowModes('edit', isNewMode), [isNewMode]);
	const tabs = useMemo<FlowTab[]>(
		() => [
			{
				id: 'overview',
				label: 'Overview',
				isDirty: state.overview.isDirty,
				isValid: !state.overview.errors
			},
			{
				id: 'triggers',
				label: formatTriggersTabLabel(state.triggers.cronTriggers.length),
				isDirty: state.triggers.isDirty,
				isValid: state.triggers.isValid
			},
			{
				id: 'design',
				label: 'Design',
				isDirty: state.design.isDirty,
				isValid: state.design.definition.isValid ?? true
			}
		],
		[
			state.design.isDirty,
			state.design.definition.isValid,
			state.triggers.isValid,
			state.triggers.isDirty,
			state.triggers.cronTriggers.length,
			state.overview.errors,
			state.overview.isDirty
		]
	);

	function onModeChanged(index: number) {
		if (props.onModeChanged) {
			props.onModeChanged(modes[index].id, isDirty);
		}
	}

	function onTabClicked(index: number) {
		const id = tabs[index].id;
		navigate(`#${id}`);
	}

	function onOverviewStateChanged(overview: OverviewTabState) {
		setState({ ...state, overview });
	}

	function onScheduleStateChanged(triggers: TriggersTabState) {
		setState({ ...state, triggers });
	}

	function onDesignStateChange(design: DesignTabState) {
		setState({
			overview: state.overview,
			triggers: updateTriggersTabState(state.triggers, design.definition.value),
			design
		});
	}

	async function onSaveClicked() {
		if (isSaving.current || !canSave) {
			return;
		}
		isSaving.current = true;

		try {
			const inputs = await Promise.all(state.triggers.cronTriggers.map(t => readFlowInputFormStateValues(t.inputFormState)));
			const pageWillBeDestroyed = await props.onSave({
				flow: {
					name: state.overview.name,
					url: state.overview.url,
					maxExecutionTime: state.overview.maxExecutionTime,
					accessCode: state.overview.accessCode,
					isDeployed: state.overview.isDeployed,
					description: state.overview.description,
					definition: state.design.definition.value,
					comment: state.overview.comment
				},
				triggers: state.triggers.cronTriggers.map((t, index) => ({
					type: TriggerType.cron,
					index: t.index,
					name: t.name,
					isEnabled: t.isEnabled,
					parameters: t.parameters,
					fires: t.fires,
					input: inputs[index]
				}))
			});

			if (!pageWillBeDestroyed) {
				setState({
					design: {
						...state.design,
						isDirty: false
					},
					triggers: {
						...state.triggers,
						isDirty: false
					},
					overview: {
						...state.overview,
						isDirty: false
					}
				});
			}
		} catch (e) {
			const error = HttpClient.readError(e);
			window.alert(`An error occurred: ${error}`);
		} finally {
			isSaving.current = false;
		}
	}

	return (
		<FlowTabHost
			name={state.overview.name}
			modes={modes}
			onModeChanged={onModeChanged}
			tabs={tabs}
			currentTabId={currentTabId}
			onTabClicked={onTabClicked}
			primaryButtonLabel="Save"
			isPrimaryButtonDisabled={!canSave}
			onPrimaryButtonClicked={onSaveClicked}
		>
			{currentTabId === 'overview' && (
				<OverviewTab state={state.overview} userId={props.userId} onStateChanged={onOverviewStateChanged} />
			)}
			{currentTabId === 'triggers' && (
				<TriggersTab
					id={props.flow?.id}
					definition={state.design.definition.value}
					state={state.triggers}
					onStateChanged={onScheduleStateChanged}
				/>
			)}
			{currentTabId === 'design' && <DesignTab state={state.design} onStateChanged={onDesignStateChange} />}
			{currentTabId === 'dev' && (
				<DevTab overviewState={state.overview} designState={state.design} onDesignStateChanged={onDesignStateChange} />
			)}
		</FlowTabHost>
	);
}

function formatTriggersTabLabel(count: number) {
	return `Triggers ${count > 0 ? `(${count})` : ''}`;
}
