"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findAnyStringStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const strings_1 = require("@nocode-js/sequential-workflow-editor-model-pro/value-models/strings");
const StepNameFormatter_1 = require("../../utilities/formatters/StepNameFormatter");
exports.findAnyStringStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('findAnyText', 'task', step => {
    step.label('FindAnyText');
    step.category('Primitives');
    step.description('Find any text from the list of searches in the input text. The result is the index of the first match or -1 if no match was found.');
    step.name()
        .dependentProperty('input')
        .dependentProperty('result')
        .value((0, sequential_workflow_editor_model_1.createGeneratedStringValueModel)({
        generator(context) {
            const input = context.formatPropertyValue('input', StepNameFormatter_1.StepNameFormatter.formatVariable);
            const result = context.formatPropertyValue('result', StepNameFormatter_1.StepNameFormatter.formatVariable);
            return `${result} = FindAnyText(${input})`;
        }
    }));
    step.property('input').value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        valueType: 'text',
        isRequired: true
    }));
    step.property('searches').value((0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, strings_1.createStringsValueModel)({
                minCount: 1,
                minLength: 1,
                unique: true
            }),
            (0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
                valueType: 'textCollection',
                isRequired: true
            })
        ]
    }));
    step.property('ignoreCase').value((0, sequential_workflow_editor_model_1.createBooleanValueModel)({}));
    step.property('result').value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        valueType: sequential_workflow_editor_model_1.WellKnownValueType.number,
        isRequired: true
    }));
});
