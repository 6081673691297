import { FullscreenLayout } from '../components/layout/FullscreenLayout';
import { Error } from '../components/theme/error/Error';
import { useIsAuthenticated } from '../auth/AuthContext';

export function AuthGate(props: { route: React.ReactNode }) {
	const is = useIsAuthenticated();
	if (!is) {
		return (
			<FullscreenLayout>
				<Error message="Not authenticated" buttonText="Sign in" buttonHref="/signin" />
			</FullscreenLayout>
		);
	}
	return <>{props.route}</>;
}
