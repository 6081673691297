"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pexelsPhotoStepModel = void 0;
const password_1 = require("@nocode-js/sequential-workflow-editor-model-pro/value-models/password");
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
exports.pexelsPhotoStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('pexelsPhoto', 'task', step => {
    step.label('PexelsPhoto');
    step.category('Services');
    step.property('apiKey').value((0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, password_1.createPasswordValueModel)({
                isRequired: true
            })
        ]
    }));
    step.property('query')
        .value((0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, sequential_workflow_editor_model_1.createStringValueModel)({
                minLength: 1
            }),
            (0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
                valueType: 'text',
                isRequired: true
            })
        ]
    }))
        .label('Query');
    step.property('size').value((0, sequential_workflow_editor_model_1.createChoiceValueModel)({
        choices: ['large2x', 'large', 'medium', 'small', 'portrait', 'landscape', 'tiny', 'original']
    }));
    step.property('output')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        valueType: 'image'
    }))
        .label('Output')
        .hint('Expected type: image');
    step.property('selection').value((0, sequential_workflow_editor_model_1.createChoiceValueModel)({
        choices: ['first', 'random'],
        defaultValue: 'first'
    }));
});
