import { Environment, TraceDto, TraceLogDto, WorkflowLogs } from 'nocode-api-builder-model';
import { Fragment, useState } from 'react';
import { Button } from '../primitives/Button';
import { Logs } from '../logs/Logs';
import { Link } from 'react-router-dom';

export interface TraceItemProps {
	trace: TraceDto;
	editProcessUrlPattern: string;
	traceLogsProvider: TraceLogsProvider;
}

export type TraceLogsProvider = (traceId: string) => Promise<TraceLogDto[]>;

export function TraceItem(props: TraceItemProps) {
	const [logs, setLogs] = useState<WorkflowLogs | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const metrics = Object.entries(props.trace.metrics);
	const hasMetrics = metrics.length > 0;
	const hasDetails = Boolean(props.trace.details);
	const editProcessUrl = props.trace.processName ? props.editProcessUrlPattern.replace(':processId', props.trace.processId) : null;

	async function onToggleLogsClicked() {
		if (isLoading) {
			return;
		}
		if (logs) {
			setLogs(null);
			return;
		}
		setIsLoading(true);
		try {
			const response = await props.traceLogsProvider(props.trace.id);
			setLogs(response.map(l => l.logs).flat());
		} catch (e) {
			window.alert(`Error: ${e}`);
		} finally {
			setIsLoading(false);
		}
	}

	return (
		<div className="border border-gray-300/80 rounded-md overflow-hidden">
			<div className="p-2.5 w-full md:flex md:items-center ">
				<div className="flex-1">
					{props.trace.status === 0 && <Badge color="bg-green-600" status="OK" />}
					{props.trace.status === -1 && <Badge color="bg-red-600" status="ERROR" />}
					{props.trace.status > 0 && <Badge color="bg-gray-400" status="INTR" />}
					{editProcessUrl ? (
						<Link className="m-2 font-medium hover:underline" to={editProcessUrl}>
							{props.trace.processName}
						</Link>
					) : (
						<span className="m-2 font-medium">{props.trace.processId}</span>
					)}
					<span className="text-blue-700 text-sm">{new Date(props.trace.time).toLocaleString()}</span>
				</div>
				<div className="py-2 md:py-0">
					<small>{props.trace.credits} credits</small>
					<strong className="ml-2 text-blue-700">${props.trace.cost.toFixed(5)}</strong>
				</div>
				<div className="md:ml-2">
					<Button onClicked={onToggleLogsClicked} size="sm" theme="secondary">
						{logs ? 'Hide logs' : 'Logs'}
					</Button>
				</div>
			</div>

			{(logs || isLoading) && (
				<div className="px-2.5 pt-2 pb-1 border-t border-gray-300/80">
					{logs && (
						<>
							{logs.length > 0 && <Logs logs={logs} stepNameMap={{}} />}
							{logs.length === 0 && <div className="text-xs text-gray-500 mb-1">Not found any log</div>}
						</>
					)}
					{isLoading && <div className="text-xs text-gray-500 mb-1">Loading...</div>}
				</div>
			)}

			<div className="md:flex w-full bg-gray-100 text-gray-400 text-xs py-2.5">
				<div className="px-2.5">
					{hasMetrics && (
						<>
							metrics:{' '}
							{metrics.map(([key, value], index) => (
								<Fragment key={index}>
									{index > 0 && ', '}
									<span className="text-gray-900">
										{key}={value}
									</span>
								</Fragment>
							))}
						</>
					)}
					{hasMetrics && hasDetails && ', '}
					{hasDetails && (
						<>
							details: <span className="text-gray-900">{props.trace.details}</span>
						</>
					)}
				</div>
				<div className="md:flex-1 px-2.5 md:text-right md:min-w-[300px]">
					by <strong className="text-black">{props.trace.clientIp}</strong> from{' '}
					<strong className="text-black">{formatFrom(props.trace)}</strong>
					{' in '}
					<strong className="text-black">{props.trace.duration}ms</strong>
				</div>
			</div>
		</div>
	);
}

function formatFrom(trace: TraceDto) {
	switch (trace.env) {
		case Environment.api:
			return 'api';
		case Environment.test:
			return 'test';
		case Environment.form:
			return 'form';
		case Environment.trigger:
			return trace.triggerName ? `trigger: ${trace.triggerName}` : 'trigger';
		default:
			return '?';
	}
}

function Badge(props: { color: string; status: string }) {
	return <span className={`${props.color} text-white px-3 py-1.5 rounded text-xs font-bold`}>{props.status}</span>;
}
