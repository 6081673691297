import { GetFlowResponse } from 'nocode-api-builder-model';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { LoadingDefaultLayout } from '../../components/layout/LoadingDefaultLayout';
import { DefaultLayout } from '../../components/layout/DefaultLayout';
import { EditFlowPage } from './EditFlowPage';
import { useFlowApiClient } from '../../api/FlowApiClient';
import { ErrorDefaultLayout } from '../../components/layout/ErrorDefaultLayout';
import { HttpClient } from '../../api/HttpClient';

export function EditFlowRoute() {
	const apiClient = useFlowApiClient();
	const id = useParams().id as string;
	const [response, setResponse] = useState<GetFlowResponse | null>(null);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		async function load() {
			try {
				const response = await apiClient.getFlow(id);
				setResponse(response);
			} catch (e) {
				setError(HttpClient.readError(e));
				console.error(e);
			}
		}
		load();
	}, [id, apiClient]);

	if (error) {
		return <ErrorDefaultLayout error={error} />;
	}
	if (!response) {
		return <LoadingDefaultLayout />;
	}
	return (
		<DefaultLayout>
			<EditFlowPage id={id} flow={response.flow} triggers={response.triggers} />
		</DefaultLayout>
	);
}
