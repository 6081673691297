"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rssStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const utilities_1 = require("../../utilities");
exports.rssStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('rss', 'task', step => {
    step.label('RSS');
    step.category('Services');
    step.name()
        .dependentProperty('url')
        .value((0, sequential_workflow_editor_model_1.createGeneratedStringValueModel)({
        generator(context) {
            const url = context.formatPropertyValue('url', utilities_1.StepNameFormatter.formatDynamicUrl);
            return `RSS(${url})`;
        }
    }));
    step.property('url')
        .value((0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, sequential_workflow_editor_model_1.createStringValueModel)({
                minLength: 1
            }),
            (0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
                valueType: 'text',
                isRequired: true
            })
        ]
    }))
        .label('RSS URL');
    step.property('limit')
        .value((0, sequential_workflow_editor_model_1.createNumberValueModel)({
        min: 0
    }))
        .hint('Limit the number of items to read. 0 means no limit.');
    step.property('output')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        valueType: 'json'
    }))
        .label('Output')
        .hint('Expected type: json');
    step.property('outputUrls')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        valueType: 'textCollection'
    }))
        .label('Output URLs')
        .hint('Expected type: textCollection');
    step.property('outputTitles')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        valueType: 'textCollection'
    }))
        .label('Output titles')
        .hint('Expected type: textCollection');
    step.property('emptyMode')
        .value((0, sequential_workflow_editor_model_1.createChoiceValueModel)({
        choices: ['empty collection', 'interrupt']
    }))
        .label('Empty mode');
    step.property('keepLastPosition')
        .value((0, sequential_workflow_editor_model_1.createChoiceValueModel)({
        choices: ['disabled', 'process scope', 'global scope']
    }))
        .label('💾 Keep last position')
        .hint('If you want to read only new items from the RSS feed enable this mode. The last position will be stored in the key-value store.');
});
