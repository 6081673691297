import { InputError } from '../primitives/InputError';
import { FlowInputFormState, hasAnyFlowInputFieldError, parseFlowInputFieldRawValue } from './FlowInputFormState';
import { InputField } from './InputField';

export interface FlowInputFormProps {
	state: FlowInputFormState;
	onStateUpdated: (state: FlowInputFormState) => void;
}

export function FlowInputForm(props: FlowInputFormProps) {
	const state = props.state;

	function onFieldChanged(index: number, rawValue: unknown) {
		const field = state.fields[index];
		const result = parseFlowInputFieldRawValue(rawValue, field.spec);
		const fields = [...state.fields];
		fields[index] = {
			spec: field.spec,
			rawValue,
			value: result.value,
			error: result.error
		};
		const hasError = hasAnyFlowInputFieldError(fields);
		props.onStateUpdated({ fields, hasError });
	}

	return (
		<>
			{state.fields.length > 0 &&
				state.fields.map((field, index) => (
					<div key={index} className="pb-2">
						<label className="block mb-1.5 text-sm">{field.spec.name}</label>
						<InputField
							spec={field.spec}
							rawValue={field.rawValue}
							hasError={!!field.error}
							onChange={rv => onFieldChanged(index, rv)}
						/>
						<InputError error={field.error} />
					</div>
				))}
		</>
	);
}
