import { TestTabState } from './TestTabState';
import { TestTabForm } from '../../theme/flow/TestTabForm';
import { FlowInputFormState } from '../../theme/flowInputForm/FlowInputFormState';

export interface TestTabProps {
	description: string;
	url: string;
	state: TestTabState;
	onStateChanged: (state: TestTabState) => void;
}

export function TestTab(props: TestTabProps) {
	function onFormStateChanged(newFormState: FlowInputFormState) {
		props.onStateChanged({
			...props.state,
			formState: newFormState
		});
	}

	return (
		<TestTabForm
			description={props.description}
			logs={props.state.logs}
			stepNameMap={props.state.stepNameMap}
			formState={props.state.formState}
			outputState={props.state.outputState}
			onFormStateChanged={onFormStateChanged}
		/>
	);
}
