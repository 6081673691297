import { Button } from '../primitives/Button';

export interface ErrorProps {
	message: string;
	buttonText?: string;
	buttonHref?: string;
}

export function Error(props: ErrorProps) {
	return (
		<div className="py-10 px-2 text-center">
			<h2 className="text-3xl font-bold mb-8">{props.message}</h2>

			<Button href={props.buttonHref || '/'}>{props.buttonText || 'Go to home'}</Button>
		</div>
	);
}
