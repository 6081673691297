"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractCodeBlockStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const StepNameFormatter_1 = require("../../utilities/formatters/StepNameFormatter");
exports.extractCodeBlockStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('extractCodeBlock', 'task', step => {
    step.label('ExtractCodeBlock');
    step.category('Answers');
    step.name()
        .dependentProperty('input')
        .dependentProperty('output')
        .value((0, sequential_workflow_editor_model_1.createGeneratedStringValueModel)({
        generator(context) {
            const input = context.formatPropertyValue('input', StepNameFormatter_1.StepNameFormatter.formatVariable);
            const output = context.formatPropertyValue('output', StepNameFormatter_1.StepNameFormatter.formatVariable);
            return `${output} = ExtractCodeBlock(${input})`;
        }
    }));
    step.property('input')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        isRequired: true,
        valueType: 'text'
    }))
        .hint('Expected type: text');
    step.property('output')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        isRequired: true,
        valueType: 'text'
    }))
        .hint('Expected type: text');
    step.property('failureMode')
        .value((0, sequential_workflow_editor_model_1.createChoiceValueModel)({
        choices: ['empty text', 'error']
    }))
        .label('Not found mode');
    step.property('moreThanOneMode')
        .value((0, sequential_workflow_editor_model_1.createChoiceValueModel)({
        choices: ['return first', 'merge', 'error']
    }))
        .label('More than one mode');
});
