import { useNavigate } from 'react-router';
import { Fragment } from 'react';
import { useIsAuthenticated } from '../../auth/AuthContext';

export function HomeRoute() {
	const navigate = useNavigate();
	const isAuthenticated = useIsAuthenticated();

	setTimeout(() => {
		if (isAuthenticated) {
			navigate('/dashboard');
		} else {
			navigate('/signin');
		}
	});

	return <Fragment />;
}
