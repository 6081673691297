"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mozillaReadabilityStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const StepNameFormatter_1 = require("../../utilities/formatters/StepNameFormatter");
exports.mozillaReadabilityStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('mozillaReadability', 'task', step => {
    step.label('MozillaReadability');
    step.category('Transformers');
    step.name()
        .dependentProperty('html')
        .dependentProperty('output')
        .value((0, sequential_workflow_editor_model_1.createGeneratedStringValueModel)({
        generator(context) {
            const html = context.formatPropertyValue('html', StepNameFormatter_1.StepNameFormatter.formatDynamic);
            const output = context.formatPropertyValue('output', StepNameFormatter_1.StepNameFormatter.formatVariable);
            return `${output} = MozillaReadability(${html})`;
        }
    }));
    step.property('html')
        .value((0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
                valueType: 'text',
                isRequired: true
            }),
            (0, sequential_workflow_editor_model_1.createStringValueModel)({})
        ]
    }))
        .label('HTML');
    step.property('output')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        isRequired: true,
        valueType: 'text'
    }))
        .label('Output');
});
