import { Error as ErrorTheme } from '../theme/error/Error';
import { DefaultLayout } from './DefaultLayout';

export function ErrorDefaultLayout(props: { error: string }) {
	return (
		<DefaultLayout>
			<ErrorTheme message={props.error} />
		</DefaultLayout>
	);
}
