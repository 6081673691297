import { TraceDto } from 'nocode-api-builder-model';
import { CenteredBox } from '../layout/CenteredBox';
import { Button } from '../primitives/Button';
import { PageTitle } from '../layout/PageTitle';
import { TraceItem, TraceLogsProvider } from './TraceItem';

export interface TraceListProps {
	page: number;
	traces: TraceDto[];
	editProcessUrlPattern: string;
	filter: string | null;
	hasPrevPage: boolean;
	onPrevPageClicked: () => void;
	hasNextPage: boolean;
	onNextPageClicked: () => void;
	traceLogsProvider: TraceLogsProvider;
}

export function TraceList(props: TraceListProps) {
	return (
		<CenteredBox>
			<PageTitle title="Traces"> </PageTitle>

			{props.filter && (
				<div className="bg-gray-100 py-2 px-4 mt-2 rounded-md text-sm text-gray-400">
					Filter: <span className="text-black">{props.filter}</span>
				</div>
			)}

			{props.traces.length === 0 && (
				<div className="px-4 py-20 text-center">
					<p className="text-sm text-gray-500">No traces found.</p>
				</div>
			)}

			{props.traces.length > 0 && (
				<>
					<div className="grid grid-cols-1 gap-4 my-4 mx-4">
						{props.traces.map((t, index) => (
							<TraceItem
								trace={t}
								key={index}
								editProcessUrlPattern={props.editProcessUrlPattern}
								traceLogsProvider={props.traceLogsProvider}
							/>
						))}
					</div>

					{(props.hasPrevPage || props.hasNextPage) && (
						<div className="text-center pt-2 pb-4">
							{props.hasPrevPage && (
								<Button size="md" onClicked={props.onPrevPageClicked}>
									Previous
								</Button>
							)}

							<span className="inline-block px-3">Page {props.page + 1}</span>

							{props.hasNextPage && (
								<Button size="md" onClicked={props.onNextPageClicked}>
									Next
								</Button>
							)}
						</div>
					)}
				</>
			)}
		</CenteredBox>
	);
}
