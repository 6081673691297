import { useEffect, useMemo, useRef } from 'react';
import { SequentialWorkflowDesigner, WrappedDefinition } from 'sequential-workflow-designer-react';
import { EditorProvider } from 'sequential-workflow-editor';
import { DesignTabState } from './DesignTabState';
import { FlowDefinition, WorkflowUidGenerator, flowDefinitionModel } from 'nocode-api-builder-model';
import { canHandleKey, designerExtensions } from './extensions/designerExtensions';
import { createEditorExtensions } from './extensions/editorExtensions';
import { useSuggestionApiClient } from '../../../api/SuggestionApiClient';

export interface DesignTabProps {
	state: DesignTabState;
	onStateChanged: (state: DesignTabState) => void;
}

export function DesignTab(props: DesignTabProps) {
	const isFirstChange = useRef(true);
	const definitionRef = useRef(props.state.definition.value);
	const suggestionApi = useSuggestionApiClient();

	useEffect(() => {
		definitionRef.current = props.state.definition.value;
	}, [props.state.definition.value]);

	const editor = useMemo(() => {
		const editorProvider = EditorProvider.create<FlowDefinition>(flowDefinitionModel, {
			uidGenerator: WorkflowUidGenerator.next,
			extensions: createEditorExtensions({
				javaScriptEditor: {
					suggestionProvider: suggestionApi.getSuggestion
				}
			})
		});
		return {
			rootEditor: editorProvider.createRootEditorProvider(),
			stepEditor: editorProvider.createStepEditorProvider(),
			rootValidator: editorProvider.createRootValidator(),
			stepValidator: editorProvider.createStepValidator(),
			stepLabelProvider: editorProvider.createStepLabelProvider(),
			toolboxGroups: editorProvider.getToolboxGroups(),
			extensions: designerExtensions
		};
	}, [suggestionApi]);

	function onChange(definition: WrappedDefinition<FlowDefinition>) {
		const isDirty = isFirstChange.current ? props.state.isDirty : true;
		isFirstChange.current = false;
		props.onStateChanged({
			definition,
			isDirty
		});
	}

	return (
		<SequentialWorkflowDesigner
			theme="flat"
			definition={props.state.definition}
			rootEditor={editor.rootEditor}
			stepEditor={editor.stepEditor}
			keyboard={{
				canHandleKey: canHandleKey
			}}
			validatorConfiguration={{
				root: editor.rootValidator,
				step: editor.stepValidator
			}}
			controlBar={true}
			stepsConfiguration={{
				iconUrlProvider: (_, type) => `/assets/step-icons/${type}.svg`,
				isDuplicable: () => true
			}}
			onDefinitionChange={onChange}
			toolboxConfiguration={{
				groups: editor.toolboxGroups,
				labelProvider: editor.stepLabelProvider
			}}
			undoStackSize={20}
			extensions={editor.extensions}
		/>
	);
}
