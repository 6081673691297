import { FullscreenForm } from '../layout/FullscreenForm';
import { FlowInputForm } from '../flowInputForm/FlowInputForm';
import { FlowInputFormState } from '../flowInputForm/FlowInputFormState';
import { Button } from '../primitives/Button';
import { FlowOutputState } from '../flowOutput/FlowOutputState';
import { FlowOutput } from '../flowOutput/FlowOutput';
import { Input } from '../primitives/Input';
import { InputError } from '../primitives/InputError';

export interface ExecuteDeployedFlowProps {
	title: string;
	description: string;
	isExecuting: boolean;
	accessCode: string | null;
	onAccessCodeChanged: (accessCode: string) => void;
	error: string | null;
	outputState: FlowOutputState | null;
	formState: FlowInputFormState;
	onFormStateUpdated: (state: FlowInputFormState) => void;
	onFormSubmit: () => void;
	onReset: () => void;
}

export function ExecuteDeployedFlow(props: ExecuteDeployedFlowProps) {
	return (
		<FullscreenForm title={props.title} error={props.error}>
			{props.description && <p className="mb-3">{props.description}</p>}

			{props.outputState && (
				<>
					<FlowOutput state={props.outputState} />

					<Button onClicked={props.onReset} size="md" theme="secondary">
						Restart form &rarr;
					</Button>
				</>
			)}
			{!props.outputState && (
				<>
					{props.accessCode !== null && (
						<div>
							<label className="block mb-1.5 text-sm">Access code</label>
							<Input
								value={props.accessCode}
								isInvalid={!props.accessCode}
								type="password"
								onChanged={props.onAccessCodeChanged}
							/>
							{!props.accessCode && <InputError error={'Access code is required'} />}
						</div>
					)}

					<FlowInputForm state={props.formState} onStateUpdated={props.onFormStateUpdated} />

					<Button onClicked={props.onFormSubmit} isDisabled={props.formState.hasError || props.isExecuting}>
						Submit
					</Button>
				</>
			)}
		</FullscreenForm>
	);
}
