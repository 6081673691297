"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.readKeyValueStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const utilities_1 = require("../../utilities");
const evaluableStringValueModel_1 = require("../../model/evaluableStringValueModel");
exports.readKeyValueStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('readKeyValue', 'task', step => {
    step.label('ReadKeyValue');
    step.category('DB');
    step.name()
        .dependentProperty('output')
        .dependentProperty('key')
        .value((0, sequential_workflow_editor_model_1.createGeneratedStringValueModel)({
        generator(context) {
            const output = context.formatPropertyValue('output', utilities_1.StepNameFormatter.formatVariable);
            const key = context.formatPropertyValue('key', utilities_1.StepNameFormatter.formatDynamic);
            return `${output} = ReadKeyValue(${key})`;
        }
    }));
    step.property('key')
        .value((0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, evaluableStringValueModel_1.createEvaluableStringValueModel)({
                minLength: 1
            }),
            (0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
                valueType: 'text',
                isRequired: true
            })
        ]
    }))
        .validator(utilities_1.RichTextValidator.dynamicUrlValidator);
    step.property('output')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        valueType: 'text',
        isRequired: true
    }))
        .hint('Expected type: text');
    step.property('failureMode').value((0, sequential_workflow_editor_model_1.createChoiceValueModel)({
        choices: ['empty text', 'error']
    }));
    step.property('scope').value((0, sequential_workflow_editor_model_1.createChoiceValueModel)({
        choices: ['process', 'global']
    }));
});
