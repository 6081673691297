import {
	GetTraceLogsRequest,
	GetTraceLogsResponse,
	GetTraceRequest,
	GetTraceResponse,
	GetTraceStatsRequest,
	GetTraceStatsResponse,
	GetTracesRequest,
	GetTracesResponse
} from 'nocode-api-builder-model';
import { useMemo } from 'react';
import { HttpClient } from './HttpClient';
import { AuthSession, useSession } from '../auth/AuthContext';
import { useHttpClient } from './HttpClientContext';

export function useTraceApiClient() {
	const httpClient = useHttpClient();
	const session = useSession();
	return useMemo(() => new TraceApiClient(httpClient, session), [httpClient, session]);
}

export class TraceApiClient {
	public constructor(
		private readonly httpClient: HttpClient,
		private readonly session: AuthSession
	) {}

	public getTraces(request: GetTracesRequest): Promise<GetTracesResponse> {
		const params = new URLSearchParams();
		if (request.page) {
			params.append('page', String(request.page));
		}
		if (request.processId) {
			params.append('processId', request.processId);
		}
		if (request.triggerId) {
			params.append('triggerId', request.triggerId);
		}
		return this.httpClient.request({
			method: 'GET',
			url: '/api/traces?' + params.toString(),
			authToken: this.session.token
		});
	}

	public getTraceStats(request: GetTraceStatsRequest): Promise<GetTraceStatsResponse> {
		return this.httpClient.request({
			method: 'GET',
			url: `/api/trace-stats?fromTime=${request.fromTime}`,
			authToken: this.session.token
		});
	}

	public getTrace(request: GetTraceRequest): Promise<GetTraceResponse> {
		return this.httpClient.request({
			method: 'GET',
			url: `/api/traces/${request.traceId}`,
			authToken: this.session.token
		});
	}

	public getTraceLogs(request: GetTraceLogsRequest): Promise<GetTraceLogsResponse> {
		return this.httpClient.request({
			method: 'GET',
			url: `/api/trace-logs/${request.traceId}?minIndex=${request.minIndex}`,
			authToken: this.session.token
		});
	}
}
