export interface FlowOutputState {
	fields: FlowOutputField[];
}

export interface FlowOutputField {
	name: string;
	value: unknown;
	type: string;
}

export function createFlowOutputState(fields: FlowOutputField[]): FlowOutputState {
	return {
		fields
	};
}
