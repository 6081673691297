import { FlowJSON } from 'nocode-api-builder-model';
import { DefaultLayout } from '../../components/layout/DefaultLayout';
import { FlowListPage } from './FlowListPage';
import { useEffect, useState } from 'react';
import { LoadingDefaultLayout } from '../../components/layout/LoadingDefaultLayout';
import { useFlowApiClient } from '../../api/FlowApiClient';
import { ErrorDefaultLayout } from '../../components/layout/ErrorDefaultLayout';
import { HttpClient } from '../../api/HttpClient';

export function FlowsRoute() {
	const apiClient = useFlowApiClient();
	const [flows, setFlows] = useState<FlowJSON[] | null>(null);
	const [reload, setReload] = useState(0);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		async function load() {
			try {
				const response = await apiClient.getFlows();
				setFlows(response.flows);
			} catch (e) {
				setError(HttpClient.readError(e));
				console.error(e);
			}
		}
		load();
	}, [apiClient, reload]);

	function onReload() {
		setReload(reload + 1);
	}

	if (error) {
		return <ErrorDefaultLayout error={error} />;
	}
	if (!flows) {
		return <LoadingDefaultLayout />;
	}
	return (
		<DefaultLayout>
			<FlowListPage flows={flows} onReload={onReload} />
		</DefaultLayout>
	);
}
