"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const StepNameFormatter_1 = require("../../utilities/formatters/StepNameFormatter");
exports.setStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('set', 'task', step => {
    step.label('Set');
    step.category('Primitives');
    step.description('Sets value to variable.\nResult = Value');
    step.name()
        .dependentProperty('result')
        .dependentProperty('value')
        .value((0, sequential_workflow_editor_model_1.createGeneratedStringValueModel)({
        generator(context) {
            const result = context.formatPropertyValue('result', StepNameFormatter_1.StepNameFormatter.formatVariable);
            const value = context.formatPropertyValue('value', StepNameFormatter_1.StepNameFormatter.formatDynamic);
            return `${result} = ${value}`;
        }
    }));
    step.property('result').value((0, sequential_workflow_editor_model_1.createNullableAnyVariableValueModel)({
        isRequired: true
    }));
    step.property('value').value((0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, sequential_workflow_editor_model_1.createNullableAnyVariableValueModel)({
                isRequired: true
            }),
            (0, sequential_workflow_editor_model_1.createStringValueModel)({
                multiline: 5
            }),
            (0, sequential_workflow_editor_model_1.createNumberValueModel)({}),
            (0, sequential_workflow_editor_model_1.createBooleanValueModel)({})
        ]
    }));
});
