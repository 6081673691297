import { Input } from '../primitives/Input';
import { InputError } from '../primitives/InputError';
import { Select } from '../primitives/Select';
import { Textarea } from '../primitives/Textarea';

export interface OverviewTabFormProps {
	name: string;
	nameError: string | undefined;
	onNameChanged: (name: string) => void;
	url: string;
	deployedUrlPattern: string;
	urlError: string | undefined;
	onUrlChanged: (url: string) => void;
	maxExecutionTime: number;
	onMaxExecutionTimeChanged: (maxExecutionTime: number) => void;
	accessCode: string | null;
	onAccessCodeChanged: (accessCode: string | null) => void;
	description: string;
	onDescriptionChanged: (description: string) => void;
	comment: string;
	onCommentChanged: (comment: string) => void;
	isDeployed: boolean;
	onIsDeployedChanged: (isDeployed: boolean) => void;
}

export function OverviewTabForm(props: OverviewTabFormProps) {
	const deployedUrl = props.deployedUrlPattern.replace(':url', props.url);

	return (
		<div className="py-5 px-4">
			<div className="mb-4">
				<Label>
					Name * <Visibility private={false} />
				</Label>
				<Input value={props.name} isInvalid={!!props.nameError} onChanged={props.onNameChanged} />
				<InputError error={props.nameError} />
			</div>
			<div className="mb-4">
				<Label>
					URL * <Visibility private={false} />
				</Label>
				<Input value={props.url} isInvalid={!!props.urlError} onChanged={props.onUrlChanged} />
				<InputError error={props.urlError} />
			</div>
			<div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
				<div>
					<Label>
						Description <Visibility private={false} />
					</Label>
					<Textarea value={props.description} rows={5} onChanged={props.onDescriptionChanged} />
				</div>
				<div>
					<Label>
						Comment <Visibility private={true} />
					</Label>
					<Textarea value={props.comment} rows={5} onChanged={props.onCommentChanged} />
				</div>
			</div>
			<div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
				<div>
					<Label>Is deployed?</Label>
					<Select
						value={props.isDeployed ? '1' : '0'}
						onChanged={v => props.onIsDeployedChanged(v === '1')}
						options={{
							'0': 'Draft',
							'1': 'Deployed'
						}}
					/>
					{props.isDeployed && (
						<div className="mt-2">
							<code className="bg-blue-200 px-2 py-1 rounded-md text-xs">{deployedUrl}</code>
						</div>
					)}
				</div>
				<div>
					<Label>Max execution time</Label>
					<Select
						value={String(props.maxExecutionTime)}
						onChanged={v => props.onMaxExecutionTimeChanged(parseInt(v))}
						options={{
							'3': '3 seconds',
							'5': '5 seconds',
							'10': '10 seconds',
							'20': '20 seconds',
							'30': '30 seconds',
							'60': '60 seconds',
							'120': '2 minutes',
							'180': '3 minutes'
						}}
					/>
				</div>
				<div>
					<Label>Access code (optional)</Label>
					<Input value={props.accessCode || ''} onChanged={v => props.onAccessCodeChanged(v || null)} />
				</div>
			</div>
		</div>
	);
}

function Label(props: { children: React.ReactNode }) {
	return <label className="block mb-2 text-sm font-medium text-gray-900">{props.children}</label>;
}

function Visibility(props: { private: boolean }) {
	return <span className="bg-gray-200 text-gray-400 text-xs rounded p-1.5 py-0.5 mx-1">{props.private ? 'Private' : 'Public'}</span>;
}
