import React from 'react';

export interface PageTitleProps {
	title: string;
	children: React.ReactNode;
}

export function PageTitle(props: PageTitleProps) {
	return (
		<div className="bg-gray-100 px-4 pt-5 pb-3 border-b border-gray-300/80 rounded-b-md">
			<div className="flex items-end">
				<div className="flex-1">
					<h3 className="text-2xl font-bold">{props.title}</h3>
				</div>
				<div>{props.children}</div>
			</div>
		</div>
	);
}
