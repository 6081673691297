"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.modelSet = void 0;
const ifStepModel_1 = require("./steps/flow/ifStepModel");
const logStepModel_1 = require("./steps/tracing/logStepModel");
const setStepModel_1 = require("./steps/primitives/setStepModel");
const rangeLoopStepModel_1 = require("./steps/flow/rangeLoopStepModel");
const collectionLengthStepModel_1 = require("./steps/primitives/collectionLengthStepModel");
const equationStepModel_1 = require("./steps/primitives/equationStepModel");
const randomStepModel_1 = require("./steps/primitives/randomStepModel");
const appendTextStepModel_1 = require("./steps/primitives/appendTextStepModel");
const minStepModel_1 = require("./steps/primitives/minStepModel");
const maxStepModel_1 = require("./steps/primitives/maxStepModel");
const interruptStepModel_1 = require("./steps/flow/interruptStepModel");
const breakStepModel_1 = require("./steps/flow/breakStepModel");
const services_1 = require("./steps/services");
const steps_1 = require("./steps");
const markdown_1 = require("./steps/markdown");
const generateImageStepModel_1 = require("./steps/transformers/generateImageStepModel");
const queryHtmlStepModel_1 = require("./steps/transformers/queryHtmlStepModel");
const db_1 = require("./steps/db");
// Add below steps in alphabetical order!
const ai = [steps_1.chatOpenAiStepModel, steps_1.imagineDalleStepModel];
const answers = [steps_1.extractCodeBlockStepModel, steps_1.extractCodeBlocksStepModel, steps_1.extractListStepModel];
const db = [db_1.readKeyValueStepModel, db_1.writeKeyValueStepModel];
const flow = [breakStepModel_1.breakStepModel, ifStepModel_1.ifStepModel, interruptStepModel_1.interruptStepModel, rangeLoopStepModel_1.rangeLoopStepModel, steps_1.switchStepModel, steps_1.textCollectionLoopStepModel];
const markdown = [markdown_1.clearMarkdownStepModel, markdown_1.appendHeadingStepModel, markdown_1.imageMarkdownStepModel, markdown_1.paragraphMarkdownStepModel];
const primitives = [
    appendTextStepModel_1.appendTextStepModel,
    steps_1.chunkTextStepModel,
    collectionLengthStepModel_1.collectionLengthStepModel,
    steps_1.cropImageStepModel,
    equationStepModel_1.equationStepModel,
    steps_1.findAnyStringStepModel,
    steps_1.findStringStepModel,
    steps_1.getStepModel,
    steps_1.hashStepModel,
    steps_1.javaScriptTestStepModel,
    maxStepModel_1.maxStepModel,
    minStepModel_1.minStepModel,
    randomStepModel_1.randomStepModel,
    setStepModel_1.setStepModel,
    steps_1.splitTextStepModel,
    steps_1.textLengthStepModel,
    steps_1.trimTextModel
];
const services = [
    services_1.codepenStepModel,
    services_1.imageRequestStepModel,
    steps_1.jsonRequestStepModel,
    services_1.pexelsPhotoStepModel,
    services_1.rssStepModel,
    services_1.searchGoogleStepModel,
    services_1.sendEmailModel,
    steps_1.textRequestStepModel,
    services_1.wordpressPostStepModel
];
const tracing = [logStepModel_1.logStepModel];
const transformers = [generateImageStepModel_1.generateImageStepModel, steps_1.loadPdfStepModel, steps_1.mozillaReadabilityStepModel, steps_1.queryAllHtmlStepModel, queryHtmlStepModel_1.queryHtmlStepModel];
exports.modelSet = [
    ...ai,
    ...answers,
    ...db,
    ...flow,
    ...markdown,
    ...primitives,
    ...services,
    ...tracing,
    ...transformers
];
