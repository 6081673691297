"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.wordpressPostStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const password_1 = require("@nocode-js/sequential-workflow-editor-model-pro/value-models/password");
exports.wordpressPostStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('wordpressPost', 'task', step => {
    step.label('WordpressPost');
    step.category('Services');
    step.property('apiUrl')
        .value((0, sequential_workflow_editor_model_1.createStringValueModel)({
        defaultValue: 'http://localhost/wordpress/wp-json/wp/v2/'
    }))
        .label('API endpoint URL')
        .validator({
        validate(context) {
            const value = context.getValue();
            return value.endsWith('/v2/') ? null : 'Api endpoint URL must end with: /v2/';
        }
    });
    step.property('username')
        .value((0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, sequential_workflow_editor_model_1.createStringValueModel)({
                defaultValue: 'admin'
            })
        ]
    }))
        .label('Username');
    step.property('appPassword')
        .value((0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, password_1.createPasswordValueModel)({
                isRequired: true
            })
        ]
    }))
        .label('User app password');
    step.property('title')
        .value((0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, sequential_workflow_editor_model_1.createStringValueModel)({
                minLength: 1
            }),
            (0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
                valueType: 'text',
                isRequired: false
            })
        ]
    }))
        .label('Title');
    step.property('body').value((0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, sequential_workflow_editor_model_1.createNullableAnyVariableValueModel)({
                isRequired: true,
                valueTypes: ['text', 'markdown']
            }),
            (0, sequential_workflow_editor_model_1.createStringValueModel)({})
        ]
    }));
    step.property('status').value((0, sequential_workflow_editor_model_1.createChoiceValueModel)({
        choices: ['publish', 'draft']
    }));
    step.property('featuredMedia')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        valueType: 'image'
    }))
        .label('Featured media');
    step.property('outputPostUrl')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        valueType: 'text'
    }))
        .label('Output post URL');
});
