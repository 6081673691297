export interface SelectProps {
	value: string;
	onChanged: (value: string) => void;
	options: Record<string, string>;
	className?: string;
}

export function Select(props: SelectProps) {
	let cls =
		'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-2';
	if (props.className) {
		cls += ' ' + props.className;
	}
	return (
		<select value={props.value} onChange={e => props.onChanged(String(e.target.value))} className={cls}>
			{Object.entries(props.options).map(([value, label]) => (
				<option key={value} value={value}>
					{label}
				</option>
			))}
		</select>
	);
}
