import { FormEvent } from 'react';
import { Button } from '../primitives/Button';
import { Input } from '../primitives/Input';
import { FullscreenForm } from '../layout/FullscreenForm';

export interface SignInProps {
	username: string;
	password: string;
	error: string | null;
	onUsernameChanged: (value: string) => void;
	onPasswordChanged: (value: string) => void;
	onSubmit: () => void;
}

export function SignIn(props: SignInProps) {
	function onSubmit(e: FormEvent) {
		e.preventDefault();
		props.onSubmit();
	}

	return (
		<FullscreenForm title="Sign In To Your Account" error={props.error}>
			<form className="space-y-4 md:space-y-6" onSubmit={onSubmit}>
				<div>
					<label className="block mb-2 text-sm font-medium text-gray-900">E-mail</label>
					<Input value={props.username} name="email" onChanged={props.onUsernameChanged} />
				</div>
				<div>
					<label className="block mb-2 text-sm font-medium text-gray-900">Password</label>
					<Input type="password" name="password" value={props.password} onChanged={props.onPasswordChanged} />
				</div>

				<Button type="submit">Sign in</Button>

				<p className="text-sm font-light text-gray-500">
					Don’t have an account yet?{' '}
					<a href="/signup" className="font-medium text-blue-600 hover:underline">
						Sign up
					</a>
				</p>
			</form>
		</FullscreenForm>
	);
}
