"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clearMarkdownStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const StepNameFormatter_1 = require("../../utilities/formatters/StepNameFormatter");
exports.clearMarkdownStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('clearMarkdown', 'task', step => {
    step.label('ClearMarkdown');
    step.category('Markdown');
    step.name()
        .dependentProperty('target')
        .value((0, sequential_workflow_editor_model_1.createGeneratedStringValueModel)({
        generator(context) {
            const target = context.formatPropertyValue('target', StepNameFormatter_1.StepNameFormatter.formatVariable);
            return `ClearMarkdown(${target})`;
        }
    }));
    step.property('target').value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        valueType: 'markdown',
        isRequired: true
    }));
});
