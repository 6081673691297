"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.StepNameFormatter = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
class StepNameFormatter {
    static createStringFormatter(limit) {
        return (value) => trim(value, limit);
    }
}
exports.StepNameFormatter = StepNameFormatter;
_a = StepNameFormatter;
StepNameFormatter.formatVariable = (value) => {
    if (value) {
        return '$' + value.name;
    }
    return '?';
};
StepNameFormatter.formatDynamic = (value) => {
    switch (value.modelId) {
        case sequential_workflow_editor_model_1.nullableVariableValueModelId:
        case sequential_workflow_editor_model_1.nullableAnyVariableValueModelId:
        case sequential_workflow_editor_model_1.anyVariablesValueModelId:
            return _a.formatVariable(value.value);
        case sequential_workflow_editor_model_1.numberValueModelId:
            return String(value.value);
        case sequential_workflow_editor_model_1.stringValueModelId:
            return `"${trim(String(value.value), 9)}"`;
        case sequential_workflow_editor_model_1.booleanValueModelId:
            return value.value ? 'True' : 'False';
    }
    return '?';
};
StepNameFormatter.formatDynamicUrl = (value) => {
    if (value.modelId === sequential_workflow_editor_model_1.stringValueModelId) {
        try {
            const parsed = new URL(value.value);
            return `${parsed.protocol}//${parsed.host}…`;
        }
        catch (e) {
            // Invalid URL
        }
    }
    return StepNameFormatter.formatDynamic(value);
};
function trim(value, limit) {
    return value.length > limit ? `${value.substring(0, limit - 3)}…` : value;
}
