import { useParams } from 'react-router';
import { DeployedFlowDto } from 'nocode-api-builder-model';
import { useEffect, useState } from 'react';
import { usePublicFlowApiClient } from '../../api/PublicFlowApiClient';
import { FullscreenLayout } from '../../components/layout/FullscreenLayout';
import { LoadingFullscreenLayout } from '../../components/layout/LoadingFullscreenLayout';
import { ExecuteDeployedFlowPage } from './ExecuteDeployedFlowPage';
import { HttpClient } from '../../api/HttpClient';

export function ExecuteDeployedFlowRoute() {
	const apiClient = usePublicFlowApiClient();
	const { userId, url } = useParams();
	const [flow, setFlow] = useState<DeployedFlowDto | null>(null);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		async function load() {
			try {
				const response = await apiClient.getDeployedFlow(userId as string, url as string);
				setFlow(response.flow);
			} catch (e) {
				setError(HttpClient.readError(e));
				console.error(e);
			}
		}

		load();
	}, [userId, url, apiClient]);

	if (!flow) {
		return <LoadingFullscreenLayout />;
	}
	if (error) {
		return <>{error}</>;
	}
	return (
		<FullscreenLayout>
			<ExecuteDeployedFlowPage userId={userId as string} url={url as string} flow={flow} />
		</FullscreenLayout>
	);
}
