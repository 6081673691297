import { FlowDefinition, WorkflowInput, WorkflowLogs } from 'nocode-api-builder-model';
import { FlowInputFormState, mapFlowInputFormState } from '../../theme/flowInputForm/FlowInputFormState';
import { StepNameMap } from '../../theme/logs/Logs';
import { DefinitionWalker } from 'sequential-workflow-model';
import { FlowOutputState } from '../../theme/flowOutput/FlowOutputState';

export interface TestTabState {
	formState: FlowInputFormState;
	outputState: FlowOutputState | null;
	logs: WorkflowLogs;
	stepNameMap: StepNameMap;
}

export function createTestTabState(definition: FlowDefinition, initialInput: WorkflowInput | null): TestTabState {
	const formState = mapFlowInputFormState(definition, initialInput);

	return {
		formState,
		outputState: null,
		logs: [],
		stepNameMap: createStepNameMap(definition)
	};
}

function createStepNameMap(definition: FlowDefinition): StepNameMap {
	const walker = new DefinitionWalker();
	const stepNameMap: StepNameMap = {};
	walker.forEach(definition, step => {
		stepNameMap[step.id] = step.name;
	});
	return stepNameMap;
}
