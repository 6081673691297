import { FlowDefinition } from 'nocode-api-builder-model';
import { WrappedDefinition } from 'sequential-workflow-designer-react';

export interface DesignTabState {
	definition: WrappedDefinition<FlowDefinition>;
	isDirty: boolean;
}

export function createDesignTabState(definition: FlowDefinition): DesignTabState {
	return {
		definition: { value: definition } as WrappedDefinition<FlowDefinition>,
		isDirty: false
	};
}
