"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.appendTextStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const StepNameFormatter_1 = require("../../utilities/formatters/StepNameFormatter");
exports.appendTextStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('appendText', 'task', step => {
    step.label('AppendText');
    step.category('Primitives');
    step.description('Append a text to a variable');
    step.name()
        .dependentProperty('target')
        .dependentProperty('value')
        .value((0, sequential_workflow_editor_model_1.createGeneratedStringValueModel)({
        generator(context) {
            const target = context.formatPropertyValue('target', StepNameFormatter_1.StepNameFormatter.formatVariable);
            const value = context.formatPropertyValue('value', StepNameFormatter_1.StepNameFormatter.formatDynamic);
            return `${target} += ${value}`;
        }
    }));
    step.property('target').value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        valueType: 'text',
        isRequired: true
    }));
    step.property('value').value((0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, sequential_workflow_editor_model_1.createStringValueModel)({
                multiline: true
            }),
            (0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
                valueType: 'text',
                isRequired: true
            })
        ]
    }));
    step.property('newLine').value((0, sequential_workflow_editor_model_1.createBooleanValueModel)({}));
});
