import { ImageTemplate } from 'nocode-api-builder-model';
import { Editor } from 'mini-canvas-editor';

export class ImageTemplateEditor {
	public static async open(value: ImageTemplate | null): Promise<ImageTemplateEditor> {
		const popup = document.createElement('div');
		popup.className = 'mcep-popup';

		const bar = document.createElement('div');
		bar.className = 'mcep-popup-bar';

		const placeholder = document.createElement('div');
		placeholder.className = 'mcep-popup-placeholder';

		const okButton = document.createElement('button');
		okButton.className = 'mcep-popup-button';
		okButton.textContent = 'OK';

		const cancelButton = document.createElement('button');
		cancelButton.className = 'mcep-popup-button';
		cancelButton.textContent = 'Cancel';

		popup.appendChild(bar);
		popup.appendChild(placeholder);
		bar.appendChild(okButton);
		bar.appendChild(cancelButton);
		document.body.appendChild(popup);

		let editor: Editor;
		if (value) {
			editor = await Editor.createFromJSON(value.template as any, placeholder, {});
		} else {
			editor = Editor.createBlank(placeholder, 500, 500, {});
		}
		const instance = new ImageTemplateEditor(popup, editor);
		okButton.addEventListener('click', instance.onSaveClicked, false);
		cancelButton.addEventListener('click', instance.onCloseClicked, false);
		return instance;
	}

	private closeHandler?: (result: ImageTemplate | null) => void;

	private constructor(
		private readonly popup: HTMLElement,
		private readonly editor: Editor
	) {}

	private readonly onSaveClicked = (e: Event) => {
		e.preventDefault();
		this.close({
			template: this.editor.toJSON(),
			layers: this.editor.getWorkspaceObjects().map(o => ({
				name: o.get('label'),
				type: o.type
			}))
		});
	};

	private readonly onCloseClicked = (e: Event) => {
		e.preventDefault();
		this.closeHandler?.(null);
		this.close(null);
	};

	private close(result: ImageTemplate | null) {
		if (this.closeHandler) {
			this.closeHandler(result);
			this.closeHandler = undefined;
		}
		this.editor.destroy();
		document.body.removeChild(this.popup);
	}

	public waitForClose(): Promise<ImageTemplate | null> {
		return new Promise(resolve => {
			this.closeHandler = resolve;
		});
	}
}
