"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractListStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
exports.extractListStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('extractList', 'task', step => {
    step.label('ExtractList');
    step.category('Answers');
    step.property('input')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        isRequired: true,
        valueType: 'text'
    }))
        .hint('Expected type: text');
    step.property('output')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        isRequired: true,
        valueType: 'textCollection'
    }))
        .hint('Expected type: textCollection');
    step.property('removeQuotes').value((0, sequential_workflow_editor_model_1.createBooleanValueModel)({}));
    step.property('failureMode')
        .value((0, sequential_workflow_editor_model_1.createChoiceValueModel)({
        choices: ['empty collection', 'error']
    }))
        .label('Not found mode');
});
