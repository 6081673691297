"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.splitTextStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const StepNameFormatter_1 = require("../../utilities/formatters/StepNameFormatter");
exports.splitTextStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('splitText', 'task', step => {
    step.label('SplitText');
    step.category('Primitives');
    step.name()
        .dependentProperty('input')
        .dependentProperty('output')
        .value((0, sequential_workflow_editor_model_1.createGeneratedStringValueModel)({
        generator(context) {
            const input = context.formatPropertyValue('input', StepNameFormatter_1.StepNameFormatter.formatVariable);
            const output = context.formatPropertyValue('output', StepNameFormatter_1.StepNameFormatter.formatVariable);
            return `${output} = SplitText(${input})`;
        }
    }));
    step.property('input')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        isRequired: true,
        valueType: 'text'
    }))
        .hint('Expected type: text');
    step.property('output')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        isRequired: true,
        valueType: 'textCollection'
    }))
        .hint('Expected type: textCollection');
    step.property('separator').value((0, sequential_workflow_editor_model_1.createChoiceValueModel)({
        choices: ['[newline]', '[space]', 'custom']
    }));
    step.property('custom').value((0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, sequential_workflow_editor_model_1.createStringValueModel)({}),
            (0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
                valueType: 'text'
            })
        ]
    }));
    step.property('removeEmptyOrWhitespace')
        .value((0, sequential_workflow_editor_model_1.createBooleanValueModel)({
        defaultValue: false
    }))
        .label('Remove empty or whitespace-only texts')
        .hint('Remove empty or whitespace-only texts from the output collection.');
});
