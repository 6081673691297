import { ChangePasswordRequest } from 'nocode-api-builder-model';
import { useMemo } from 'react';
import { HttpClient } from './HttpClient';
import { AuthSession, useSession } from '../auth/AuthContext';
import { useHttpClient } from './HttpClientContext';

export function useAuthApiClient() {
	const httpClient = useHttpClient();
	const session = useSession();
	return useMemo(() => new AuthApiClient(httpClient, session), [httpClient, session]);
}

export class AuthApiClient {
	public constructor(
		private readonly httpClient: HttpClient,
		private readonly session: AuthSession
	) {}

	public async changePassword(request: ChangePasswordRequest): Promise<void> {
		return this.httpClient.request({
			method: 'PUT',
			url: `/api/auth/change-password`,
			body: request,
			authToken: this.session.token
		});
	}
}
