import { TraceJSON, WorkflowLog, WorkflowLogType, WorkflowLogs, WorkflowMetrics, WorkflowOutput } from 'nocode-api-builder-model';
import { FlowApiClient } from '../../../api/FlowApiClient';
import { TraceApiClient } from '../../../api/TraceApiClient';
import { HttpClient } from '../../../api/HttpClient';

export interface FlowExecutorResult {
	status: number;
	message: string;
	output: WorkflowOutput | null;
}

export class FlowExecutor {
	public constructor(
		private readonly flowApiClient: FlowApiClient,
		private readonly traceApiClient: TraceApiClient,
		private readonly onLog: (log: WorkflowLog) => void,
		private readonly onFinished: (output: WorkflowOutput) => void
	) {}

	public async execute(id: string, inputs: Record<string, unknown>): Promise<void> {
		try {
			this.onLog([WorkflowLogType.debug, null, 'Executing...']);

			const { traceId } = await this.flowApiClient.testFlow(id, inputs);

			let minIndex = 0;
			while (true) {
				const { traceStatus, traceLogs } = await this.traceApiClient.getTraceLogs({
					traceId,
					minIndex
				});

				// eslint-disable-next-line no-loop-func
				traceLogs.forEach(traceLog => {
					minIndex = Math.max(minIndex, traceLog.index + 1);
					this.copyLogs(traceLog.logs);
				});

				if (traceStatus !== null) {
					break;
				}

				await sleep(500);
			}

			const trace = await this.traceApiClient.getTrace({ traceId });

			this.dumpResult(trace);
			this.copyMetrics(trace.metrics);

			if (trace.output) {
				this.onFinished(trace.output);
			}
		} catch (e) {
			const error = HttpClient.readError(e);
			this.onLog([WorkflowLogType.error, null, `An error occurred: ${error}`]);
		}
	}

	private copyLogs(logs: WorkflowLogs) {
		logs.forEach(this.onLog);
	}

	private dumpResult(trace: TraceJSON) {
		const status = trace.status === 0 ? 'Success' : 'Failure';
		this.onLog([WorkflowLogType.debug, null, `Execution finished with status ${trace.status} (${status}) in ${trace.duration}ms`]);
	}

	private copyMetrics(metrics: WorkflowMetrics) {
		const entries: [string, string][] = Object.entries(metrics).map(([key, value]) => [key, String(value)]);
		if (entries.length > 0) {
			this.onLog([WorkflowLogType.debug, null, 'Metrics', ...entries]);
		}
	}
}

function sleep(ms: number): Promise<void> {
	return new Promise(resolve => setTimeout(resolve, ms));
}
