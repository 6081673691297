"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateImageStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const StepNameFormatter_1 = require("../../utilities/formatters/StepNameFormatter");
const external_1 = require("@nocode-js/sequential-workflow-editor-model-pro/value-models/external");
const dependent_1 = require("@nocode-js/sequential-workflow-editor-model-pro/value-models/dependent");
const key_variable_dictionary_1 = require("@nocode-js/sequential-workflow-editor-model-pro/value-models/key-variable-dictionary");
exports.generateImageStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('generateImage', 'task', step => {
    step.label('GenerateImage');
    step.category('Transformers');
    step.name()
        .dependentProperty('output')
        .value((0, sequential_workflow_editor_model_1.createGeneratedStringValueModel)({
        generator(context) {
            const output = context.formatPropertyValue('output', StepNameFormatter_1.StepNameFormatter.formatVariable);
            return `${output} = GenerateImage()`;
        }
    }));
    step.property('template')
        .value((0, external_1.createExternalValueModel)({
        defaultValue: null,
        editorId: 'imageTemplateEditor'
    }))
        .validator({
        validate(context) {
            return context.getValue() ? null : 'Template is required';
        }
    });
    step.property('mapping')
        .dependentProperty('template')
        .value((0, dependent_1.createDependentValueModel)({
        model: (0, key_variable_dictionary_1.createKeyVariableDictionaryValueModel)({
            areVariablesRequired: true,
            getAllowedTypes() {
                return ['image'];
            }
        }),
        generator(context, currentValue) {
            var _a;
            const template = context.getPropertyValue('template');
            const items = ((_a = template === null || template === void 0 ? void 0 : template.layers) !== null && _a !== void 0 ? _a : [])
                .filter(layer => layer.type === 'rect' && layer.name.startsWith('@'))
                .map(layer => {
                var _a, _b;
                return ({
                    key: layer.name,
                    variableName: (_b = (_a = currentValue === null || currentValue === void 0 ? void 0 : currentValue.items.find(item => item.key === layer.name)) === null || _a === void 0 ? void 0 : _a.variableName) !== null && _b !== void 0 ? _b : null
                });
            });
            return { items };
        }
    }))
        .validator({
        validate(context) {
            const template = context.getPropertyValue('template');
            if (template) {
                const value = context.getValue();
                for (const item of value.value.items) {
                    if (!template.layers.some(l => l.name === item.key)) {
                        return `Layer ${item.key} is not defined`;
                    }
                }
            }
            return null;
        }
    });
    step.property('output')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        valueType: 'image',
        isRequired: true
    }))
        .hint('Expected type: image');
});
