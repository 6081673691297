import { BranchesValueEditorExtension } from '@nocode-js/sequential-workflow-editor-pro/value-editors/branches';
import { PasswordValueEditorExtension } from '@nocode-js/sequential-workflow-editor-pro/value-editors/password';
import { RichVariableDefinitionsValueEditorExtension } from '@nocode-js/sequential-workflow-editor-pro/value-editors/rich-variable-definitions';
import { ExternalValueEditorExtension } from '@nocode-js/sequential-workflow-editor-pro/value-editors/external';
import { DependentValueEditorExtension } from '@nocode-js/sequential-workflow-editor-pro/value-editors/dependent';
import { KeyVariableDictionaryValueEditorExtension } from '@nocode-js/sequential-workflow-editor-pro/value-editors/key-variable-dictionary';
import { StringsValueEditorExtension } from '@nocode-js/sequential-workflow-editor-pro/value-editors/strings';
import { ImageTemplateEditor } from './ImageTemplateEditor';
import { ImageTemplate, modelEncryptionKey } from 'nocode-api-builder-model';
import { EditorExtension, StringValueEditorEditorExtension, ValueEditorFactory } from 'sequential-workflow-editor';
import {
	JavaScriptCodeEditorConfiguration,
	createJavaScriptCodeEditor,
	javaScriptCodeEditorId
} from './javaScriptCodeEditor/javaScriptCodeEditor';

export interface EditorExtensionsConfiguration {
	javaScriptEditor: JavaScriptCodeEditorConfiguration;
}

export function createEditorExtensions(configuration: EditorExtensionsConfiguration): EditorExtension[] {
	return [
		BranchesValueEditorExtension.create({
			editorId: 'dynamicBranches'
		}),
		PasswordValueEditorExtension.create({
			encryptionKey: modelEncryptionKey
		}),
		RichVariableDefinitionsValueEditorExtension.create(),
		DependentValueEditorExtension.create({}),
		KeyVariableDictionaryValueEditorExtension.create({}),
		ExternalValueEditorExtension.create<ImageTemplate | null>({
			editorId: 'imageTemplateEditor',
			labelProvider: value => (value ? 'Edit template' : 'Create template'),
			valueProvider: async startValue => {
				const editor = await ImageTemplateEditor.open(startValue);
				return (await editor.waitForClose()) ?? startValue;
			}
		}),
		StringsValueEditorExtension.create(),
		StringValueEditorEditorExtension.create({
			editorId: 'evaluableString',
			class: 'evaluable-string-editor'
		}),
		{
			valueEditors: [
				{
					editorId: javaScriptCodeEditorId,
					factory: createJavaScriptCodeEditor(configuration.javaScriptEditor) as ValueEditorFactory
				}
			]
		}
	];
}
