import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.scss';
import 'sequential-workflow-editor/css/editor.css';
import '@nocode-js/sequential-workflow-editor-pro/css/value-editors/rich-variable-definitions.css';

import { App } from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
