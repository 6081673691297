import { useEffect, useState } from 'react';

export interface DropdownProps {
	theme?: 'primary' | 'secondary';
	options: string[];
	value: number | string | null;
	onChanged: (index: number) => void;
}

export function Dropdown(props: DropdownProps) {
	const [isOpened, setIsOpened] = useState(false);
	const [buttonRef, setButtonRef] = useState<HTMLButtonElement | null>(null);
	const [dropdownRef, setDropdownRef] = useState<HTMLDivElement | null>(null);
	const value = typeof props.value === 'number' ? props.options[props.value] : props.value;
	let cls: string;
	if (props.theme === 'secondary') {
		cls = 'text-white bg-gray-700 hover:bg-gray-600';
	} else {
		cls = 'text-white bg-blue-600 hover:bg-blue-500';
	}

	useEffect(() => {
		function handleClickOutside(e: MouseEvent) {
			if (dropdownRef && !dropdownRef.contains(e.target as Node)) {
				setIsOpened(false);
			}
		}
		if (isOpened) {
			document.addEventListener('click', handleClickOutside, false);
			return () => document.removeEventListener('click', handleClickOutside, false);
		}
	}, [dropdownRef, isOpened]);

	function toggle(e: React.MouseEvent) {
		e.stopPropagation();
		e.preventDefault();

		const is = !isOpened;
		setIsOpened(is);
		if (is && dropdownRef && buttonRef) {
			dropdownRef.style.top = '-999999px';

			setTimeout(() => {
				const width = Math.max(buttonRef.clientWidth, dropdownRef.clientWidth);
				const offset = buttonRef.clientWidth - width;
				dropdownRef.style.width = width + 'px';
				dropdownRef.style.top = '';
				dropdownRef.style.marginLeft = offset + 'px';
			});
		}
	}

	function select(index: number) {
		setIsOpened(false);
		props.onChanged(index);
	}

	return (
		<>
			<button
				type="button"
				className={`${cls} w-full font-medium rounded-md text-sm px-4 py-2 inline-flex items-center`}
				onClick={toggle}
				ref={setButtonRef}
			>
				{value !== null && <span className="inline flex-1 w-full text-left mr-2">{value}</span>}
				<svg
					className="w-4 h-4"
					aria-hidden="true"
					fill="none"
					stroke="currentColor"
					viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
				</svg>
			</button>
			<div
				className={'absolute z-50 bg-white divide-y divide-gray-100 rounded-lg shadow' + (isOpened ? '' : ' hidden')}
				ref={setDropdownRef}
			>
				<ul className="py-2 text-sm text-gray-700" aria-labelledby="dropdownDefaultButton">
					{props.options.map((option, index) => (
						<li key={index}>
							<span
								className="block px-4 py-2 cursor-pointer whitespace-nowrap hover:bg-gray-100"
								onClick={() => select(index)}
							>
								{option}
							</span>
						</li>
					))}
				</ul>
			</div>
		</>
	);
}
