import { WorkflowInput } from 'nocode-api-builder-model';

const inputStorageKey = '__flowTesterInput';

export class FlowTesterStorage {
	public static setInput(processId: string, input: WorkflowInput) {
		const rawValue = buildPrefix(processId) + JSON.stringify(input);
		window.localStorage.setItem(inputStorageKey, rawValue);
	}

	public static tryGetInput(processId: string): WorkflowInput | null {
		const rawValue = window.localStorage.getItem(inputStorageKey);
		const prefix = buildPrefix(processId);
		if (!rawValue || !rawValue.startsWith(prefix)) {
			return null;
		}
		return JSON.parse(rawValue.substring(prefix.length));
	}
}

function buildPrefix(processId: string) {
	return processId + ';';
}
