"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.imagineDalleStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const StepNameFormatter_1 = require("../../utilities/formatters/StepNameFormatter");
const RichTextValidator_1 = require("../../utilities/richText/RichTextValidator");
const evaluableStringValueModel_1 = require("../../model/evaluableStringValueModel");
exports.imagineDalleStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('imagineDalle', 'task', step => {
    step.label('ImagineDallE');
    step.category('AI');
    step.name()
        .dependentProperty('prompt')
        .dependentProperty('output')
        .value((0, sequential_workflow_editor_model_1.createGeneratedStringValueModel)({
        generator(context) {
            const prompt = context.formatPropertyValue('prompt', StepNameFormatter_1.StepNameFormatter.formatDynamic);
            const output = context.formatPropertyValue('output', StepNameFormatter_1.StepNameFormatter.formatVariable);
            return `${output} = ImagineDallE(${prompt})`;
        }
    }));
    step.property('prompt')
        .value((0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, evaluableStringValueModel_1.createEvaluableStringValueModel)({
                multiline: 20
            }),
            (0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
                valueType: 'text',
                isRequired: true
            })
        ]
    }))
        .label('Prompt')
        .hint('{$varName} - passes value of variable')
        .validator(RichTextValidator_1.RichTextValidator.dynamicTextValidator);
    step.property('output')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        isRequired: true,
        valueType: 'image'
    }))
        .label('Output');
});
