import { FlowDefinition, TriggerJSON } from 'nocode-api-builder-model';
import { CronTrigger } from '../../theme/flow/TriggersTabForm';
import { mapFlowInputFormState } from '../../theme/flowInputForm/FlowInputFormState';
import { Randomizer } from '../../utilities/Randomizer';

export interface TriggersTabState {
	cronTriggers: CronTrigger[];
	isDirty: boolean;
	isValid: boolean;
}

export function createTriggersTabState(definition: FlowDefinition, triggers: TriggerJSON[] | undefined): TriggersTabState {
	const state = {
		isDirty: false,
		cronTriggers: triggers
			? triggers.map<CronTrigger>(json => ({
					id: json.id,
					index: json.index,
					name: json.name,
					isEnabled: json.isEnabled,
					parameters: json.parameters,
					lastTime: json.lastTime,
					nextTime: json.nextTime,
					fires: json.fires,
					inputFormState: mapFlowInputFormState(definition, json.input)
			  }))
			: []
	};
	return {
		...state,
		isValid: validateTriggersTabState(state)
	};
}

export function updateTriggersTabState(state: TriggersTabState, updatedDefinition: FlowDefinition): TriggersTabState {
	const definitionInputs = updatedDefinition.properties.inputs.variables;
	const isReloadNeeded = state.cronTriggers.some(trigger => {
		return (
			definitionInputs.length !== trigger.inputFormState.fields.length ||
			definitionInputs.some(v => trigger.inputFormState.fields.find(f => f.spec.name !== v.name || f.spec.type !== v.type))
		);
	});
	if (!isReloadNeeded) {
		return state;
	}

	const newState = {
		cronTriggers: state.cronTriggers.map<CronTrigger>(trigger => ({
			...trigger,
			// TODO: this operation loses the current input values
			inputFormState: mapFlowInputFormState(updatedDefinition, null)
		}))
	};
	return {
		...newState,
		isDirty: true,
		isValid: validateTriggersTabState(newState)
	};
}

export function createEmptyCronTrigger(index: number, definition: FlowDefinition): CronTrigger {
	return {
		id: null,
		index,
		name: Randomizer.randomName(),
		isEnabled: true,
		lastTime: null,
		nextTime: null,
		parameters: 'd:12',
		fires: 0,
		inputFormState: mapFlowInputFormState(definition, null)
	};
}

export function validateTriggersTabState(state: Omit<TriggersTabState, 'isDirty' | 'isValid'>): boolean {
	return state.cronTriggers.length === 0 || state.cronTriggers.every(t => !t.inputFormState.hasError);
}
