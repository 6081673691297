"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.breakStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
exports.breakStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('break', 'interruptingTask', step => {
    step.label('Break');
    step.category('Flow');
    step.description('Breaks the loop and continues with the next step in the workflow.');
    step.name().value((0, sequential_workflow_editor_model_1.createStringValueModel)({
        defaultValue: 'Break()'
    }));
});
