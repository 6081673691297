import {
	CreateFlowRequest,
	CreateFlowResponse,
	TestFlowRequest,
	TestFlowResponse,
	GetFlowResponse,
	GetFlowsResponse,
	UpdateFlowRequest,
	DuplicateFlowResponse
} from 'nocode-api-builder-model';
import { AuthSession, useSession } from '../auth/AuthContext';
import { HttpClient } from './HttpClient';
import { useMemo } from 'react';
import { useHttpClient } from './HttpClientContext';

export function useFlowApiClient(): FlowApiClient {
	const httpClient = useHttpClient();
	const session = useSession();
	return useMemo(() => new FlowApiClient(httpClient, session), [httpClient, session]);
}

export class FlowApiClient {
	public constructor(
		private readonly httpClient: HttpClient,
		private readonly session: AuthSession
	) {}

	public getFlows(): Promise<GetFlowsResponse> {
		return this.httpClient.request({
			method: 'GET',
			url: '/api/flows',
			authToken: this.session.token
		});
	}

	public getFlow(id: string): Promise<GetFlowResponse> {
		return this.httpClient.request({
			method: 'GET',
			url: `/api/flows/${id}`,
			authToken: this.session.token
		});
	}

	public async createFlow(request: CreateFlowRequest): Promise<string> {
		const result = await this.httpClient.request<CreateFlowResponse>({
			method: 'POST',
			url: '/api/flows',
			body: request,
			authToken: this.session.token
		});
		return result['id'];
	}

	public async updateFlow(id: string, request: UpdateFlowRequest): Promise<void> {
		await this.httpClient.request({
			method: 'PUT',
			url: `/api/flows/${id}`,
			body: request,
			authToken: this.session.token
		});
	}

	public async deleteFlow(id: string): Promise<void> {
		await this.httpClient.request({
			method: 'DELETE',
			url: `/api/flows/${id}`,
			authToken: this.session.token
		});
	}

	public async duplicateFlow(id: string): Promise<DuplicateFlowResponse> {
		return await this.httpClient.request({
			method: 'POST',
			url: `/api/duplicate-flow/${id}`,
			authToken: this.session.token
		});
	}

	public async testFlow(id: string, request: TestFlowRequest): Promise<TestFlowResponse> {
		return await this.httpClient.request({
			method: 'POST',
			url: `/api/flows/${id}/tests`,
			body: request,
			authToken: this.session.token
		});
	}
}
