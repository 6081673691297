import { useEffect, useState } from 'react';
import { DefaultLayout } from '../../components/layout/DefaultLayout';
import { Dashboard, DashboardPanel, DashboardStatPanel, DashboardRowGrid } from '../../components/theme/dashboard/Dashboard';
import { useTraceApiClient } from '../../api/TraceApiClient';
import { LoadingDefaultLayout } from '../../components/layout/LoadingDefaultLayout';
import { ErrorDefaultLayout } from '../../components/layout/ErrorDefaultLayout';
import { HttpClient } from '../../api/HttpClient';
import { SubscriptionUsageDto, TraceStatsDto } from 'nocode-api-builder-model';
import { Chart } from './Chart';
import { useSubscriptionApiClient } from '../../api/SubscriptionApiClient';
import { CurrentSubscription } from '../../components/theme/dashboard/CurrentSubscription';

const DAY_TIME_RANGE = 86400000;
const WEEK_TIME_RANGE = 604800000;
const TWO_WEEKS_TIME_RANGE = WEEK_TIME_RANGE * 2;

interface DashboardRouteData {
	fromTime: number;
	lastDay: TraceStatsDto;
	lastWeek: TraceStatsDto;
	last2Weeks: TraceStatsDto;
	all: TraceStatsDto[];
	subscriptionUsage: SubscriptionUsageDto | null;
}

export function DashboardRoute() {
	const traceApiClient = useTraceApiClient();
	const subscriptionApiClient = useSubscriptionApiClient();
	const [data, setData] = useState<DashboardRouteData | null>(null);

	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		async function load() {
			try {
				const now = Date.now();
				let fromTime = now - TWO_WEEKS_TIME_RANGE;
				fromTime = fromTime - (fromTime % DAY_TIME_RANGE);
				const fromWeek = now - WEEK_TIME_RANGE;
				const fromDay = now - DAY_TIME_RANGE;
				const [r1, r2] = await Promise.all([
					traceApiClient.getTraceStats({ fromTime }),
					subscriptionApiClient.getSubscriptionUsage()
				]);

				setData({
					fromTime,
					lastDay: sum(r1, fromDay),
					lastWeek: sum(r1, fromWeek),
					last2Weeks: sum(r1, fromTime),
					all: r1,
					subscriptionUsage: r2.usage
				});
			} catch (e) {
				setError(HttpClient.readError(e));
			}
		}

		load();
	}, [traceApiClient, subscriptionApiClient]);

	async function onManageSubscriptionClicked() {
		const portal = await subscriptionApiClient.getSubscriptionPortal();
		window.location.href = portal.url;
	}

	if (error) {
		return <ErrorDefaultLayout error={error} />;
	}
	if (!data) {
		return <LoadingDefaultLayout />;
	}

	return (
		<DefaultLayout>
			<Dashboard>
				<CurrentSubscription usage={data.subscriptionUsage} onManageSubscriptionClicked={onManageSubscriptionClicked} />

				<DashboardRowGrid>
					<DashboardStatPanel title="Last day" value={String(data.lastDay.count)} description={createDescription(data.lastDay)} />
					<DashboardStatPanel
						title="Last week"
						value={String(data.lastWeek.count)}
						description={createDescription(data.lastWeek)}
					/>
					<DashboardStatPanel
						title="Last 2 weeks"
						value={String(data.last2Weeks.count)}
						description={createDescription(data.last2Weeks)}
					/>
				</DashboardRowGrid>

				<DashboardRowGrid>
					<DashboardPanel title="Executions">
						<Chart fromTime={data.fromTime} traces={data.all} field="count" />
					</DashboardPanel>
					<DashboardPanel title="Cost">
						<Chart fromTime={data.fromTime} traces={data.all} field="cost" />
					</DashboardPanel>
					<DashboardPanel title="CUs">
						<Chart fromTime={data.fromTime} traces={data.all} field="computeUnits" />
					</DashboardPanel>
				</DashboardRowGrid>
			</Dashboard>
		</DefaultLayout>
	);
}

function sum(traces: TraceStatsDto[], fromTime: number): TraceStatsDto {
	return traces.reduce(
		(acc, trace) => {
			if (trace.time >= fromTime) {
				acc.count += trace.count;
				acc.computeUnits += trace.computeUnits;
				acc.cost += trace.cost;
			}
			return acc;
		},
		{
			time: fromTime,
			count: 0,
			computeUnits: 0,
			cost: 0
		}
	);
}

function createDescription(stats: TraceStatsDto) {
	return `executions (${stats.computeUnits} CUs, $${stats.cost.toFixed(5)})`;
}
