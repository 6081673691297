"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.chatOpenAiStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const StepNameFormatter_1 = require("../../utilities/formatters/StepNameFormatter");
const RichTextValidator_1 = require("../../utilities/richText/RichTextValidator");
const evaluableStringValueModel_1 = require("../../model/evaluableStringValueModel");
exports.chatOpenAiStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('chatOpenAi', 'task', step => {
    step.label('ChatOpenAi');
    step.category('AI');
    step.name()
        .dependentProperty('prompt')
        .dependentProperty('output')
        .value((0, sequential_workflow_editor_model_1.createGeneratedStringValueModel)({
        generator(context) {
            const prompt = context.formatPropertyValue('prompt', StepNameFormatter_1.StepNameFormatter.formatDynamic);
            const output = context.formatPropertyValue('output', StepNameFormatter_1.StepNameFormatter.formatVariable);
            return `${output} = ChatOpenAi(${prompt})`;
        }
    }));
    step.property('model').value((0, sequential_workflow_editor_model_1.createChoiceValueModel)({
        choices: ['gpt-3.5-turbo', 'gpt-3.5-turbo-16k', 'gpt-4-1106-preview']
    }));
    step.property('prompt')
        .value((0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, evaluableStringValueModel_1.createEvaluableStringValueModel)({
                multiline: 20
            }),
            (0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
                valueType: 'text',
                isRequired: true
            })
        ]
    }))
        .label('Prompt')
        .hint('{$varName} - passes value of variable')
        .validator(RichTextValidator_1.RichTextValidator.dynamicTextValidator);
    step.property('output')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        isRequired: true,
        valueType: 'text'
    }))
        .label('Output');
});
