import { FormEvent } from 'react';
import { Button } from '../primitives/Button';
import { Input } from '../primitives/Input';
import { FullscreenForm } from '../layout/FullscreenForm';

export interface ConfirmSignUpProps {
	isLoading: boolean;
	confirmationCode: string;
	error: string | null;
	onConfirmationCodeChanged: (code: string) => void;
	onSubmit: () => void;
}

export function ConfirmSignUp(props: ConfirmSignUpProps) {
	function onSubmit(e: FormEvent) {
		e.preventDefault();
		props.onSubmit();
	}

	return (
		<FullscreenForm
			title="Create New Account"
			description="We've just sent the confirmation code to your e-mail address. Please enter it below."
			error={props.error}
		>
			<form className="space-y-4 md:space-y-6" onSubmit={onSubmit}>
				<div>
					<label className="block mb-2 text-sm font-medium text-gray-900">Confirmation Code</label>
					<Input value={props.confirmationCode} name="code" onChanged={props.onConfirmationCodeChanged} />
				</div>

				<Button type="submit" isDisabled={props.isLoading}>
					Confirm
				</Button>
			</form>
		</FullscreenForm>
	);
}
