export class HttpError extends Error {
	public static is(obj: unknown): obj is HttpError {
		return (obj as Error).name === 'HttpError';
	}

	public constructor(
		message: string,
		public readonly statusCode: number,
		public readonly data: object | null
	) {
		super(message);
		this.name = 'HttpError';
	}
}
