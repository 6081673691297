import { useMemo } from 'react';
import { HttpClient } from './HttpClient';
import { useHttpClient } from './HttpClientContext';
import { ExecuteDeployedFlowRequest, ExecuteDeployedFlowResponse, GetDeployedFlowResponse } from 'nocode-api-builder-model';

export function usePublicFlowApiClient() {
	const httpClient = useHttpClient();
	return useMemo(() => new PublicFlowApiClient(httpClient), [httpClient]);
}

export class PublicFlowApiClient {
	public constructor(private readonly httpClient: HttpClient) {}

	public getDeployedFlow(userId: string, url: string): Promise<GetDeployedFlowResponse> {
		return this.httpClient.request({
			method: 'GET',
			url: `/api/deployed-flows/${userId}/${url}`
		});
	}

	public executeDeployedFlow(
		userId: string,
		url: string,
		accessCode: string | null,
		request: ExecuteDeployedFlowRequest
	): Promise<ExecuteDeployedFlowResponse> {
		const query = new URLSearchParams();
		query.set('_source', 'form');
		if (accessCode) {
			query.set('_accessCode', accessCode);
		}
		return this.httpClient.request({
			method: 'POST',
			url: `/api/deployed-flows/${userId}/${url}?${query.toString()}`,
			body: request
		});
	}
}
