import { FormEvent } from 'react';
import { CenteredBox } from '../layout/CenteredBox';
import { PageTitle } from '../layout/PageTitle';
import { Button } from '../primitives/Button';
import { Input } from '../primitives/Input';

export interface ChangePasswordData {
	currentPassword: string;
	password1: string;
	password2: string;
}

export interface ChangePasswordProps {
	error: string | null;
	success: boolean;
	data: ChangePasswordData;
	onChanged: (data: ChangePasswordData) => void;
	onSubmit: () => void;
}

export function ChangePassword(props: ChangePasswordProps) {
	function onSubmit(e: FormEvent) {
		e.preventDefault();
		props.onSubmit();
	}

	function onCurrentPasswordChanged(currentPassword: string) {
		props.onChanged({
			...props.data,
			currentPassword
		});
	}

	function onPassword1Changed(password1: string) {
		props.onChanged({
			...props.data,
			password1
		});
	}

	function onPassword2Changed(password2: string) {
		props.onChanged({
			...props.data,
			password2
		});
	}

	return (
		<CenteredBox>
			<PageTitle title="Change Password"> </PageTitle>

			<form className="py-5 px-4" onSubmit={onSubmit}>
				{props.error && <div className="text-red-600 mb-2">{props.error}</div>}
				{props.success && <div className="text-green-600 mb-2">Password has changed successfully.</div>}

				<div className="mb-4">
					<label className="block mb-2 text-sm font-medium text-gray-900">Current Password *</label>
					<Input value={props.data.currentPassword} type="password" onChanged={onCurrentPasswordChanged} />
				</div>

				<div className="mb-4">
					<label className="block mb-2 text-sm font-medium text-gray-900">New Password *</label>
					<Input value={props.data.password1} type="password" onChanged={onPassword1Changed} />
				</div>

				<div className="mb-4">
					<label className="block mb-2 text-sm font-medium text-gray-900">Confirm New Password *</label>
					<Input value={props.data.password2} type="password" onChanged={onPassword2Changed} />
				</div>

				<div>
					<Button type="submit">Save</Button>
				</div>
			</form>
		</CenteredBox>
	);
}
