"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.javaScriptTestStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const model_1 = require("../../model");
exports.javaScriptTestStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('javaScript', 'task', step => {
    step.label('JavaScript');
    step.category('Primitives');
    step.description('Executes a JavaScript script.');
    step.name()
        .dependentProperty('script')
        .value((0, sequential_workflow_editor_model_1.createGeneratedStringValueModel)({
        generator(context) {
            const input = context.getPropertyValue('script').code.substring(0, 10);
            return `JavaScript("${input}")`;
        }
    }));
    step.property('variables').value((0, sequential_workflow_editor_model_1.createAnyVariablesValueModel)({}));
    step.property('script').value((0, model_1.createJavaScriptCodeValueModel)({})).label('Script');
    step.property('timeout').value((0, sequential_workflow_editor_model_1.createNumberValueModel)({
        min: 100,
        defaultValue: 5000
    }));
});
