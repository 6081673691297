"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.imageRequestStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const StepNameFormatter_1 = require("../../utilities/formatters/StepNameFormatter");
const RichTextValidator_1 = require("../../utilities/richText/RichTextValidator");
const evaluableStringValueModel_1 = require("../../model/evaluableStringValueModel");
exports.imageRequestStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('imageRequest', 'task', step => {
    step.label('ImageRequest');
    step.category('Services');
    step.name()
        .dependentProperty('method')
        .dependentProperty('response')
        .dependentProperty('url')
        .value((0, sequential_workflow_editor_model_1.createGeneratedStringValueModel)({
        generator(context) {
            const method = context.getPropertyValue('method');
            const url = context.formatPropertyValue('url', StepNameFormatter_1.StepNameFormatter.formatDynamicUrl);
            const response = context.formatPropertyValue('response', StepNameFormatter_1.StepNameFormatter.formatVariable);
            return `${response} = ${method} ${url}`;
        }
    }));
    step.property('method').value((0, sequential_workflow_editor_model_1.createChoiceValueModel)({
        choices: ['GET'],
        defaultValue: 'GET'
    }));
    step.property('url')
        .value((0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, evaluableStringValueModel_1.createEvaluableStringValueModel)({
                minLength: 1
            }),
            (0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
                valueType: 'text',
                isRequired: true
            })
        ]
    }))
        .validator(RichTextValidator_1.RichTextValidator.dynamicUrlValidator);
    step.property('response')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        valueType: 'image',
        isRequired: true
    }))
        .label('Response variable');
    step.property('timeout')
        .value((0, sequential_workflow_editor_model_1.createNumberValueModel)({
        defaultValue: 15000,
        max: 60000,
        min: 10
    }))
        .hint('The timeout in milliseconds.');
});
