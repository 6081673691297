"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createJavaScriptCodeValueModel = exports.javaScriptCodeValueModelId = void 0;
exports.javaScriptCodeValueModelId = 'javaScriptCode';
const createJavaScriptCodeValueModel = (configuration) => ({
    create: (path) => ({
        id: exports.javaScriptCodeValueModelId,
        editorId: exports.javaScriptCodeValueModelId,
        label: 'String',
        path,
        configuration,
        getDefaultValue() {
            return {
                code: 'async function main() {\n  \n}\n\nmain();',
                question: ''
            };
        },
        getVariableDefinitions: () => null,
        validate: () => null
    })
});
exports.createJavaScriptCodeValueModel = createJavaScriptCodeValueModel;
