"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.chunkTextStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const StepNameFormatter_1 = require("../../utilities/formatters/StepNameFormatter");
exports.chunkTextStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('chunkText', 'task', step => {
    step.label('ChunkText');
    step.category('Primitives');
    step.name()
        .dependentProperty('input')
        .dependentProperty('output')
        .value((0, sequential_workflow_editor_model_1.createGeneratedStringValueModel)({
        generator(context) {
            const input = context.formatPropertyValue('input', StepNameFormatter_1.StepNameFormatter.formatVariable);
            const output = context.formatPropertyValue('output', StepNameFormatter_1.StepNameFormatter.formatVariable);
            return `${output} = ChunkText(${input})`;
        }
    }));
    step.property('input')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        isRequired: true,
        valueType: 'text'
    }))
        .hint('Expected type: text');
    step.property('output')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        isRequired: true,
        valueType: 'textCollection'
    }))
        .hint('Expected type: textCollection');
    function num(defaultValue) {
        return (0, sequential_workflow_editor_model_1.createDynamicValueModel)({
            models: [
                (0, sequential_workflow_editor_model_1.createNumberValueModel)({
                    min: 1,
                    defaultValue
                }),
                (0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
                    isRequired: true,
                    valueType: 'number'
                })
            ]
        });
    }
    step.property('chunkSize').value(num(200)).hint('Chunk size is measured by number of characters');
    step.property('chunkOverlap').value(num(20));
    step.property('separator').value((0, sequential_workflow_editor_model_1.createChoiceValueModel)({
        choices: ['[space]']
    }));
    step.property('keepSeparator').value((0, sequential_workflow_editor_model_1.createBooleanValueModel)({
        defaultValue: false
    }));
});
