import { useMemo, useState } from 'react';
import { createFlowModes } from '../FlowModes';
import { TestTab } from './TestTab';
import { TestTabState, createTestTabState } from './TestTabState';
import { FlowExecutor } from './FlowExecutor';
import { FlowDefinition, FlowJSON, WorkflowLog, WorkflowOutput } from 'nocode-api-builder-model';
import { FlowTab, FlowTabHost } from '../../theme/flow/FlowTabHost';
import { useFlowApiClient } from '../../../api/FlowApiClient';
import { useTraceApiClient } from '../../../api/TraceApiClient';
import { readFlowInputFormStateValues } from '../../theme/flowInputForm/FlowInputFormState';
import { createFlowOutputState } from '../../theme/flowOutput/FlowOutputState';
import { FlowTesterStorage } from './FlowTesterStorage';

export interface FlowTesterProps {
	id: string;
	flow: FlowJSON;
	onModeChanged: (modeId: string) => void;
}

export function FlowTester(props: FlowTesterProps) {
	const flowApiClient = useFlowApiClient();
	const traceApiClient = useTraceApiClient();
	const [isExecuting, setIsExecuting] = useState(false);
	const definition = useMemo<FlowDefinition>(() => JSON.parse(props.flow.definition), [props.flow.definition]);
	const [state, setState] = useState(() => {
		const lastInput = FlowTesterStorage.tryGetInput(props.id);
		return createTestTabState(definition, lastInput);
	});
	const isValid = !state.formState.hasError;

	const modes = useMemo(() => createFlowModes('test', false), []);
	const tabs: FlowTab[] = useMemo(
		() => [
			{
				id: 'test',
				label: 'Test',
				isDirty: false,
				isValid
			}
		],
		[isValid]
	);

	function onModeChanged(index: number) {
		props.onModeChanged(modes[index].id);
	}

	async function onRunClicked() {
		if (!isValid || isExecuting) {
			return;
		}
		setIsExecuting(true);

		const newState: TestTabState = {
			...state,
			logs: [],
			outputState: null
		};
		setState(newState);

		try {
			function onLog(log: WorkflowLog) {
				newState.logs.push(log);
				setState({ ...newState, logs: [...newState.logs] });
			}

			function setOutput(output: WorkflowOutput) {
				newState.outputState = createFlowOutputState(
					definition.properties.outputs.variables
						.map(v => ({
							name: v.name,
							type: v.type,
							value: output[v.name]
						}))
						.filter(f => f.value !== undefined)
				);
				setState({ ...newState });
			}

			const executor = new FlowExecutor(flowApiClient, traceApiClient, onLog, setOutput);
			const values = await readFlowInputFormStateValues(state.formState);
			FlowTesterStorage.setInput(props.id, values);
			await executor.execute(props.flow.id, values);
		} finally {
			setIsExecuting(false);
		}
	}

	return (
		<FlowTabHost
			name={props.flow.name}
			modes={modes}
			onModeChanged={onModeChanged}
			tabs={tabs}
			currentTabId={tabs[0].id}
			onTabClicked={() => {}}
			primaryButtonLabel="Run"
			isPrimaryButtonDisabled={!isValid || isExecuting}
			onPrimaryButtonClicked={onRunClicked}
		>
			<TestTab description={props.flow.description} url={props.flow.url} state={state} onStateChanged={setState} />
		</FlowTabHost>
	);
}
