import { Fragment, useEffect, useState } from 'react';
import { OutputFieldProps } from '../OutputField';

const MAX_SIZE = 150;

export function ImageOutputField(props: OutputFieldProps) {
	const [data, setData] = useState<{
		preload: HTMLImageElement;
		width: number;
		height: number;
	} | null>(null);

	useEffect(() => {
		const base64 = props.field.value as string;
		const preload = new Image();
		preload.onload = () => {
			const scale = Math.min(MAX_SIZE / preload.width, MAX_SIZE / preload.height);
			const width = preload.width * scale;
			const height = preload.height * scale;
			setData({
				preload,
				width,
				height
			});
		};
		preload.src = 'data:image/png;base64,' + base64;
	}, [props.field]);

	return data === null ? <Fragment /> : <img src={data.preload.src} width={data.width} height={data.height} alt="Output" />;
}
