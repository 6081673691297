import { FlowJSON } from 'nocode-api-builder-model';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { LoadingDefaultLayout } from '../../components/layout/LoadingDefaultLayout';
import { DefaultLayout } from '../../components/layout/DefaultLayout';
import { TestFlowPage } from './TestFlowPage';
import { useFlowApiClient } from '../../api/FlowApiClient';
import { ErrorDefaultLayout } from '../../components/layout/ErrorDefaultLayout';
import { HttpClient } from '../../api/HttpClient';

export function TestFlowRoute() {
	const apiClient = useFlowApiClient();
	const id = useParams().id as string;
	const [flow, setFlow] = useState<FlowJSON | null>(null);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		async function load() {
			try {
				const response = await apiClient.getFlow(id);
				setFlow(response.flow);
			} catch (e) {
				setError(HttpClient.readError(e));
				console.error(e);
			}
		}
		load();
	}, [id, apiClient]);

	if (error) {
		return <ErrorDefaultLayout error={error} />;
	}
	if (!flow) {
		return <LoadingDefaultLayout />;
	}
	return (
		<DefaultLayout>
			<TestFlowPage id={id} flow={flow} />
		</DefaultLayout>
	);
}
