"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hashStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const StepNameFormatter_1 = require("../../utilities/formatters/StepNameFormatter");
exports.hashStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('hash', 'task', step => {
    step.label('Hash');
    step.category('Primitives');
    step.description('Hashes the input value by using the SHA-256 algorithm and stores the result in the output variable.');
    step.name()
        .dependentProperty('input')
        .dependentProperty('output')
        .value((0, sequential_workflow_editor_model_1.createGeneratedStringValueModel)({
        generator(context) {
            const input = context.formatPropertyValue('input', StepNameFormatter_1.StepNameFormatter.formatDynamic);
            const output = context.formatPropertyValue('output', StepNameFormatter_1.StepNameFormatter.formatVariable);
            return `${output} = Hash(${input})`;
        }
    }));
    step.property('input')
        .value((0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, sequential_workflow_editor_model_1.createNullableAnyVariableValueModel)({
                valueTypes: ['text', 'json', 'textCollection'],
                isRequired: true
            }),
            (0, sequential_workflow_editor_model_1.createStringValueModel)({})
        ]
    }))
        .label('Input');
    step.property('output')
        .dependentProperty('input')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        valueType: 'text',
        isRequired: true
    }))
        .label('Output');
});
