"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkflowUidGenerator = void 0;
const uuid_1 = require("uuid");
class WorkflowUidGenerator {
}
exports.WorkflowUidGenerator = WorkflowUidGenerator;
WorkflowUidGenerator.next = () => {
    return (0, uuid_1.v4)();
};
