import { OverviewTabForm } from '../../theme/flow/OverviewTabForm';
import { OverviewTabState, validateOverviewTabState } from './OverviewTabState';

export interface OverviewTabProps {
	state: OverviewTabState;
	userId: string;
	onStateChanged: (state: OverviewTabState) => void;
}

export function OverviewTab(props: OverviewTabProps) {
	const deployedUrlPattern = `/api/deployed-flows/${props.userId}/:url`;

	function setState(delta: Partial<OverviewTabState>) {
		const update = { ...props.state, ...delta };
		props.onStateChanged({
			...update,
			isDirty: true,
			errors: validateOverviewTabState(update)
		});
	}

	function onNameChanged(name: string) {
		setState({ name });
	}

	function onUrlChanged(url: string) {
		setState({ url });
	}

	function onMaxExecutionTimeChanged(maxExecutionTime: number) {
		setState({ maxExecutionTime });
	}

	function onAccessCodeChanged(accessCode: string | null) {
		setState({ accessCode });
	}

	function onDescriptionChanged(description: string) {
		setState({ description });
	}

	function onCommentChanged(comment: string) {
		setState({ comment });
	}

	function onIsDeployedChanged(isDeployed: boolean) {
		setState({ isDeployed });
	}

	return (
		<OverviewTabForm
			name={props.state.name}
			nameError={props.state.errors?.nameError}
			onNameChanged={onNameChanged}
			url={props.state.url}
			deployedUrlPattern={deployedUrlPattern}
			urlError={props.state.errors?.urlError}
			onUrlChanged={onUrlChanged}
			maxExecutionTime={props.state.maxExecutionTime}
			onMaxExecutionTimeChanged={onMaxExecutionTimeChanged}
			accessCode={props.state.accessCode}
			onAccessCodeChanged={onAccessCodeChanged}
			description={props.state.description}
			onDescriptionChanged={onDescriptionChanged}
			comment={props.state.comment}
			onCommentChanged={onCommentChanged}
			isDeployed={props.state.isDeployed}
			onIsDeployedChanged={onIsDeployedChanged}
		/>
	);
}
