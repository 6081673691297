"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.interruptStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const StepNameFormatter_1 = require("../../utilities/formatters/StepNameFormatter");
exports.interruptStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('interrupt', 'interruptingTask', step => {
    step.label('Interrupt');
    step.category('Flow');
    step.description('Interrupts the execution of the workflow and return the error code and reason.');
    step.name()
        .dependentProperty('code')
        .dependentProperty('reason')
        .value((0, sequential_workflow_editor_model_1.createGeneratedStringValueModel)({
        generator(context) {
            const code = context.formatPropertyValue('code', StepNameFormatter_1.StepNameFormatter.formatDynamic);
            const reason = context.formatPropertyValue('reason', StepNameFormatter_1.StepNameFormatter.formatDynamic);
            return `Interrupt(${code}, ${reason})`;
        }
    }));
    step.property('code')
        .value((0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, sequential_workflow_editor_model_1.createNumberValueModel)({
                min: 1
            }),
            (0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
                valueType: 'number'
            })
        ]
    }))
        .hint('Error code must be greater than or equal to 1.');
    step.property('reason').value((0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, sequential_workflow_editor_model_1.createStringValueModel)({}),
            (0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
                valueType: 'text'
            })
        ]
    }));
});
