"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.textRequestStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const StepNameFormatter_1 = require("../../utilities/formatters/StepNameFormatter");
const RichTextValidator_1 = require("../../utilities/richText/RichTextValidator");
const evaluableStringValueModel_1 = require("../../model/evaluableStringValueModel");
exports.textRequestStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('textRequest', 'task', step => {
    step.label('TextRequest');
    step.category('Services');
    step.name()
        .dependentProperty('method')
        .dependentProperty('response')
        .dependentProperty('url')
        .value((0, sequential_workflow_editor_model_1.createGeneratedStringValueModel)({
        generator(context) {
            const method = context.getPropertyValue('method');
            const url = context.formatPropertyValue('url', StepNameFormatter_1.StepNameFormatter.formatDynamicUrl);
            const response = context.formatPropertyValue('response', StepNameFormatter_1.StepNameFormatter.formatVariable);
            return `${response} = ${method} ${url}`;
        }
    }));
    step.property('method').value((0, sequential_workflow_editor_model_1.createChoiceValueModel)({
        choices: ['GET', 'POST', 'PUT', 'DELETE'],
        defaultValue: 'GET'
    }));
    step.property('url')
        .value((0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, evaluableStringValueModel_1.createEvaluableStringValueModel)({
                minLength: 1
            }),
            (0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
                valueType: 'text',
                isRequired: true
            })
        ]
    }))
        .validator(RichTextValidator_1.RichTextValidator.dynamicTextValidator);
    step.property('response')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        valueType: 'text',
        isRequired: true
    }))
        .label('Response variable');
    step.property('headers').value((0, sequential_workflow_editor_model_1.createStringDictionaryValueModel)({
        uniqueKeys: true,
        valueMinLength: 1
    }));
    step.property('timeout')
        .value((0, sequential_workflow_editor_model_1.createNumberValueModel)({
        defaultValue: 15000,
        max: 60000,
        min: 10
    }))
        .hint('The timeout in milliseconds.');
    step.property('failureMode')
        .value((0, sequential_workflow_editor_model_1.createChoiceValueModel)({
        choices: ['error', 'continue']
    }))
        .label('Failure mode');
    step.property('errorCode')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        valueType: sequential_workflow_editor_model_1.WellKnownValueType.number
    }))
        .label('Error code variable')
        .hint('If the fail mode is set to "continue", the error code will be written to this variable. Error codes:\n0 - no error\n1+ - HTTP error code\n-1 - timeout');
});
