import { WorkflowLogs } from 'nocode-api-builder-model';
import { Logs, StepNameMap } from '../logs/Logs';
import { useEffect, useRef } from 'react';
import { FlowInputFormState } from '../flowInputForm/FlowInputFormState';
import { FlowInputForm } from '../flowInputForm/FlowInputForm';
import { FlowOutputState } from '../flowOutput/FlowOutputState';
import { FlowOutput } from '../flowOutput/FlowOutput';
import { LogItemBox, LogItemBoxTheme } from '../primitives/LogItemBox';

export interface TestTabFormProps {
	description: string;
	logs: WorkflowLogs;
	stepNameMap: StepNameMap;
	formState: FlowInputFormState;
	outputState: FlowOutputState | null;
	onFormStateChanged: (formState: FlowInputFormState) => void;
}

export function TestTabForm(props: TestTabFormProps) {
	const logsBox = useRef<HTMLElement | null>(null);
	const hasResult = props.logs.length > 0 || Boolean(props.outputState);

	useEffect(() => {
		if (logsBox.current) {
			logsBox.current.scrollTop = logsBox.current.scrollHeight;
		}
	}, [props.logs, props.outputState]);

	return (
		<div className="md:flex md:h-full md:flex-row">
			<div className="bg-gray-100 md:w-[300px] md:order-2">
				<h4 className="text-xl font-bold p-2.5 pt-4">Inputs</h4>

				<div className="px-2.5">
					<FlowInputForm state={props.formState} onStateUpdated={props.onFormStateChanged} />
				</div>

				{props.formState.fields.length === 0 && <div className="text-center text-sm text-gray-500 px-2 py-5 ">No inputs</div>}
			</div>
			<div className="md:flex-1 md:order-1 md:relative">
				<div className="md:absolute md:top-0 md:bottom-0 md:left-0 md:right-0 md:overflow-auto" ref={e => (logsBox.current = e)}>
					{hasResult && (
						<div className="p-4">
							<Logs logs={props.logs} stepNameMap={props.stepNameMap} />
							{props.outputState && (
								<LogItemBox theme={LogItemBoxTheme.default}>
									<h5 className="text-lg font-bold">Output</h5>
									<FlowOutput state={props.outputState} />
								</LogItemBox>
							)}
						</div>
					)}
					{!hasResult && (
						<div className="p-4">
							<div className="border p-2 rounded-md mb-2">{props.description}</div>

							<div className="text-gray-500">
								To run test you need to press {'"'}Run{'"'} button.
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
