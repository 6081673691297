import { DotGridProExtension, StepsProExtension } from '@nocode-js/sequential-workflow-designer-pro';
import { KeyboardAction, StepsDesignerExtension } from 'sequential-workflow-designer';

const stepView = {
	paddingLeft: 10,
	paddingRight: 10 + 10,
	paddingY: 10,
	textMarginLeft: 10,
	minTextWidth: 60,
	iconSize: 22,
	radius: 4,
	inputSize: 10,
	outputSize: 10
};

export const designerExtensions = [
	StepsDesignerExtension.create({
		task: {
			view: stepView
		}
	}),
	StepsProExtension.create({
		interruptingTask: {
			view: stepView
		}
	}),
	DotGridProExtension.create({
		gridSizeX: 22,
		gridSizeY: 22,
		dotR: 1
	})
];

export function canHandleKey(_: KeyboardAction, event: KeyboardEvent): boolean {
	if ((event.target as HTMLElement).className.includes('cm-content')) {
		// codemirror
		return false;
	}
	return true;
}
