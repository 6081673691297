import { FlowOutputState } from './FlowOutputState';
import { OutputField } from './OutputField';

export interface FlowOutputProps {
	state: FlowOutputState;
}

export function FlowOutput(props: FlowOutputProps) {
	return (
		<div>
			{props.state.fields.map((field, index) => (
				<div key={index}>
					<div className="flex w-full py-2 items-center">
						<h3 className="flex-1 font-bold inline">{field.name}</h3>
						<span className="text-gray-300 text-xs">{field.type}</span>
					</div>

					<OutputField field={field} />
				</div>
			))}
		</div>
	);
}
