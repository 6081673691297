import { Button } from '../primitives/Button';
import { FullscreenForm } from '../layout/FullscreenForm';

export interface FinishSignUpProps {
	singInHref: string;
}

export function FinishSignUp(props: FinishSignUpProps) {
	return (
		<FullscreenForm title="Account Created" description="We've just created your account. Now you may sign in." error={null}>
			<Button href={props.singInHref}>Sign in</Button>
		</FullscreenForm>
	);
}
