"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.imageMarkdownStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const StepNameFormatter_1 = require("../../utilities/formatters/StepNameFormatter");
exports.imageMarkdownStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('imageMarkdown', 'task', step => {
    step.label('ImageMarkdown');
    step.category('Markdown');
    step.name()
        .dependentProperty('target')
        .dependentProperty('image')
        .dependentProperty('alt')
        .value((0, sequential_workflow_editor_model_1.createGeneratedStringValueModel)({
        generator(context) {
            const target = context.formatPropertyValue('target', StepNameFormatter_1.StepNameFormatter.formatVariable);
            const image = context.formatPropertyValue('image', StepNameFormatter_1.StepNameFormatter.formatVariable);
            return `${target} += !(${image})`;
        }
    }));
    step.property('target').value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        valueType: 'markdown',
        isRequired: true
    }));
    step.property('image').value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        valueType: 'image',
        isRequired: true
    }));
    step.property('alt').value((0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, sequential_workflow_editor_model_1.createStringValueModel)({}),
            (0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
                valueType: 'text',
                isRequired: true
            })
        ]
    }));
});
