import { DefaultLayout } from '../../components/layout/DefaultLayout';
import { CreateFlowPage } from './CreateFlowPage';

export function CreateFlowRoute() {
	return (
		<DefaultLayout>
			<CreateFlowPage />
		</DefaultLayout>
	);
}
