"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FlowValidator = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const sequential_workflow_model_1 = require("sequential-workflow-model");
const workflows_1 = require("../../workflows");
class FlowValidator {
    static validName(name) {
        if (!name) {
            throw new Error('Name is empty');
        }
        if (typeof name !== 'string') {
            throw new Error('Name is not a string');
        }
    }
    static validUrl(url) {
        if (!url) {
            throw new Error('Url is empty');
        }
        if (typeof url !== 'string') {
            throw new Error('Url is not a string');
        }
        if (url.length < 3) {
            throw new Error('Url is too short, expected at least 3 characters');
        }
        if (!/^[a-z0-9-_]*$/.test(url)) {
            throw new Error('Url contains invalid characters');
        }
    }
    static validIsDeployed(isDeployed) {
        if (typeof isDeployed !== 'boolean') {
            throw new Error('Is deployed is not a boolean');
        }
    }
    static validEnabledTriggers(triggers) {
        if (typeof triggers !== 'number' || isNaN(triggers) || triggers < 0) {
            throw new Error('Trigger count is invalid');
        }
    }
    static validMaxExecutionTime(maxExecutionTime) {
        if (![3, 5, 10, 20, 30, 60, 120, 180].includes(maxExecutionTime)) {
            throw new Error('Max execution time is not supported');
        }
    }
    static validAccessCode(accessCode) {
        if (accessCode === null) {
            return;
        }
        if (typeof accessCode !== 'string' || accessCode.length === 0) {
            throw new Error('Access code is invalid');
        }
    }
    static validateDescription(description) {
        if (typeof description !== 'string') {
            throw new Error('Description is not a string');
        }
    }
    static validateComment(comment) {
        if (typeof comment !== 'string') {
            throw new Error('Comment is not a string');
        }
    }
    static validDefinition(definition) {
        if (!definition) {
            throw new Error('Definition is empty');
        }
        const walker = new sequential_workflow_model_1.DefinitionWalker();
        const validator = sequential_workflow_editor_model_1.DefinitionValidator.create(workflows_1.flowDefinitionModel, walker);
        const error = validator.validate(definition);
        if (error) {
            const place = error.stepId ? `Step ${error.stepId}` : 'Root';
            const message = Object.values(error.error).join(', ');
            throw new Error(`Definition is invalid. ${place}: ${message}`);
        }
    }
}
exports.FlowValidator = FlowValidator;
