"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rangeLoopStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const StepNameFormatter_1 = require("../../utilities/formatters/StepNameFormatter");
exports.rangeLoopStepModel = (0, sequential_workflow_editor_model_1.createSequentialStepModel)('rangeLoop', 'container', step => {
    step.label('RangeLoop');
    step.category('Flow');
    step.name()
        .dependentProperty('from')
        .dependentProperty('to')
        .dependentProperty('delta')
        .value((0, sequential_workflow_editor_model_1.createGeneratedStringValueModel)({
        generator(context) {
            const from = context.formatPropertyValue('from', StepNameFormatter_1.StepNameFormatter.formatDynamic);
            const to = context.formatPropertyValue('to', StepNameFormatter_1.StepNameFormatter.formatDynamic);
            const delta = context.formatPropertyValue('delta', StepNameFormatter_1.StepNameFormatter.formatDynamic);
            return `${from} < ${to}, Δ${delta}`;
        }
    }));
    function val(defaultValue) {
        return (0, sequential_workflow_editor_model_1.createDynamicValueModel)({
            models: [
                (0, sequential_workflow_editor_model_1.createNumberValueModel)({
                    defaultValue
                }),
                (0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
                    isRequired: true,
                    valueType: 'number'
                })
            ]
        });
    }
    step.property('from').value(val(0));
    step.property('to').value(val(3));
    step.property('delta').value(val(1));
    step.property('indexVariable').value((0, sequential_workflow_editor_model_1.createNullableVariableDefinitionValueModel)({
        valueType: 'number',
        defaultValue: {
            name: 'index',
            type: 'number'
        },
        isRequired: true
    }));
});
