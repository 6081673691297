"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.writeKeyValueStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const utilities_1 = require("../../utilities");
exports.writeKeyValueStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('writeKeyValue', 'task', step => {
    step.label('WriteKeyValue');
    step.category('DB');
    step.name()
        .dependentProperty('value')
        .dependentProperty('key')
        .value((0, sequential_workflow_editor_model_1.createGeneratedStringValueModel)({
        generator(context) {
            const value = context.formatPropertyValue('value', utilities_1.StepNameFormatter.formatDynamic);
            const key = context.formatPropertyValue('key', utilities_1.StepNameFormatter.formatDynamic);
            return `WriteKeyValue(${key}, ${value})`;
        }
    }));
    step.property('key').value((0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, sequential_workflow_editor_model_1.createStringValueModel)({
                minLength: 1
            }),
            (0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
                valueType: 'text',
                isRequired: true
            })
        ]
    }));
    step.property('value')
        .value((0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, sequential_workflow_editor_model_1.createStringValueModel)({
                minLength: 1
            }),
            (0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
                valueType: 'text',
                isRequired: true
            })
        ]
    }))
        .hint('Expected type: text');
    step.property('isValueChanged')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        valueType: sequential_workflow_editor_model_1.WellKnownValueType.number,
        isRequired: false
    }))
        .label('Is value changed variable')
        .hint('Expected type: number. If the value has changed, the variable will be set to 1, otherwise it will be set to 0.');
    step.property('scope').value((0, sequential_workflow_editor_model_1.createChoiceValueModel)({
        choices: ['process', 'global']
    }));
});
