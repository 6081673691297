"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.collectionLengthStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const StepNameFormatter_1 = require("../../utilities/formatters/StepNameFormatter");
const utilities_1 = require("../../utilities");
const evaluableStringValueModel_1 = require("../../model/evaluableStringValueModel");
exports.collectionLengthStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('collectionLength', 'task', step => {
    step.label('CollectionLength');
    step.category('Primitives');
    step.name()
        .dependentProperty('input')
        .dependentProperty('path')
        .dependentProperty('output')
        .value((0, sequential_workflow_editor_model_1.createGeneratedStringValueModel)({
        generator(context) {
            const input = context.formatPropertyValue('input', StepNameFormatter_1.StepNameFormatter.formatVariable);
            const path = context.formatPropertyValue('path', StepNameFormatter_1.StepNameFormatter.createStringFormatter(8));
            const output = context.formatPropertyValue('output', StepNameFormatter_1.StepNameFormatter.formatVariable);
            const arg = path ? `${input}[${path}]` : input;
            return `${output} = CollectionLength(${arg})`;
        }
    }));
    step.property('input')
        .value((0, sequential_workflow_editor_model_1.createNullableAnyVariableValueModel)({
        valueTypes: ['json', 'textCollection'],
        isRequired: true
    }))
        .hint('Expected type: json, textCollection');
    step.property('path')
        .dependentProperty('input')
        .value((0, evaluableStringValueModel_1.createEvaluableStringValueModel)({}))
        .hint('Path to the JSON node. It may contain variables. For example: alfa/{$index}/beta')
        .validator({
        validate(context) {
            var _a;
            const value = context.getValue();
            if (value) {
                const inputVariableType = (_a = context.getPropertyValue('input')) === null || _a === void 0 ? void 0 : _a.type;
                if (inputVariableType !== 'json') {
                    return 'If you define a path, the input variable must be json';
                }
            }
            return utilities_1.RichTextValidator.validateString(value, context);
        }
    });
    step.property('output')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        valueType: 'number',
        isRequired: true
    }))
        .hint('Expected type: number');
});
