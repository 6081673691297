import { WorkflowUidGenerator } from 'nocode-api-builder-model';

export class Randomizer {
	public static randomUrl(): string {
		return WorkflowUidGenerator.next().substring(0, 8);
	}

	public static randomName(): string {
		return WorkflowUidGenerator.next().substring(0, 6);
	}
}
