"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TriggerParameters = void 0;
class TriggerParameters {
}
exports.TriggerParameters = TriggerParameters;
TriggerParameters.cron = {
    'm:1': 'Every minute',
    'm:2': 'Every 2 minutes',
    'm:5': 'Every 5 minutes',
    'm:10': 'Every 10 minutes',
    'm:30': 'Every 30 minutes',
    'h:1': 'Every hour',
    'h:2': 'Every 2 hours',
    'h:4': 'Every 4 hours',
    'h:8': 'Every 8 hours',
    'h:12': 'Every 12 hours',
    'h:24': 'Every 24 hours',
    'd:0': 'Every day at 0:00 UTC',
    'd:2': 'Every day at 2:00 UTC',
    'd:4': 'Every day at 4:00 UTC',
    'd:6': 'Every day at 6:00 UTC',
    'd:8': 'Every day at 8:00 UTC',
    'd:10': 'Every day at 10:00 UTC',
    'd:12': 'Every day at 12:00 UTC',
    'd:14': 'Every day at 14:00 UTC',
    'd:16': 'Every day at 16:00 UTC',
    'd:18': 'Every day at 18:00 UTC',
    'd:20': 'Every day at 20:00 UTC',
    'd:22': 'Every day at 22:00 UTC'
};
