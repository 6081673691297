"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserValidator = void 0;
class UserValidator {
    static validEmail(email) {
        if (!email) {
            throw new Error('Email is empty');
        }
        if (!email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
            throw new Error('Email is invalid');
        }
    }
    static validPassword(password) {
        if (!password) {
            throw new Error('Password is empty');
        }
        if (password.length < 6) {
            throw new Error('Password is too short, expected at least 6 characters');
        }
    }
}
exports.UserValidator = UserValidator;
