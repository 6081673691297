"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.codepenStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
exports.codepenStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('codepen', 'task', step => {
    step.label('Codepen');
    step.category('Services');
    step.property('title')
        .value((0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, sequential_workflow_editor_model_1.createStringValueModel)({
                minLength: 1,
                defaultValue: 'My Codepen'
            }),
            (0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
                valueType: 'text',
                isRequired: false
            })
        ]
    }))
        .label('Title');
    const value = (0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
                valueType: 'text',
                isRequired: false
            }),
            (0, sequential_workflow_editor_model_1.createStringValueModel)({
                multiline: 4
            })
        ]
    });
    step.property('html').value(value).label('HTML');
    step.property('css').value(value).label('CSS');
    step.property('js').value(value).label('JavaScript');
    step.property('output')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        valueType: 'json'
    }))
        .hint('Expected type: json');
    step.property('outputHtml')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        valueType: 'text'
    }))
        .hint('Expected type: text');
});
