import { MutableRefObject, createContext, useContext, useRef } from 'react';

export const mainContext = createContext<MutableRefObject<HTMLElement | null> | null>(null);

export function MainContext(props: { children: React.ReactNode }) {
	const ref = useRef<HTMLElement | null>(null);
	return <mainContext.Provider value={ref}>{props.children}</mainContext.Provider>;
}

function useMainContext() {
	const ref = useContext(mainContext);
	if (!ref) {
		throw new Error('Cannot find MainContext');
	}
	return ref;
}

export function useScrollTop() {
	const ref = useMainContext();
	return () => {
		if (ref.current) {
			ref.current.scrollTop = 0;
		}
	};
}

export function Main(props: { children: React.ReactNode }) {
	return (
		<MainContext>
			<MainInternal children={props.children} />
		</MainContext>
	);
}

function MainInternal(props: { children: React.ReactNode }) {
	const ref = useMainContext();
	return (
		<main className="flex-1 overflow-x-hidden overflow-y-auto" ref={el => (ref.current = el)}>
			{props.children}
		</main>
	);
}
