import { FlowJSON } from 'nocode-api-builder-model';
import { FlowTester } from '../../components/flow/tester/FlowTester';
import { useNavigate } from 'react-router';

export interface TestFlowPageProps {
	id: string;
	flow: FlowJSON;
}

export function TestFlowPage(props: TestFlowPageProps) {
	const navigate = useNavigate();

	function onModeChanged(modeId: string) {
		if (modeId === 'edit') {
			navigate(`/flows/${props.id}/edit`);
		}
		if (modeId === 'traces') {
			navigate(`/traces?processId=${props.id}`);
		}
	}

	return <FlowTester id={props.id} flow={props.flow} onModeChanged={onModeChanged} />;
}
