"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findStringStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const StepNameFormatter_1 = require("../../utilities/formatters/StepNameFormatter");
exports.findStringStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('findText', 'task', step => {
    step.label('FindText');
    step.category('Primitives');
    step.description('Find a text in another text and return the position. If not found, return -1.');
    step.name()
        .dependentProperty('input')
        .dependentProperty('result')
        .dependentProperty('search')
        .value((0, sequential_workflow_editor_model_1.createGeneratedStringValueModel)({
        generator(context) {
            const input = context.formatPropertyValue('input', StepNameFormatter_1.StepNameFormatter.formatVariable);
            const result = context.formatPropertyValue('result', StepNameFormatter_1.StepNameFormatter.formatVariable);
            const search = context.formatPropertyValue('search', StepNameFormatter_1.StepNameFormatter.formatDynamic);
            return `${result} = FindString(${input}, ${search})`;
        }
    }));
    step.property('input').value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        valueType: 'text',
        isRequired: true
    }));
    step.property('search').value((0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, sequential_workflow_editor_model_1.createStringValueModel)({}),
            (0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
                valueType: 'text',
                isRequired: true
            })
        ]
    }));
    step.property('offset').value((0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, sequential_workflow_editor_model_1.createNumberValueModel)({ min: 0 }),
            (0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
                valueType: sequential_workflow_editor_model_1.WellKnownValueType.number,
                isRequired: true
            })
        ]
    }));
    step.property('ignoreCase').value((0, sequential_workflow_editor_model_1.createBooleanValueModel)({}));
    step.property('result').value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        valueType: sequential_workflow_editor_model_1.WellKnownValueType.number,
        isRequired: true
    }));
});
