"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.maxStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const StepNameFormatter_1 = require("../../utilities/formatters/StepNameFormatter");
exports.maxStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('max', 'task', step => {
    step.label('Max');
    step.category('Primitives');
    step.description('Calculates the maximum of two numbers.');
    step.name()
        .dependentProperty('a')
        .dependentProperty('b')
        .dependentProperty('result')
        .value((0, sequential_workflow_editor_model_1.createGeneratedStringValueModel)({
        generator(context) {
            const a = context.formatPropertyValue('a', StepNameFormatter_1.StepNameFormatter.formatDynamic);
            const b = context.formatPropertyValue('b', StepNameFormatter_1.StepNameFormatter.formatDynamic);
            const result = context.formatPropertyValue('result', StepNameFormatter_1.StepNameFormatter.formatVariable);
            return `${result} = Max(${a}, ${b})`;
        }
    }));
    const val = (0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, sequential_workflow_editor_model_1.createNumberValueModel)({}),
            (0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
                isRequired: true,
                valueType: sequential_workflow_editor_model_1.WellKnownValueType.number
            })
        ]
    });
    step.property('result').value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        valueType: sequential_workflow_editor_model_1.WellKnownValueType.number,
        isRequired: true
    }));
    step.property('a').value(val).label('A');
    step.property('b').value(val).label('B');
});
