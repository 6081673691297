import { DeployedFlowDto } from 'nocode-api-builder-model';
import { ExecuteDeployedFlow } from '../../components/theme/executeDeployedFlow/ExecuteDeployedFlow';
import { FlowOutputState } from '../../components/theme/flowOutput/FlowOutputState';
import { createFlowInputFormState, readFlowInputFormStateValues } from '../../components/theme/flowInputForm/FlowInputFormState';
import { useState } from 'react';
import { usePublicFlowApiClient } from '../../api/PublicFlowApiClient';
import { HttpClient } from '../../api/HttpClient';

export interface ExecuteDeployedFlowPageProps {
	userId: string;
	url: string;
	flow: DeployedFlowDto;
}

export function ExecuteDeployedFlowPage(props: ExecuteDeployedFlowPageProps) {
	const apiClient = usePublicFlowApiClient();
	const [isExecuting, setIsExecuting] = useState(false);
	const [accessCode, setAccessCode] = useState<string | null>(() => (props.flow.hasAccessCode ? '' : null));
	const [error, setError] = useState<string | null>(null);
	const [state, setState] = useState(() => createState(props.flow));
	const [outputState, setOutputState] = useState<FlowOutputState | null>(null);

	async function onSubmit() {
		if (isExecuting || state.hasError) {
			return;
		}
		if (error) {
			setError(null);
		}
		setIsExecuting(true);

		try {
			const values = await readFlowInputFormStateValues(state);
			const response = await apiClient.executeDeployedFlow(props.userId, props.url, accessCode, values);

			setOutputState({
				fields: props.flow.outputs.variables
					.map(v => ({
						name: v.name,
						type: v.type,
						value: response[v.name]
					}))
					.filter(v => v.value !== undefined)
			});
		} catch (e) {
			setError(HttpClient.readError(e));
			console.error(e);
		} finally {
			setIsExecuting(false);
		}
	}

	function onReset() {
		setOutputState(null);
		setState(createState(props.flow));
	}

	return (
		<ExecuteDeployedFlow
			title={props.flow.name}
			description={props.flow.description}
			isExecuting={isExecuting}
			accessCode={accessCode}
			onAccessCodeChanged={setAccessCode}
			error={error}
			formState={state}
			outputState={outputState}
			onFormSubmit={onSubmit}
			onReset={onReset}
			onFormStateUpdated={setState}
		/>
	);
}

function createState(flow: DeployedFlowDto) {
	return createFlowInputFormState(
		flow.inputs.variables.map(v => ({
			isRequired: Boolean(v.isRequired),
			name: v.name,
			type: v.type
		}))
	);
}
