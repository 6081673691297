import { TraceStatsDto } from 'nocode-api-builder-model';
import { useEffect, useMemo, useRef, useState } from 'react';
import { BarChart } from 'chartist';

const DAY = 86400000;

export interface ChartProps {
	fromTime: number;
	traces: TraceStatsDto[];
	field: keyof TraceStatsDto;
}

export function Chart(props: ChartProps) {
	const [placeholder, setPlaceholder] = useState<HTMLDivElement | null>(null);
	const chartRef = useRef<BarChart | null>(null);

	const data = useMemo(() => {
		const labels: string[] = [];
		const values: number[] = [];
		for (let day = props.fromTime; day <= Date.now(); day += DAY) {
			const dayTraces = props.traces.filter(trace => trace.time >= day && trace.time < day + DAY);
			labels.push(String(new Date(day).getUTCDate()));
			values.push(dayTraces.reduce((sum, trace) => sum + trace[props.field], 0));
		}
		return { labels, values };
	}, [props.fromTime, props.traces, props.field]);

	useEffect(() => {
		if (!placeholder) {
			return;
		}

		if (chartRef.current) {
			chartRef.current.detach();
		}
		chartRef.current = new BarChart(placeholder, {
			labels: data.labels,
			series: [data.values]
		});
	}, [placeholder, data]);

	return <div ref={setPlaceholder} className="h-72"></div>;
}
