import {
	FileInputField,
	FileFieldRawValue,
	createFileInputFieldDefaultRawValue,
	parseFileInputFieldRawValue
} from './types/FileInputField';
import { NumberInputField, createNumberInputFieldDefaultRawValue, parseNumberInputFieldRawValue } from './types/NumberInputField';
import { TextInputField, createTextInputFieldDefaultRawValue, parseTextInputFieldRawValue } from './types/TextInputField';

export interface InputFieldSpec {
	name: string;
	type: string;
	isRequired: boolean;
	initialValue?: unknown;
}

export interface InputFieldProps<T = unknown> {
	spec: InputFieldSpec;
	hasError: boolean;
	rawValue: T;
	onChange: (rawValue: T) => void;
}

export function InputField(props: InputFieldProps) {
	switch (props.spec.type) {
		case 'text':
			return (
				<TextInputField spec={props.spec} hasError={props.hasError} rawValue={props.rawValue as string} onChange={props.onChange} />
			);
		case 'number':
			return (
				<NumberInputField
					spec={props.spec}
					hasError={props.hasError}
					rawValue={props.rawValue as string}
					onChange={props.onChange}
				/>
			);
		case 'file':
			return (
				<FileInputField
					spec={props.spec}
					hasError={props.hasError}
					rawValue={props.rawValue as FileFieldRawValue}
					onChange={props.onChange}
				/>
			);
	}
	throw new Error(`Unknown field type: ${props.spec.type}`);
}

export function createFieldDefaultRawValue(spec: InputFieldSpec): unknown {
	switch (spec.type) {
		case 'text':
			return createTextInputFieldDefaultRawValue();
		case 'number':
			return createNumberInputFieldDefaultRawValue();
		case 'file':
			return createFileInputFieldDefaultRawValue();
	}
	throw new Error(`Unknown field type: ${spec.type}`);
}

export function parseInputFieldRawValue(rawValue: unknown, spec: InputFieldSpec): unknown {
	switch (spec.type) {
		case 'text':
			return parseTextInputFieldRawValue(rawValue as string, spec);
		case 'number':
			return parseNumberInputFieldRawValue(rawValue as string, spec);
		case 'file':
			return parseFileInputFieldRawValue(rawValue as FileFieldRawValue, spec);
	}
	throw new Error(`Unknown field type: ${spec.type}`);
}
