import { TriggerNextTimeCalculator, TriggerParameters, TriggerType } from 'nocode-api-builder-model';
import { Button } from '../primitives/Button';
import { Select } from '../primitives/Select';
import { FlowInputFormState } from '../flowInputForm/FlowInputFormState';
import { FlowInputForm } from '../flowInputForm/FlowInputForm';
import { Input } from '../primitives/Input';

export interface TriggersTabFormProps {
	cronTriggers: CronTrigger[];
	onNewCronTriggerClicked: () => void;
	onCronTriggersChanged: (triggers: CronTrigger[]) => void;
	onCronTriggerTraceClicked: (trigger: CronTrigger) => void;
	onCronTriggerTestClicked?: (trigger: CronTrigger) => void;
}

export interface CronTrigger {
	id: string | null;
	index: number;
	name: string;
	isEnabled: boolean;
	parameters: string;
	lastTime: number | null;
	nextTime: number | null;
	fires: number;
	inputFormState: FlowInputFormState;
}

export function TriggersTabForm(props: TriggersTabFormProps) {
	function onAddClicked() {
		props.onNewCronTriggerClicked();
	}

	function onDeleteClicked(trigger: CronTrigger) {
		const confirmDelete = window.confirm('Are you sure?');
		if (confirmDelete) {
			const newTriggers = props.cronTriggers.filter(t => t.index !== trigger.index);
			props.onCronTriggersChanged(newTriggers);
		}
	}

	function onTracesClicked(trigger: CronTrigger) {
		props.onCronTriggerTraceClicked(trigger);
	}

	function onInputFormStateUpdated(trigger: CronTrigger, state: FlowInputFormState) {
		trigger.inputFormState = state;
		props.onCronTriggersChanged([...props.cronTriggers]);
	}

	function onIsEnabledChanged(trigger: CronTrigger, isEnabled: boolean) {
		trigger.isEnabled = isEnabled;
		props.onCronTriggersChanged([...props.cronTriggers]);
	}

	function onNameChanged(trigger: CronTrigger, name: string) {
		trigger.name = name;
		props.onCronTriggersChanged([...props.cronTriggers]);
	}

	function onParametersChanged(trigger: CronTrigger, parameters: string) {
		trigger.parameters = parameters;
		props.onCronTriggersChanged([...props.cronTriggers]);
	}

	function onCronTriggerTestClicked(trigger: CronTrigger) {
		if (props.onCronTriggerTestClicked) {
			props.onCronTriggerTestClicked(trigger);
		}
	}

	return (
		<>
			{props.cronTriggers.length === 0 && <div className="text-center pt-8 pb-5 text-sm">No triggers</div>}
			{props.cronTriggers.map((trigger, index) => (
				<div key={index} className="border border-gray-300/80 rounded-md overflow-hidden m-4">
					<div className="md:flex w-full bg-gray-100 items-center">
						<div className="p-2">
							<span className="inline-block px-2 py-1 bg-white rounded-sm text-gray-900 text-sm">
								<label className="cursor-pointer">
									<input
										type="checkbox"
										checked={trigger.isEnabled}
										onChange={e => onIsEnabledChanged(trigger, e.target.checked)}
										className="mr-1"
									/>{' '}
									Enabled
								</label>
							</span>
						</div>
						<div className="md:flex-1 text-gray-400 text-xs p-2">
							<span className="mr-2">#{trigger.index}</span>

							<span className="inline-block mr-2">
								Last execution:{' '}
								<span className="text-gray-900">
									{trigger.lastTime ? new Date(trigger.lastTime).toLocaleString() : '-'}
								</span>
							</span>
							<span className="inline-block mr-2">
								Next execution:{' '}
								<span className="text-gray-900">
									{formatDate(
										trigger.nextTime
											? trigger.nextTime
											: TriggerNextTimeCalculator.calculate(TriggerType.cron, trigger.parameters, Date.now())
									)}
								</span>
							</span>
							<span className="inline-block">
								Fires: <span className="text-gray-900">{String(trigger.fires)}</span>
							</span>
						</div>
						<div className="p-2">
							{props.onCronTriggerTestClicked && (
								<Button size="sm" theme="secondary" className="mr-2" onClicked={() => onCronTriggerTestClicked(trigger)}>
									Test
								</Button>
							)}
							{trigger.id && (
								<Button size="sm" theme="secondary" className="mr-2" onClicked={() => onTracesClicked(trigger)}>
									Traces
								</Button>
							)}
							<Button size="sm" theme="danger" onClicked={() => onDeleteClicked(trigger)}>
								Delete
							</Button>
						</div>
					</div>

					<div className="md:flex text-sm">
						<div className="p-2 pb-1 md:w-[200px]">
							<h4 className="block mb-1.5 text-sm">Name</h4>
							<Input value={trigger.name} onChanged={v => onNameChanged(trigger, v)} className="mb-2" />
						</div>
						<div className="p-2 pb-1 md:w-[200px]">
							<h4 className="block mb-1.5 text-sm">Parameters</h4>
							<Select
								options={TriggerParameters.cron}
								value={trigger.parameters}
								onChanged={v => onParametersChanged(trigger, v)}
								className="mb-2"
							></Select>
						</div>
						<div className="md:flex-1 p-2 pb-1">
							{trigger.inputFormState.fields.length === 0 && <div className="text-gray-400 text-center py-6">No inputs</div>}
							{trigger.inputFormState.fields.length > 0 && (
								<FlowInputForm state={trigger.inputFormState} onStateUpdated={s => onInputFormStateUpdated(trigger, s)} />
							)}
						</div>
					</div>
				</div>
			))}
			<div className="text-center py-3">
				<Button onClicked={onAddClicked} size="md">
					Add
				</Button>
			</div>
		</>
	);
}

function formatDate(date: number) {
	return new Date(date).toLocaleString();
}
