"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const StepNameFormatter_1 = require("../../utilities/formatters/StepNameFormatter");
const RichTextValidator_1 = require("../../utilities/richText/RichTextValidator");
const evaluableStringValueModel_1 = require("../../model/evaluableStringValueModel");
exports.getStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('get', 'task', step => {
    step.label('Get');
    step.category('Primitives');
    step.name()
        .dependentProperty('input')
        .dependentProperty('path')
        .dependentProperty('output')
        .value((0, sequential_workflow_editor_model_1.createGeneratedStringValueModel)({
        generator(context) {
            const input = context.formatPropertyValue('input', StepNameFormatter_1.StepNameFormatter.formatVariable);
            const path = context.formatPropertyValue('path', StepNameFormatter_1.StepNameFormatter.createStringFormatter(8));
            const output = context.formatPropertyValue('output', StepNameFormatter_1.StepNameFormatter.formatVariable);
            return `${output} = ${input}[${path}]`;
        }
    }));
    step.property('input')
        .value((0, sequential_workflow_editor_model_1.createNullableAnyVariableValueModel)({
        valueTypes: ['json', 'textCollection'],
        isRequired: true
    }))
        .label('Input');
    step.property('path')
        .dependentProperty('input')
        .value((0, evaluableStringValueModel_1.createEvaluableStringValueModel)({
        minLength: 1
    }))
        .hint('Path to json node or collection item. It may contain variables. For example: alfa/{$index}/beta')
        .validator(RichTextValidator_1.RichTextValidator.stringPathValidator);
    step.property('output')
        .dependentProperty('input')
        .value((0, sequential_workflow_editor_model_1.createNullableAnyVariableValueModel)({
        valueTypes: ['text', 'number', 'json'],
        isRequired: true
    }))
        .label('Output')
        .validator({
        validate(context) {
            var _a;
            const value = context.getValue();
            if ((value === null || value === void 0 ? void 0 : value.type) !== 'text') {
                const inputVariableType = (_a = context.getPropertyValue('input')) === null || _a === void 0 ? void 0 : _a.type;
                if (inputVariableType === 'textCollection') {
                    return 'If the input is text collection, the output must be text.';
                }
            }
            return null;
        }
    });
});
