import { Loading } from '../theme/loading/Loading';
import { DefaultLayout } from './DefaultLayout';

export function LoadingDefaultLayout() {
	return (
		<DefaultLayout>
			<Loading />
		</DefaultLayout>
	);
}
