"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.searchGoogleStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
exports.searchGoogleStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('searchGoogle', 'task', step => {
    step.label('SearchGoogle');
    step.category('Services');
    step.property('query')
        .value((0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, sequential_workflow_editor_model_1.createStringValueModel)({}),
            (0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
                valueType: 'text',
                isRequired: true
            })
        ]
    }))
        .label('Query');
    step.property('outputUrls')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        valueType: 'textCollection'
    }))
        .label('Output URLs')
        .hint('Expected type: textCollection');
    step.property('output')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        valueType: 'output'
    }))
        .label('Output')
        .hint('Expected type: json')
        .dependentProperty('outputUrls')
        .validator({
        validate(context) {
            const output = context.getValue();
            const outputUrls = context.getPropertyValue('outputUrls');
            if (!output && !outputUrls) {
                return 'This property is required if you do not specify the Output URLs property.';
            }
            return null;
        }
    });
});
