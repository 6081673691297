import {
	ConfirmSignUpRequest,
	ConfirmSignUpResponse,
	RefreshTokenRequest,
	RefreshTokenResponse,
	SignInRequest,
	SignInResponse,
	SignUpRequest,
	SignUpResponse
} from 'nocode-api-builder-model';
import { useMemo } from 'react';
import { HttpClient } from './HttpClient';
import { useHttpClient } from './HttpClientContext';

export function usePublicAuthApiClient() {
	const httpClient = useHttpClient();
	return useMemo(() => new PublicAuthApiClient(httpClient), [httpClient]);
}

export class PublicAuthApiClient {
	public constructor(private readonly httpClient: HttpClient) {}

	public async signIn(request: SignInRequest): Promise<SignInResponse> {
		return this.httpClient.request({
			method: 'PUT',
			url: `/api/auth/signin`,
			body: request
		});
	}

	public async signUp(request: SignUpRequest): Promise<SignUpResponse> {
		return this.httpClient.request({
			method: 'POST',
			url: `/api/auth/signup`,
			body: request
		});
	}

	public async confirmSignUp(request: ConfirmSignUpRequest): Promise<ConfirmSignUpResponse> {
		return this.httpClient.request({
			method: 'POST',
			url: `/api/auth/signup/confirm`,
			body: request
		});
	}

	public async refreshToken(request: RefreshTokenRequest): Promise<RefreshTokenResponse> {
		return this.httpClient.request({
			method: 'PUT',
			url: `/api/auth/refresh-token`,
			body: request
		});
	}
}
