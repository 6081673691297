import { GetSubscriptionUsageResponse, GetSubscriptionPortalResponse } from 'nocode-api-builder-model';
import { useMemo } from 'react';
import { HttpClient } from './HttpClient';
import { AuthSession, useSession } from '../auth/AuthContext';
import { useHttpClient } from './HttpClientContext';

export function useSubscriptionApiClient() {
	const httpClient = useHttpClient();
	const session = useSession();
	return useMemo(() => new SubscriptionApiClient(httpClient, session), [httpClient, session]);
}

export class SubscriptionApiClient {
	public constructor(
		private readonly httpClient: HttpClient,
		private readonly session: AuthSession
	) {}

	public async getSubscriptionUsage(): Promise<GetSubscriptionUsageResponse> {
		return this.httpClient.request({
			method: 'GET',
			url: `/api/subscription/usage`,
			authToken: this.session.token
		});
	}

	public async getSubscriptionPortal(): Promise<GetSubscriptionPortalResponse> {
		return this.httpClient.request({
			method: 'GET',
			url: `/api/subscription/portal`,
			authToken: this.session.token
		});
	}
}
