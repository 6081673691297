export interface FullscreenFormProps {
	title: string;
	description?: string;
	error: string | null;
	children: React.ReactNode;
}

export function FullscreenForm(props: FullscreenFormProps) {
	return (
		<div className="w-full h-screen overflow-y-auto">
			<div className="table w-full min-h-screen bg-gray-800">
				<div className="table-row overflow-hidden">
					<div className="table-cell align-middle">
						<div className="bg-white rounded-lg shadow p-6 space-y-4 w-full sm:max-w-md md:space-y-6 sm:p-8 mx-auto my-4">
							<h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">{props.title}</h1>

							{props.description && <p className="text-sm font-light text-gray-500">{props.description}</p>}

							{props.error && <div className="text-red-600">{props.error}</div>}

							{props.children}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
