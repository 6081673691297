import { createContext, useContext } from 'react';
import { HttpClient } from './HttpClient';

const httpClientContext = createContext<HttpClient | null>(null);

export function useHttpClient(): HttpClient {
	const client = useContext(httpClientContext);
	if (!client) {
		throw new Error('Cannot find HttpClientContext');
	}
	return client;
}

export function HttpClientContext(props: { children: React.ReactNode }) {
	return <httpClientContext.Provider value={new HttpClient()}>{props.children}</httpClientContext.Provider>;
}
