import { GetSuggestionResponse, GetSuggestionRequest } from 'nocode-api-builder-model';
import { AuthSession, useSession } from '../auth/AuthContext';
import { HttpClient } from './HttpClient';
import { useMemo } from 'react';
import { useHttpClient } from './HttpClientContext';

export function useSuggestionApiClient(): SuggestionApiClient {
	const httpClient = useHttpClient();
	const session = useSession();
	return useMemo(() => new SuggestionApiClient(httpClient, session), [httpClient, session]);
}

export class SuggestionApiClient {
	public constructor(
		private readonly httpClient: HttpClient,
		private readonly session: AuthSession
	) {}

	public readonly getSuggestion = (request: GetSuggestionRequest): Promise<GetSuggestionResponse> => {
		return this.httpClient.request({
			method: 'POST',
			url: '/api/suggestions',
			authToken: this.session.token,
			body: request
		});
	};
}
