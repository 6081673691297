import { FlowJSON, TriggerJSON } from 'nocode-api-builder-model';
import { useFlowApiClient } from '../../api/FlowApiClient';
import { FlowEditor, FlowEditorData } from '../../components/flow/editor/FlowEditor';
import { useNavigate } from 'react-router';
import { useSession } from '../../auth/AuthContext';

export interface EditFlowPageProps {
	id: string;
	flow: FlowJSON;
	triggers: TriggerJSON[];
}

export function EditFlowPage(props: EditFlowPageProps) {
	const navigate = useNavigate();
	const apiClient = useFlowApiClient();
	const { userId } = useSession();

	async function onSave(data: FlowEditorData) {
		await apiClient.updateFlow(props.id, data);
		return false;
	}

	function onModeChanged(modeId: string, isDirty: boolean) {
		if (isDirty && !window.confirm('You have unsaved changes. Are you sure you want to continue?')) {
			return;
		}
		if (modeId === 'test') {
			navigate(`/flows/${props.id}/test`);
		}
		if (modeId === 'traces') {
			navigate(`/traces?processId=${props.id}`);
		}
	}

	return <FlowEditor userId={userId} flow={props.flow} triggers={props.triggers} onSave={onSave} onModeChanged={onModeChanged} />;
}
