import { FlowJSON, FlowValidator } from 'nocode-api-builder-model';
import { Randomizer } from '../../utilities/Randomizer';

export interface OverviewTabState {
	name: string;
	url: string;
	maxExecutionTime: number;
	accessCode: string | null;
	isDeployed: boolean;
	description: string;
	comment: string;
	isDirty: boolean;
	errors: OverviewTabErrors | undefined;
}

export interface OverviewTabErrors {
	nameError: string | undefined;
	urlError: string | undefined;
}

export function createOverviewTabState(flow: FlowJSON | undefined): OverviewTabState {
	const value = {
		name: flow?.name ?? '',
		url: flow?.url ?? Randomizer.randomUrl(),
		maxExecutionTime: flow?.maxExecutionTime ?? 60,
		accessCode: flow?.accessCode ?? null,
		isDeployed: flow?.isDeployed ?? false,
		description: flow?.description ?? '',
		comment: flow?.comment ?? ''
	};
	return {
		...value,
		isDirty: false,
		errors: validateOverviewTabState(value)
	};
}

export function validateOverviewTabState(state: Omit<OverviewTabState, 'isDirty' | 'errors'>): OverviewTabErrors | undefined {
	let nameError: string | undefined = undefined;
	try {
		FlowValidator.validName(state.name);
	} catch (e) {
		nameError = (e as Error).message;
	}

	let urlError: string | undefined = undefined;
	try {
		FlowValidator.validUrl(state.url);
	} catch (e) {
		urlError = (e as Error).message;
	}

	if (nameError || urlError) {
		return { nameError, urlError };
	}
	return undefined;
}
