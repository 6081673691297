import { useFlowApiClient } from '../../api/FlowApiClient';
import { useSession } from '../../auth/AuthContext';
import { FlowEditor, FlowEditorData } from '../../components/flow/editor/FlowEditor';
import { useNavigate } from 'react-router';

export function CreateFlowPage() {
	const navigate = useNavigate();
	const apiClient = useFlowApiClient();
	const { userId } = useSession();

	function onModeChanged(modeId: string) {
		if (modeId === 'test' || modeId === 'traces') {
			alert('Flow must be saved to perform this action.');
		}
	}

	async function onSave(data: FlowEditorData) {
		const id = await apiClient.createFlow(data);

		navigate(`/flows/${id}/edit`);
		return true;
	}

	return <FlowEditor userId={userId} onModeChanged={onModeChanged} onSave={onSave} />;
}
