import { FlowJSON } from 'nocode-api-builder-model';
import { useFlowApiClient } from '../../api/FlowApiClient';
import { FlowList } from '../../components/theme/flowList/FlowList';
import { useSession } from '../../auth/AuthContext';

export interface FlowListPageProps {
	flows: FlowJSON[];
	onReload: () => void;
}

export function FlowListPage(props: FlowListPageProps) {
	const apiClient = useFlowApiClient();
	const { userId } = useSession();

	const flows = props.flows.map(a => ({
		id: a.id,
		name: a.name,
		method: 'POST',
		enabledTriggers: a.enabledTriggers,
		editUrl: `/flows/${a.id}/edit#design`,
		editTriggersUrls: `/flows/${a.id}/edit#triggers`,
		testUrl: `/flows/${a.id}/test`,
		executeUrl: a.isDeployed ? `/deployed-flows/${userId}/${a.url}` : null,
		tracesUrl: `/traces?processId=${a.id}`
	}));

	async function onDeleteClicked(id: string) {
		if (!window.confirm('Do you really want to delete this flow?')) {
			return;
		}

		try {
			await apiClient.deleteFlow(id);
			props.onReload();
		} catch (e) {
			window.alert(`Error: ${(e as Error).message || e}`);
		}
	}

	async function onDuplicateClicked(id: string) {
		if (!window.confirm('Do you really want to duplicate this flow?')) {
			return;
		}

		try {
			await apiClient.duplicateFlow(id);
			props.onReload();
		} catch (e) {
			window.alert(`Error: ${(e as Error).message || e}`);
		}
	}

	return <FlowList flows={flows} createUrl="/flows/create" onDeleteClicked={onDeleteClicked} onDuplicateClicked={onDuplicateClicked} />;
}
