import { useNavigate } from 'react-router';
import { CronTrigger, TriggersTabForm } from '../../theme/flow/TriggersTabForm';
import { TriggersTabState, createEmptyCronTrigger, validateTriggersTabState } from './TriggersTabState';
import { FlowDefinition } from 'nocode-api-builder-model';
import { readFlowInputFormStateValues } from '../../theme/flowInputForm/FlowInputFormState';
import { FlowTesterStorage } from '../tester/FlowTesterStorage';

export interface TriggersTabProps {
	id?: string;
	definition: FlowDefinition;
	state: TriggersTabState;
	onStateChanged: (state: TriggersTabState) => void;
}

export function TriggersTab(props: TriggersTabProps) {
	const navigate = useNavigate();

	function onNewCronTriggerClicked() {
		const nextIndex = Math.max(0, ...props.state.cronTriggers.map(t => t.index)) + 1;
		const newTrigger = createEmptyCronTrigger(nextIndex, props.definition);
		const cronTriggers = [...props.state.cronTriggers, newTrigger];
		props.onStateChanged({ ...props.state, cronTriggers, isDirty: true });
	}

	function onDailyTriggersChanged(cronTriggers: CronTrigger[]) {
		const isValid = validateTriggersTabState({ cronTriggers });
		props.onStateChanged({ ...props.state, cronTriggers, isDirty: true, isValid });
	}

	function onTriggerTraceClicked(trigger: CronTrigger) {
		if (trigger.id) {
			navigate(`/traces/?triggerId=${trigger.id}`);
		}
	}

	async function onCronTriggerTestClicked(trigger: CronTrigger) {
		if (props.id) {
			const values = await readFlowInputFormStateValues(trigger.inputFormState);
			FlowTesterStorage.setInput(props.id, values);
			navigate(`/flows/${props.id}/test`);
		}
	}

	return (
		<TriggersTabForm
			cronTriggers={props.state.cronTriggers}
			onNewCronTriggerClicked={onNewCronTriggerClicked}
			onCronTriggersChanged={onDailyTriggersChanged}
			onCronTriggerTraceClicked={onTriggerTraceClicked}
			onCronTriggerTestClicked={props.id ? onCronTriggerTestClicked : undefined}
		/>
	);
}
