import { FlowDefinition } from 'nocode-api-builder-model';
import { DevTabForm } from '../../theme/flow/DevTabForm';
import { DesignTabState } from './DesignTabState';
import { OverviewTabState } from './OverviewTabState';
import { wrapDefinition } from 'sequential-workflow-designer-react';

export interface DevTabProps {
	overviewState: OverviewTabState;
	designState: DesignTabState;
	onDesignStateChanged: (state: DesignTabState) => void;
}

export function DevTab(props: DevTabProps) {
	async function onImportDefinitionClicked() {
		const file = await openFile('application/json');
		const data = await file.text();
		const definition: FlowDefinition = JSON.parse(data);
		props.onDesignStateChanged({
			definition: wrapDefinition(definition),
			isDirty: true
		});
	}

	function onExportDefinitionClicked() {
		const data = JSON.stringify(props.designState.definition.value, null, 2);
		const blob = new Blob([data], { type: 'application/json' });
		downloadFile(`${props.overviewState.name}.flow.json`, blob);
	}

	return <DevTabForm onImportDefinitionClicked={onImportDefinitionClicked} onExportDefinitionClicked={onExportDefinitionClicked} />;
}

function openFile(accept: string): Promise<File> {
	return new Promise(resolve => {
		const input = document.createElement('input');
		input.type = 'file';
		input.accept = accept;
		input.onchange = async () => {
			if (input.files?.length) {
				resolve(input.files[0]);
			}
		};
		input.click();
	});
}

function downloadFile(fileName: string, content: Blob) {
	const url = URL.createObjectURL(content);
	const link = document.createElement('a');
	link.href = url;
	link.download = fileName;
	link.click();
}
