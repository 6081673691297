import { Input } from '../../primitives/Input';
import { InputFieldProps, InputFieldSpec } from '../InputField';

export function createTextInputFieldDefaultRawValue(): string {
	return '';
}

export function parseTextInputFieldRawValue(rawValue: string, spec: InputFieldSpec): string {
	if (spec.isRequired && !rawValue) {
		throw new Error('Value is required');
	}
	return rawValue;
}

export function TextInputField(props: InputFieldProps<string>) {
	return <Input name={props.spec.name} value={props.rawValue} isInvalid={props.hasError} onChanged={props.onChange} />;
}
