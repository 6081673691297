"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.logStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const StepNameFormatter_1 = require("../../utilities/formatters/StepNameFormatter");
exports.logStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('log', 'task', step => {
    step.label('Log');
    step.category('Tracing');
    step.name()
        .dependentProperty('message')
        .value((0, sequential_workflow_editor_model_1.createGeneratedStringValueModel)({
        generator(context) {
            const message = context.formatPropertyValue('message', StepNameFormatter_1.StepNameFormatter.formatDynamic);
            return `Log(${message})`;
        }
    }));
    step.property('message')
        .value((0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, sequential_workflow_editor_model_1.createStringValueModel)({}),
            (0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
                isRequired: true,
                valueType: 'text'
            })
        ]
    }))
        .label('Text');
    step.property('variables').value((0, sequential_workflow_editor_model_1.createAnyVariablesValueModel)({})).label('Log variables');
});
