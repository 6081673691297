"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.textCollectionLoopStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const StepNameFormatter_1 = require("../../utilities/formatters/StepNameFormatter");
exports.textCollectionLoopStepModel = (0, sequential_workflow_editor_model_1.createSequentialStepModel)('textCollectionLoop', 'container', step => {
    step.label('TextCollectionLoop');
    step.category('Flow');
    step.name()
        .dependentProperty('textCollection')
        .dependentProperty('currentVariable')
        .value((0, sequential_workflow_editor_model_1.createGeneratedStringValueModel)({
        generator(context) {
            const textCollection = context.formatPropertyValue('textCollection', StepNameFormatter_1.StepNameFormatter.formatDynamic);
            const currentVariable = context.formatPropertyValue('currentVariable', StepNameFormatter_1.StepNameFormatter.formatVariable);
            return `each ${currentVariable} of ${textCollection}`;
        }
    }));
    step.property('textCollection')
        .label('Input collection')
        .value((0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
                valueType: 'textCollection',
                isRequired: true
            })
        ]
    }));
    step.property('currentVariable')
        .label('Current value variable')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableDefinitionValueModel)({
        valueType: 'text',
        defaultValue: {
            name: 'current',
            type: 'text'
        },
        isRequired: true
    }));
    step.property('indexVariable')
        .label('Current index variable')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableDefinitionValueModel)({
        valueType: 'number',
        isRequired: false
    }));
    const num = (0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, sequential_workflow_editor_model_1.createNumberValueModel)({
                defaultValue: 0
            }),
            (0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
                valueType: 'number',
                isRequired: true
            })
        ]
    });
    step.property('offset').value(num).hint('Offset from the beginning of the collection.');
    step.property('limit').value(num).hint('If 0 then no limit.');
});
