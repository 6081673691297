import { ChangeEvent } from 'react';
import { InputFieldProps, InputFieldSpec } from '../InputField';

export type FileFieldRawValue = File | null;
export type FileFieldValue = (() => Promise<string>) | null;

export function createFileInputFieldDefaultRawValue(): FileFieldRawValue {
	return null;
}

export function parseFileInputFieldRawValue(rawValue: FileFieldRawValue, spec: InputFieldSpec): FileFieldValue {
	if (spec.isRequired && !rawValue) {
		throw new Error('Value is required');
	}
	if (!rawValue) {
		return null;
	}
	const file = rawValue;
	return () =>
		new Promise<string>((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => resolve(reader.result as string);
			reader.onerror = reject;
			reader.readAsDataURL(file);
		});
}

export function FileInputField(props: InputFieldProps<FileFieldRawValue>) {
	function onChange(e: ChangeEvent<HTMLInputElement>) {
		const file = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
		props.onChange(file);
	}

	return <input type="file" onChange={onChange} className="w-full" />;
}
