"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.queryHtmlStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const utilities_1 = require("../../utilities");
const evaluableStringValueModel_1 = require("../../model/evaluableStringValueModel");
exports.queryHtmlStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('queryHtml', 'task', step => {
    step.label('QueryHtml');
    step.category('Transformers');
    step.name()
        .dependentProperty('output')
        .dependentProperty('selector')
        .value((0, sequential_workflow_editor_model_1.createGeneratedStringValueModel)({
        generator(context) {
            const output = context.formatPropertyValue('output', utilities_1.StepNameFormatter.formatVariable);
            const selector = context.formatPropertyValue('selector', utilities_1.StepNameFormatter.formatDynamic);
            return `${output} = QueryHtml(${selector})`;
        }
    }));
    step.property('input')
        .value((0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
                valueType: 'text',
                isRequired: true
            })
        ]
    }))
        .hint('Expected text variable that contains HTML.');
    step.property('selector')
        .value((0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, evaluableStringValueModel_1.createEvaluableStringValueModel)({
                minLength: 1
            }),
            (0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
                valueType: 'string',
                isRequired: true
            })
        ]
    }))
        .validator(utilities_1.RichTextValidator.dynamicTextValidator)
        .hint('CSS selector to find element, for example:\n- "#a .b" - return text content\n- "#a .b|attr:title" - return value of "title" attribute\n- "#a .b|href" - return value of href');
    step.property('output')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        valueType: 'text',
        isRequired: true
    }))
        .hint('Expected type: text');
    step.property('failureMode').value((0, sequential_workflow_editor_model_1.createChoiceValueModel)({
        choices: ['empty text', 'error']
    }));
    step.property('errorCode')
        .value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        valueType: sequential_workflow_editor_model_1.WellKnownValueType.number
    }))
        .label('Error code variable')
        .hint('Error codes:\n0 - no error\n1 - cannot parse HTML\n2 - selector not found');
});
