import { useState } from 'react';
import { OutputFieldProps } from '../OutputField';
import { CodeBox } from './CodeBox';

export function HtmlOutputField(props: OutputFieldProps) {
	const value = props.field.value as string;
	const [preview, setPreview] = useState<boolean>(true);

	function togglePreview() {
		setPreview(!preview);
	}

	return (
		<div className="bg-gray-200 rounded-md">
			<div className="p-2 text-right text-xs">
				<span onClick={togglePreview} className="hover:underline cursor-pointer">
					{preview ? 'show raw' : 'show preview'}
				</span>
			</div>
			{preview && (
				<div className="bg-white border border-gray-300">
					<iframe
						title={props.field.name}
						sandbox="allow-forms allow-popups allow-popups-to-escape-sandbox"
						className="w-full h-[200px]"
						srcDoc={value}
					/>
				</div>
			)}
			{!preview && <CodeBox>{value}</CodeBox>}
		</div>
	);
}
