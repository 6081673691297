import { WorkflowLog, WorkflowLogType, WorkflowLogs } from 'nocode-api-builder-model';
import { LogItemBox, LogItemBoxTheme } from '../primitives/LogItemBox';

export type StepNameMap = Record<string, string>;

export function Logs(props: { logs: WorkflowLogs; stepNameMap: StepNameMap }) {
	return (
		<div className="text-sm">
			{props.logs.map((log, index) => (
				<LogRow key={index} log={log} stepName={log[1] ? props.stepNameMap[log[1]] : null} />
			))}
		</div>
	);
}

function LogRow(props: { log: WorkflowLog; stepName: string | null | undefined }) {
	let theme: LogItemBoxTheme;
	switch (props.log[0]) {
		case WorkflowLogType.error:
			theme = LogItemBoxTheme.error;
			break;
		case WorkflowLogType.user:
			theme = LogItemBoxTheme.user;
			break;
		default:
			theme = LogItemBoxTheme.default;
			break;
	}

	return (
		<LogItemBox theme={theme}>
			<div className="flex w-full ">
				<div className="flex-1">{props.log[2]}</div>
				{props.stepName && (
					<div>
						<span className="text-gray-400 border-gray-400 border px-2 py-0.5 rounded">{props.stepName}</span>
					</div>
				)}
			</div>
			{props.log.length > 3 &&
				props.log.slice(3).map((param, index) => <LogRowParam key={index} keyValue={param as [string, string]} />)}
		</LogItemBox>
	);
}

function LogRowParam(props: { keyValue: [string, string] }) {
	return (
		<div className="flex w-full items-center mt-1.5">
			<div className="text-right whitespace-nowrap pr-2 min-w-[50px]">{props.keyValue[0]}</div>
			<div className="flex-1 overflow-auto max-h-40 bg-gray-100 rounded-md">
				<pre className="p-1 text-xs">{formatParamValue(props.keyValue[1])}</pre>
			</div>
		</div>
	);
}

function formatParamValue(value: string) {
	const data = JSON.parse(value);
	if (typeof data === 'string') {
		return data;
	}
	return JSON.stringify(data, null, 2);
}
