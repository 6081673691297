"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TriggerValidator = void 0;
const TooLargeTriggerInputError_1 = require("./TooLargeTriggerInputError");
const TriggerParameters_1 = require("./TriggerParameters");
const TriggerType_1 = require("./TriggerType");
const MAX_INPUT_SIZE = 1024 * 64;
class TriggerValidator {
    static validateIndex(index) {
        if (typeof index !== 'number') {
            throw new Error('Index must be a number');
        }
    }
    static validateName(name) {
        if (typeof name !== 'string') {
            throw new Error('Name must be a string');
        }
        if (name.length > 128) {
            throw new Error('Trigger name is too long');
        }
    }
    static validateType(type) {
        if (type !== TriggerType_1.TriggerType.cron) {
            throw new Error(`Unknown trigger type: ${type}`);
        }
    }
    static validateIsEnabled(isEnabled) {
        if (typeof isEnabled !== 'boolean') {
            throw new Error('isEnabled must be a boolean');
        }
    }
    static validateParameters(type, parameters) {
        TriggerValidator.validateType(type);
        const values = Object.keys(TriggerParameters_1.TriggerParameters.cron);
        if (!values.includes(parameters)) {
            throw new Error(`Not supported parameters: ${parameters}`);
        }
    }
    static validateInput(input) {
        if (typeof input !== 'object' || input === null || Array.isArray(input)) {
            throw new Error('Input must be an object');
        }
        const size = JSON.stringify(input).length;
        if (size > MAX_INPUT_SIZE) {
            throw new TooLargeTriggerInputError_1.TooLargeTriggerInputError(`Trigger input is too large: ${size}, max size: ${MAX_INPUT_SIZE}`);
        }
    }
}
exports.TriggerValidator = TriggerValidator;
