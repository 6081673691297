import { InputFieldSpec, createFieldDefaultRawValue, parseInputFieldRawValue } from './InputField';
import { RichVariableDefinitions } from '@nocode-js/sequential-workflow-editor-model-pro/value-models/rich-variable-definitions';

export interface FlowInputFormField {
	spec: InputFieldSpec;
	rawValue: unknown;
	value: unknown;
	error: string | null;
}

export interface FlowInputFormState {
	hasError: boolean;
	fields: FlowInputFormField[];
}

export function createFlowInputFormState(specs: InputFieldSpec[]): FlowInputFormState {
	const fields = specs.map<FlowInputFormField>(spec => {
		const rawValue = spec.initialValue ?? createFieldDefaultRawValue(spec);
		const result = parseFlowInputFieldRawValue(rawValue, spec);
		return {
			spec,
			rawValue,
			value: result.value,
			error: result.error
		};
	});
	const hasError = hasAnyFlowInputFieldError(fields);
	return { fields, hasError };
}

export function mapFlowInputFormState(
	definition: { properties: { inputs: RichVariableDefinitions } },
	initialInput: Record<string, unknown> | null
) {
	return createFlowInputFormState(
		definition.properties.inputs.variables.map(v => ({
			isRequired: Boolean(v.isRequired),
			name: v.name,
			type: v.type,
			initialValue: initialInput ? initialInput[v.name] : undefined
		}))
	);
}

export async function readFlowInputFormStateValues(state: FlowInputFormState): Promise<Record<string, unknown>> {
	const values = await Promise.all(
		state.fields.map(field => {
			return typeof field.value === 'function' ? field.value() : field.value;
		})
	);
	return state.fields.reduce<Record<string, unknown>>((result, field, index) => {
		result[field.spec.name] = values[index];
		return result;
	}, {});
}

export function hasAnyFlowInputFieldError(fields: FlowInputFormField[]): boolean {
	return fields.some(field => !!field.error);
}

export function parseFlowInputFieldRawValue(rawValue: unknown, spec: InputFieldSpec) {
	try {
		const value = parseInputFieldRawValue(rawValue, spec);
		return { value, error: null };
	} catch (e) {
		const error = e instanceof Error ? e.message : String(e);
		return { value: null, error };
	}
}
