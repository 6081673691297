"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.textLengthStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const StepNameFormatter_1 = require("../../utilities/formatters/StepNameFormatter");
exports.textLengthStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('textLength', 'task', step => {
    step.label('TextLength');
    step.category('Primitives');
    step.name()
        .dependentProperty('input')
        .dependentProperty('output')
        .value((0, sequential_workflow_editor_model_1.createGeneratedStringValueModel)({
        generator(context) {
            const input = context.formatPropertyValue('input', StepNameFormatter_1.StepNameFormatter.formatVariable);
            const output = context.formatPropertyValue('output', StepNameFormatter_1.StepNameFormatter.formatVariable);
            return `${output} = TextLength(${input})`;
        }
    }));
    step.property('input').value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        valueType: 'text',
        isRequired: true
    }));
    step.property('output').value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        valueType: sequential_workflow_editor_model_1.WellKnownValueType.number,
        isRequired: true
    }));
});
