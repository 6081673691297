import { GetTracesResponse } from 'nocode-api-builder-model';
import { useTraceApiClient } from '../../api/TraceApiClient';
import { useEffect, useState } from 'react';
import { ErrorDefaultLayout } from '../../components/layout/ErrorDefaultLayout';
import { LoadingDefaultLayout } from '../../components/layout/LoadingDefaultLayout';
import { DefaultLayout } from '../../components/layout/DefaultLayout';
import { HttpClient } from '../../api/HttpClient';
import { TraceListPage } from './TraceListPage';
import { useSearchParams } from 'react-router-dom';

export function TracesRoute() {
	const apiClient = useTraceApiClient();
	const [searchParams] = useSearchParams();
	const page = searchParams.has('page') ? Number(searchParams.get('page')) : 0;
	const processId = searchParams.get('processId') ?? undefined;
	const triggerId = searchParams.get('triggerId') ?? undefined;
	const [response, setResponse] = useState<GetTracesResponse | null>(null);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		async function load() {
			try {
				const response = await apiClient.getTraces({
					page,
					processId,
					triggerId
				});
				setResponse(response);
			} catch (e) {
				setError(HttpClient.readError(e));
				console.error(e);
			}
		}
		load();
	}, [apiClient, page, processId, triggerId]);

	if (error) {
		return <ErrorDefaultLayout error={error} />;
	}
	if (!response) {
		return <LoadingDefaultLayout />;
	}
	return (
		<DefaultLayout>
			<TraceListPage page={page} processId={processId} triggerId={triggerId} response={response} />
		</DefaultLayout>
	);
}
