import { useState } from 'react';
import { DefaultLayout } from '../../../components/layout/DefaultLayout';
import { ChangePassword, ChangePasswordData } from '../../../components/theme/changePassword/ChangePassword';
import { UserValidator } from 'nocode-api-builder-model';
import { HttpClient } from '../../../api/HttpClient';
import { useAuthApiClient } from '../../../api/AuthApiClient';

export interface ChangePasswordRouteState {
	error: string | null;
	success: boolean;
	data: ChangePasswordData;
}

export function ChangePasswordRoute() {
	const apiClient = useAuthApiClient();
	const [state, setState] = useState<ChangePasswordRouteState>(() => ({
		data: createEmptyData(),
		error: null,
		success: false
	}));

	function onDataChanged(data: ChangePasswordData) {
		setState({
			...state,
			data
		});
	}

	async function onSubmit() {
		const newState = { ...state };
		if (state.success || state.error) {
			newState.success = false;
			newState.error = null;
			setState(newState);
		}

		try {
			if (!state.data.currentPassword) {
				throw new Error('Current password is empty');
			}
			if (state.data.password1 !== state.data.password2) {
				throw new Error('The repeated password is different');
			}
			UserValidator.validPassword(state.data.password1);
		} catch (e) {
			setState({
				...newState,
				error: e instanceof Error ? e.message : String(e)
			});
			return;
		}

		try {
			await apiClient.changePassword({
				currentPassword: state.data.currentPassword,
				newPassword: state.data.password1
			});
		} catch (e) {
			setState({
				...newState,
				error: HttpClient.readError(e)
			});
			return;
		}

		setState({
			error: null,
			success: true,
			data: createEmptyData()
		});
	}

	return (
		<DefaultLayout>
			<ChangePassword data={state.data} error={state.error} success={state.success} onChanged={onDataChanged} onSubmit={onSubmit} />
		</DefaultLayout>
	);
}

function createEmptyData(): ChangePasswordData {
	return {
		currentPassword: '',
		password1: '',
		password2: ''
	};
}
