import { Link } from 'react-router-dom';
import { CenteredBox } from '../layout/CenteredBox';
import { PageTitle } from '../layout/PageTitle';
import { Button } from '../primitives/Button';

export interface FlowListProps {
	createUrl: string;
	onDeleteClicked: (id: string) => void;
	onDuplicateClicked: (id: string) => void;
	flows: {
		id: string;
		name: string;
		method: string;
		enabledTriggers: number;
		editUrl: string;
		editTriggersUrls: string;
		testUrl: string;
		executeUrl: string | null;
		tracesUrl: string;
	}[];
}

export function FlowList(props: FlowListProps) {
	return (
		<CenteredBox>
			<PageTitle title="Flows">
				<Button href={props.createUrl} size="md">
					Create
				</Button>
			</PageTitle>

			{props.flows.length === 0 && (
				<div className="px-4 py-20 text-center">
					<p className="text-sm text-gray-500">No flows found.</p>
				</div>
			)}

			{props.flows.length > 0 && (
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 my-4 mx-4">
					{props.flows.map(e => (
						<div key={e.id} className="p-2.5 border border-gray-300/80 rounded-t-md">
							<div className="flex w-full">
								<div className="flex-1">
									<h4 className="text-xl font-bold">{e.name}</h4>
								</div>
								<div>
									<Button onClicked={() => props.onDeleteClicked(e.id)} theme="secondary" size="sm" className="text-xs">
										Delete
									</Button>
									<Button
										onClicked={() => props.onDuplicateClicked(e.id)}
										theme="secondary"
										size="sm"
										className="text-xs ml-1"
									>
										Duplicate
									</Button>
								</div>
							</div>

							<div className="flex mt-3 items-center">
								<div className="flex-1">
									<Button href={e.editUrl} size="md" className="mr-1">
										Edit
									</Button>
									<Button href={e.testUrl} theme="secondary" size="md" className="mr-1">
										Test
									</Button>
									<Button href={e.tracesUrl} theme="secondary" size="md" className="mr-1">
										Traces
									</Button>
									{e.executeUrl && (
										<Button href={e.executeUrl} size="md" theme="secondary" target="_blank" className="mr-1">
											Form
										</Button>
									)}
								</div>
								<div>
									{e.enabledTriggers > 0 && (
										<Link
											to={e.editTriggersUrls}
											className="m-0 px-2 py-1 rounded-md text-xs text-white bg-blue-400 hover:bg-blue-300"
										>
											{e.enabledTriggers} {e.enabledTriggers > 1 ? 'triggers' : 'trigger'}
										</Link>
									)}
								</div>
							</div>
						</div>
					))}
				</div>
			)}
		</CenteredBox>
	);
}
