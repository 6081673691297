"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Environment = void 0;
var Environment;
(function (Environment) {
    Environment[Environment["test"] = 1] = "test";
    Environment[Environment["form"] = 2] = "form";
    Environment[Environment["api"] = 3] = "api";
    Environment[Environment["trigger"] = 4] = "trigger";
})(Environment || (exports.Environment = Environment = {}));
