"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RichTextParser = void 0;
const variableRegexp = /\{\$[a-zA-Z_0-9-]+\}/g;
class RichTextParser {
    static replaceVariables(text, replacer) {
        return text.replaceAll(variableRegexp, (match) => {
            const name = match.substring(2, match.length - 1);
            return replacer(name);
        });
    }
    static findVariables(text) {
        const matches = text.match(variableRegexp);
        if (matches === null) {
            return [];
        }
        return matches.map(match => match.substring(2, match.length - 1));
    }
}
exports.RichTextParser = RichTextParser;
