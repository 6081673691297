"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flowDefinitionModel = exports.rootModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const rich_variable_definitions_1 = require("@nocode-js/sequential-workflow-editor-model-pro/value-models/rich-variable-definitions");
const modelSet_1 = require("./modelSet");
// For now we don't have input fields for complex types.
const inputValueTypes = ['text', 'number', 'file'];
const internalValueTypes = ['text', 'textCollection', 'number', 'json', 'file', 'image', 'markdown'];
// We don't want to return files.
const outputValueTypes = ['text', 'textCollection', 'number', 'json', 'image', 'markdown'];
const allValueTypes = [...new Set([...inputValueTypes, ...internalValueTypes, ...outputValueTypes])];
exports.rootModel = (0, sequential_workflow_editor_model_1.createRootModel)(root => {
    root.property('inputs')
        .value((0, rich_variable_definitions_1.createRichVariableDefinitionsValueModel)({
        valueTypes: inputValueTypes
    }))
        .label('Input variables');
    root.property('internals')
        .value((0, sequential_workflow_editor_model_1.createVariableDefinitionsValueModel)({
        valueTypes: internalValueTypes
    }))
        .label('Internal variables');
    root.property('outputs')
        .value((0, rich_variable_definitions_1.createRichVariableDefinitionsValueModel)({
        valueTypes: outputValueTypes,
        isIsRequiredDisabled: true
    }))
        .label('Output variables');
});
exports.flowDefinitionModel = (0, sequential_workflow_editor_model_1.createDefinitionModel)(model => {
    model.valueTypes(allValueTypes);
    model.root(exports.rootModel);
    model.steps(modelSet_1.modelSet);
});
