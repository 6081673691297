import { Html, ValueEditor } from 'sequential-workflow-editor';
import { ValueContext } from 'sequential-workflow-editor-model';
import { EditorView, basicSetup } from 'codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { JavaScriptCodeValueModel } from 'nocode-api-builder-model';
import { indentWithTab } from '@codemirror/commands';
import { keymap } from '@codemirror/view';

export interface JavaScriptCodeEditorConfiguration {
	suggestionProvider: (request: { prompt: string }) => Promise<{ answer: string }>;
}

export const javaScriptCodeEditorId = 'javaScriptCode';

export function createJavaScriptCodeEditor(configuration: JavaScriptCodeEditorConfiguration) {
	return (context: ValueContext<JavaScriptCodeValueModel>): ValueEditor<JavaScriptCodeValueModel> => {
		function setCode(code: string) {
			editor.dispatch({ changes: { from: 0, to: editor.state.doc.length, insert: code } });
			context.setValue({
				code,
				question: chatInput.value
			});
		}

		async function onChatSubmit() {
			const question = chatInput.value;
			const prompt = buildPrompt(question);
			if (!prompt) {
				window.alert('The question field is empty');
				return;
			}

			try {
				chatInput.setAttribute('readonly', 'readonly');
				chatSubmit.setAttribute('disabled', 'disabled');

				const response = await configuration.suggestionProvider({ prompt });
				const code = extractResponse(response.answer);
				if (code) {
					setCode(code);
				}
			} finally {
				chatInput.removeAttribute('readonly');
				chatSubmit.removeAttribute('disabled');
			}
		}

		const view = Html.element('div', {
			class: 'swe-javascript-editor'
		});
		const codemirror = Html.element('div', {
			class: 'swe-javascript-editor-codemirror'
		});
		const chat = Html.element('div', {
			class: 'swe-javascript-editor-chat'
		});
		const chatInput = Html.element('textarea', {
			class: 'swe-javascript-editor-chat-input',
			placeholder: 'Ask AI to generate code'
		});
		const chatSubmit = Html.element('button', {
			class: 'swe-javascript-editor-chat-submit'
		});
		chatSubmit.innerText = 'Ask';

		view.appendChild(codemirror);
		view.appendChild(chat);
		chat.appendChild(chatInput);
		chat.appendChild(chatSubmit);
		chatSubmit.addEventListener('click', onChatSubmit, false);

		const initialValue = context.getValue();
		const updateListenerExtension = EditorView.updateListener.of(update => {
			if (update.docChanged) {
				context.setValue({
					code: update.state.doc.toString(),
					question: chatInput.value
				});
			}
		});
		const editor = new EditorView({
			doc: initialValue.code,
			extensions: [basicSetup, keymap.of([indentWithTab]), javascript(), updateListenerExtension],
			parent: codemirror
		});
		chatInput.value = initialValue.question;

		return {
			view
		};
	};
}

function buildPrompt(input: string): string | null {
	const raw = input.trim();
	if (raw.length < 1) {
		return null;
	}
	const question = `You are a world class software engineer. You use JavaScript to write code. ${raw}`;
	return question;
}

function extractResponse(response: string): string | null {
	const res = response.match(/```([\s\S]+?)```/g);
	if (!res || res.length < 1) {
		return null;
	}
	const lines = res[0].split('\n');
	return lines.splice(1, lines.length - 2).join('\n');
}
