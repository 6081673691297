"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TriggerNextTimeCalculator = void 0;
const TriggerType_1 = require("./TriggerType");
class TriggerNextTimeCalculator {
    static calculate(type, parameters, now) {
        if (type !== TriggerType_1.TriggerType.cron) {
            throw new Error('Not implemented trigger type');
        }
        const [tag, rawValue] = parameters.split(':', 2);
        const value = parseInt(rawValue);
        if (tag === 'm') {
            const next = new Date(now);
            next.setMinutes(next.getMinutes() + value);
            next.setSeconds(0);
            next.setMilliseconds(0);
            return next.getTime();
        }
        if (tag === 'h') {
            const next = new Date(now);
            next.setHours(next.getHours() + value);
            next.setMinutes(0);
            next.setSeconds(0);
            next.setMilliseconds(0);
            return next.getTime();
        }
        if (tag === 'd') {
            const next = new Date(now);
            next.setHours(value);
            next.setMinutes(0);
            next.setSeconds(0);
            next.setMilliseconds(0);
            if (next.getTime() < now) {
                next.setDate(next.getDate() + 1);
            }
            return next.getTime();
        }
        throw new Error(`Not supported cron parameters: ${parameters}`);
    }
}
exports.TriggerNextTimeCalculator = TriggerNextTimeCalculator;
