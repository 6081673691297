"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.randomStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const StepNameFormatter_1 = require("../../utilities/formatters/StepNameFormatter");
exports.randomStepModel = (0, sequential_workflow_editor_model_1.createStepModel)('random', 'task', step => {
    step.label('Rand');
    step.category('Primitives');
    step.description('Random number between two numbers');
    step.name()
        .dependentProperty('from')
        .dependentProperty('to')
        .dependentProperty('result')
        .value((0, sequential_workflow_editor_model_1.createGeneratedStringValueModel)({
        generator(context) {
            const from = context.formatPropertyValue('from', StepNameFormatter_1.StepNameFormatter.formatDynamic);
            const to = context.formatPropertyValue('to', StepNameFormatter_1.StepNameFormatter.formatDynamic);
            const result = context.formatPropertyValue('result', StepNameFormatter_1.StepNameFormatter.formatVariable);
            return `${result} = Rand(${from}, ${to})`;
        }
    }));
    const val = (0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, sequential_workflow_editor_model_1.createNumberValueModel)({}),
            (0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
                isRequired: true,
                valueType: sequential_workflow_editor_model_1.WellKnownValueType.number
            })
        ]
    });
    step.property('result').value((0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
        valueType: sequential_workflow_editor_model_1.WellKnownValueType.number,
        isRequired: true
    }));
    step.property('from').value(val).label('From');
    step.property('to').value(val).label('To');
});
