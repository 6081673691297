"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RichTextValidator = void 0;
const RichTextParser_1 = require("./RichTextParser");
class RichTextValidator {
    //
    static tryValidateDynamic(context) {
        const value = context.getValue();
        if (value.modelId === 'string') {
            const error = RichTextValidator.validateString(value.value, context);
            if (error) {
                return error;
            }
        }
        return null;
    }
    static validateString(value, context) {
        const variables = RichTextParser_1.RichTextParser.findVariables(value);
        const undef = context.findFirstUndefinedVariable(variables);
        if (undef) {
            return `Variable $${undef} not found`;
        }
        return null;
    }
    static validatePath(value, context) {
        return RichTextValidator.validateString(value, context);
    }
}
exports.RichTextValidator = RichTextValidator;
RichTextValidator.dynamicTextValidator = {
    validate(context) {
        return RichTextValidator.tryValidateDynamic(context);
    }
};
RichTextValidator.dynamicPathValidator = RichTextValidator.dynamicTextValidator;
RichTextValidator.dynamicUrlValidator = RichTextValidator.dynamicTextValidator;
RichTextValidator.stringTextValidator = {
    validate(context) {
        return RichTextValidator.validateString(context.getValue(), context);
    }
};
RichTextValidator.stringPathValidator = RichTextValidator.stringTextValidator;
RichTextValidator.stringUrlValidator = RichTextValidator.stringTextValidator;
