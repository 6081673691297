"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendEmailModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const utilities_1 = require("../../utilities");
const evaluableStringValueModel_1 = require("../../model/evaluableStringValueModel");
exports.sendEmailModel = (0, sequential_workflow_editor_model_1.createStepModel)('sendEmail', 'task', step => {
    step.label('SendEmail');
    step.category('Services');
    const str = (0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, evaluableStringValueModel_1.createEvaluableStringValueModel)({
                minLength: 1
            }),
            (0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
                valueType: 'text',
                isRequired: true
            })
        ]
    });
    step.property('to').value(str).label('To (e-mail)').validator(utilities_1.RichTextValidator.dynamicTextValidator);
    step.property('subject').value(str).label('Subject').validator(utilities_1.RichTextValidator.dynamicTextValidator);
    step.property('body')
        .value((0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, evaluableStringValueModel_1.createEvaluableStringValueModel)({
                multiline: 10
            }),
            (0, sequential_workflow_editor_model_1.createNullableAnyVariableValueModel)({
                isRequired: true,
                valueTypes: ['text', 'markdown']
            })
        ]
    }))
        .label('Body')
        .validator(utilities_1.RichTextValidator.dynamicTextValidator);
});
