"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ifStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const StepNameFormatter_1 = require("../../utilities/formatters/StepNameFormatter");
exports.ifStepModel = (0, sequential_workflow_editor_model_1.createBranchedStepModel)('if', 'switch', step => {
    step.label('If');
    step.category('Flow');
    step.name()
        .dependentProperty('a')
        .dependentProperty('comparison')
        .dependentProperty('b')
        .value((0, sequential_workflow_editor_model_1.createGeneratedStringValueModel)({
        generator(context) {
            const a = context.formatPropertyValue('a', StepNameFormatter_1.StepNameFormatter.formatDynamic);
            const comparison = context.getPropertyValue('comparison');
            const b = context.formatPropertyValue('b', StepNameFormatter_1.StepNameFormatter.formatDynamic);
            return `${a} ${comparison} ${b}`;
        }
    }));
    const ab = (0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, sequential_workflow_editor_model_1.createNullableAnyVariableValueModel)({
                isRequired: true,
                valueTypes: ['text', 'number', 'boolean']
            }),
            (0, sequential_workflow_editor_model_1.createStringValueModel)({}),
            (0, sequential_workflow_editor_model_1.createNumberValueModel)({}),
            (0, sequential_workflow_editor_model_1.createBooleanValueModel)({})
        ]
    });
    step.property('a').value(ab);
    step.property('comparison').value((0, sequential_workflow_editor_model_1.createChoiceValueModel)({
        choices: ['==', '===', '!=', '!==', '>', '<', '>=', '<='],
        defaultValue: '=='
    }));
    step.property('b').value(ab);
    step.branches().value((0, sequential_workflow_editor_model_1.createBranchesValueModel)({
        branches: {
            true: [],
            false: []
        }
    }));
});
