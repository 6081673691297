import { Button } from '../primitives/Button';

export interface DevTabFormProps {
	onImportDefinitionClicked: () => void;
	onExportDefinitionClicked: () => void;
}

export function DevTabForm(props: DevTabFormProps) {
	return (
		<div className="p-4">
			<h4 className="mb-2">Dev</h4>
			<Button onClicked={props.onExportDefinitionClicked} size="sm">
				Export definition
			</Button>{' '}
			<Button onClicked={props.onImportDefinitionClicked} size="sm">
				Import definition
			</Button>
		</div>
	);
}
