import { Link } from 'react-router-dom';

export interface ButtonProps {
	children: React.ReactNode;
	size?: 'sm' | 'md' | 'lg';
	theme?: 'primary' | 'secondary' | 'white' | 'danger' | 'warning';
	type?: 'button' | 'submit';
	isDisabled?: boolean;
	href?: string;
	target?: string;
	className?: string;
	onClicked?: () => void;
}

export function Button(props: ButtonProps) {
	let cls: string;
	if (props.isDisabled) {
		cls = 'text-gray-500 bg-gray-200 cursor-not-allowed';
	} else if (props.theme === 'secondary') {
		cls = 'text-gray-900 bg-white border border-gray-200 hover:text-gray-500 hover:border-gray-300';
	} else if (props.theme === 'white') {
		cls = 'text-gray-900 bg-white hover:bg-gray-100';
	} else if (props.theme === 'danger') {
		cls = 'text-white bg-red-600 hover:bg-red-700';
	} else if (props.theme === 'warning') {
		cls = 'text-black bg-yellow-500 hover:bg-yellow-600';
	} else {
		cls = 'text-white bg-blue-700 hover:bg-blue-800';
	}

	cls += ' focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-sm box-border inline-block';
	if (props.size === 'md') {
		cls += ' px-3 py-2';
	} else if (props.size === 'sm') {
		cls += ' px-1.5 py-1';
	} else {
		cls += ' px-4 py-3'; // lg
	}
	if (props.className) {
		cls += ' ' + props.className;
	}

	if (props.href) {
		return (
			<Link to={props.href} className={cls} target={props.target}>
				{props.children}
			</Link>
		);
	}
	return (
		<button type={props.type || 'button'} className={cls} onClick={props.onClicked}>
			{props.children}
		</button>
	);
}
