import { FormEvent } from 'react';
import { Button } from '../primitives/Button';
import { Input } from '../primitives/Input';
import { FullscreenForm } from '../layout/FullscreenForm';

export interface SignUpData {
	email: string;
	invitationCode: string;
	password1: string;
	password2: string;
}

export interface SignUpProps {
	isLoading: boolean;
	data: SignUpData;
	error: string | null;
	onDataChanged: (data: SignUpData) => void;
	onSubmit: () => void;
}

export function SignUp(props: SignUpProps) {
	function onEmailChanged(email: string) {
		props.onDataChanged({
			...props.data,
			email
		});
	}

	function onInvitationCodeChanged(invitationCode: string) {
		props.onDataChanged({
			...props.data,
			invitationCode
		});
	}

	function onPassword1Changed(password1: string) {
		props.onDataChanged({
			...props.data,
			password1
		});
	}

	function onPassword2Changed(password2: string) {
		props.onDataChanged({
			...props.data,
			password2
		});
	}

	function onSubmit(e: FormEvent) {
		e.preventDefault();
		props.onSubmit();
	}

	return (
		<FullscreenForm title="Create New Account" error={props.error}>
			<form className="space-y-4 md:space-y-6" onSubmit={onSubmit}>
				<div>
					<label className="block mb-2 text-sm font-medium text-gray-900">E-mail</label>
					<Input value={props.data.email} name="email" onChanged={onEmailChanged} />
				</div>
				<div>
					<label className="block mb-2 text-sm font-medium text-gray-900">Invitation code</label>
					<Input value={props.data.invitationCode} name="email" onChanged={onInvitationCodeChanged} />
				</div>
				<div>
					<label className="block mb-2 text-sm font-medium text-gray-900">Password</label>
					<Input type="password" name="password1" value={props.data.password1} onChanged={onPassword1Changed} />
				</div>
				<div>
					<label className="block mb-2 text-sm font-medium text-gray-900">Confirm Password</label>
					<Input type="password" name="password2" value={props.data.password2} onChanged={onPassword2Changed} />
				</div>

				<Button type="submit" isDisabled={props.isLoading}>
					Sign in
				</Button>

				<p className="text-sm font-light text-gray-500">
					Do you already have an account?{' '}
					<a href="/signin" className="font-medium text-blue-600 hover:underline">
						Sign in
					</a>
				</p>
			</form>
		</FullscreenForm>
	);
}
