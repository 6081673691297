"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.switchStepModel = void 0;
const sequential_workflow_editor_model_1 = require("sequential-workflow-editor-model");
const StepNameFormatter_1 = require("../../utilities/formatters/StepNameFormatter");
exports.switchStepModel = (0, sequential_workflow_editor_model_1.createBranchedStepModel)('switch', 'switch', step => {
    step.label('Switch');
    step.category('Flow');
    step.name()
        .dependentProperty('value')
        .value((0, sequential_workflow_editor_model_1.createGeneratedStringValueModel)({
        generator(context) {
            const value = context.formatPropertyValue('value', StepNameFormatter_1.StepNameFormatter.formatDynamic);
            return `${value}`;
        }
    }));
    step.property('value').value((0, sequential_workflow_editor_model_1.createDynamicValueModel)({
        models: [
            (0, sequential_workflow_editor_model_1.createNullableVariableValueModel)({
                isRequired: true,
                valueType: 'text'
            }),
            (0, sequential_workflow_editor_model_1.createStringValueModel)({})
        ]
    }));
    step.property('comparer')
        .value((0, sequential_workflow_editor_model_1.createChoiceValueModel)({
        choices: ['strict', 'ignore case']
    }))
        .label('Comparer');
    step.property('lastAsDefault')
        .value((0, sequential_workflow_editor_model_1.createBooleanValueModel)({}))
        .label('Last case as default')
        .hint('If no case matches, this step throws an error. If this option is enabled, the last case is used as default.');
    step.branches()
        .value((0, sequential_workflow_editor_model_1.createBranchesValueModel)({
        branches: {
            value: []
        },
        dynamic: true,
        editorId: 'dynamicBranches'
    }))
        .label('Cases');
});
