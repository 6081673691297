import { SubscriptionUsageDto } from 'nocode-api-builder-model';
import { Button } from '../primitives/Button';

export interface CurrentSubscriptionProps {
	usage: null | SubscriptionUsageDto;
	onManageSubscriptionClicked: () => void;
}

export function CurrentSubscription(props: CurrentSubscriptionProps) {
	return (
		<div className="flex items-center w-full mt-3 px-4 py-1.5 bg-blue-50 rounded-md">
			<div className="flex-1 text-sm text-gray-400">
				{props.usage && <CurrentPeriod usage={props.usage} />}
				{!props.usage && <>You don't have any active subscription</>}
			</div>
			<div>
				<Button onClicked={props.onManageSubscriptionClicked} size="md">
					Manage subscription
				</Button>
			</div>
		</div>
	);
}

function CurrentPeriod(props: { usage: SubscriptionUsageDto }) {
	const percent =
		props.usage.totalCredits > 0 ? (Math.min(props.usage.usedCredits / props.usage.totalCredits, 1) * 100).toFixed(2) : '100';
	return (
		<div>
			Current period:{' '}
			<span className="text-black">
				{new Date(props.usage.currentPeriodStart).toLocaleDateString()}
				{' - '}
				{new Date(props.usage.currentPeriodEnd).toLocaleDateString()}
			</span>
			, Used credits:{' '}
			<span className="text-black">
				{props.usage.usedCredits} from {props.usage.totalCredits} ({percent}%)
			</span>
		</div>
	);
}
